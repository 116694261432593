import { useEffect } from "react";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { Backdrop } from "@mui/material";
import { useAppIntl } from "services/useAppIntl";
import { LoadingIndicator } from "utils/helpers/LoadingIndicator";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";

/**
 * Displays a backdrop with a snackbar message when the browser is offline. Automatically closes when the browser is online.
 */
const OfflineInfo = () => {
  const { setSnackbarValues, snackbarMessage } = useSnackbarMessage();
  const intl = useAppIntl();
  const useOfflineInfo = useFeatureFlag("OfflineInfo");

  useEffect(() => {
    const handleOnline = () => {
      setSnackbarValues(undefined);
    };

    const handleOffline = () => {
      setSnackbarValues({
        message: intl.formatMessage({ id: "common.app.offline" }),
        type: "info",
      });
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [setSnackbarValues, intl]);

  if (!useOfflineInfo) return null;

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={snackbarMessage !== undefined}>
      <LoadingIndicator>{snackbarMessage}</LoadingIndicator>
    </Backdrop>
  );
};

export default OfflineInfo;
