import type { Options as IntlMessageFormatOptions } from "intl-messageformat";
import React from "react";
import { FormattedMessage as ReactFormattedMessage } from "react-intl";
import { IntlMessageKeys } from "services/useAppIntl";

export type FormattedMessageProps = {
  id?: IntlMessageKeys;
  description?: string | object;
  defaultMessage?: string;
  values?: Record<string, React.ReactNode>;
  tagName?: React.ElementType<any>;
  children?(...nodes: React.ReactNode[]): React.ReactElement | null;
  ignoreTag?: IntlMessageFormatOptions["ignoreTag"];
  firstLetterCapitalized?: boolean;
};

const FormattedMessage = (props: FormattedMessageProps): JSX.Element => {
  return <ReactFormattedMessage {...props} />;
};

export default FormattedMessage;
