import { Button, Box } from "@mui/material";
import { BasicLayout } from "layout";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";

export const Maintenance = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <BasicLayout paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0}>
      <BackgroundShape top={-240} left={-100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>

      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        padding="2rem"
        textAlign="center"
      >
        <FormattedTypography id="maintenance.title" variant="h1" color="primary.dark06" mb={2} />
        <FormattedTypography
          id="maintenance.text"
          variant="body2"
          values={{
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
        <Box display="flex" justifyContent="center" mb={1}>
          <img src={process.env.PUBLIC_URL + "/img/illustrations/person-roller.svg"} alt="Man walking on road" />
        </Box>
        <Button variant="contained" onClick={handleReload}>
          <FormattedTypography id="maintenance.button" />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          position: "fixed",
          bottom: "2rem",
          justifyContent: "center",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          left: "50%",
          transform: "translate(-50%, 0)",
          textAlign: "center",
        }}
      >
        <img src={process.env.PUBLIC_URL + "/img/logoSTR.png"} alt="STR logo" height="32" />
        <FormattedTypography id="common.app.subtitle" variant="captionImage" mt="8px" />
      </Box>
      <BackgroundShape bottom={-300} position="fixed" center stickToBottom>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="1000" height="400" color={svgColor.turquoise} />
      </BackgroundShape>
    </BasicLayout>
  );
};
