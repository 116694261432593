import { AtomEffect, DefaultValue } from "recoil";
import { RequestError } from "types/request";

export const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    // eslint-disable-next-line eqeqeq
    if (savedValue != null && savedValue != "undefined") {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const copyObject = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Check if fetch call has errors
 * @param response
 * @returns
 */
export const instanceOfRequestError = (object: any): object is RequestError => !!object?.errors;
