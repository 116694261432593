import { Stack } from "@mui/system";
import { useFetch } from "hooks/useFetch";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { EducatorDetails } from "types/educator";
import { connectedEducatorsSelector } from "state/educationState";
import { API_URL_BO_EDUCATOR } from "utils/constants";
import { EducatorEducations, ShareInfoListItem } from "./myEducatorEducations";
import { ContactInfo } from "./contactInfo";
import { PresentationText } from "./presentationText";
import FindUs from "./findUs";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { Title } from "utils/helpers/TitleDivider";
import { InfoMessage } from "utils/helpers/InfoMessage";
import SvgImage, { svgColor, ellipse3 as ellips } from "utils/helpers/SvgImage";

export const EducatorDetail = () => {
  const { id } = useParams();
  const connectedEducators = useRecoilValue(connectedEducatorsSelector);
  const connectedEducator = id ? connectedEducators?.find((ce) => ce.identifier === id) : connectedEducators![0];
  const eduId = id ? id : connectedEducator?.identifier;
  const { json: educator } = useFetch<EducatorDetails>(`${API_URL_BO_EDUCATOR}${eduId}`);

  if (!educator) return null;

  return (
    <>
      <BackgroundShape top={-175} left={-30}>
        <SvgImage
          d={ellips.d}
          viewBox={ellips.viewBox}
          width="530"
          height="360"
          color={svgColor.blue2}
          rotation={-10}
        />
      </BackgroundShape>
      <Title title={educator.name} />
      <Stack spacing={5}>
        {!educator.isMyEducator && <InfoMessage type="info" message="more.educator.not.my.educator" />}

        {/* TODO: Hide until we have functionallity <MyEducatorButton isMyEducator={educator.isMyEducator} /> */}
        <PresentationText presentationText={educator.presentationText} />
        {/* TODO: Hide for now <MyEducatorPageButton url={"https://stroptima.se"} /> */}

        <EducatorEducations>
          {educator.isMyEducator && (
            <ShareInfoListItem isSharingInfo={connectedEducator?.consented ?? false} identifier={educator.identifier} />
          )}
        </EducatorEducations>

        <ContactInfo homePage={educator.homepage} email={educator.email} phone={educator.phone} />
        <FindUs educator={educator} />
      </Stack>
    </>
  );
};
