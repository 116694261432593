import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { Icon, iconCircleExclamation } from "theme/icons";

type Props = {
  color?: string;
};

export const ShowConsentWarningIcon: FC<Props> = ({ color }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        left: 20,
        top: -18,
        background: "transparent",
      }}
    >
      <Box
        sx={{
          background: "white",
          height: "15px",
          width: "15px",
          borderRadius: "50%",
          position: "absolute",
          top: "9px",
          left: "5px",
        }}
      >
        <Icon
          sx={{ position: "absolute", top: "-4px", left: "-3px" }}
          name={iconCircleExclamation}
          htmlColor={color ? color : theme.palette.warning.main}
        />
      </Box>
    </Box>
  );
};
