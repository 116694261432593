import React from "react";
import { Box, List, ListItem, Switch, Typography, useTheme, Theme, useMediaQuery } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { testId } from "tests/testIdStrings";
import { educationStateInfoShared, userEducationsState } from "state/educationState";
import { arrowUpFromBracket, Icon, iconCircleExclamation } from "theme/icons";
import { optimaColors } from "theme/optima";
import { ConsentToShareInfoRequest } from "types/consentToShareInfoRequest";
import { API_URL_BO_EDUCATOR } from "utils/constants";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { getEducationIcon } from "utils/helpers/GetEducationIcon";
import { InfoMessage } from "utils/helpers/InfoMessage";
import { UserEducation } from "types/education";

const listStyle = {
  borderRadius: 0,
  height: "53px",
  "> svg": { marginRight: "1rem" },
};

const listStyleMobile = {
  marginLeft: "-24px",
  width: "calc(100% + 48px)",
  paddingLeft: "24px",
};

const listStyleDesktop = {
  marginLeft: "0px",
  width: "100%",
  paddingLeft: "16px",
  flexDirection: "row",
};

export const ShareInfoListItem = ({ isSharingInfo, identifier }: { isSharingInfo: boolean; identifier: string }) => {
  const { put } = useFetch();
  const theme = useTheme();
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const infoShared = useSetRecoilState(educationStateInfoShared);
  const refreshConnectedEducators = () => infoShared((n: number) => n + 1);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const input = event.target as HTMLInputElement;
    const body: ConsentToShareInfoRequest = { consent: input.checked, identifier: identifier };
    put(`${API_URL_BO_EDUCATOR}${"consent-to-share-info"}`, body);

    setTimeout(() => {
      refreshConnectedEducators();
    }, 500);
  };

  return (
    <>
      <ListItem
        divider
        sx={[
          { justifyContent: "space-between" },
          listStyle,
          isMediumOrSmallScreen ? listStyleMobile : listStyleDesktop,
        ]}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Icon
            name={arrowUpFromBracket}
            size="medium"
            sx={{ marginRight: "0.5rem", color: theme.palette.primary.main }}
          />
          <FormattedTypography paddingTop={"3px"} variant="subtitle2" id="educator.details.share.info.button.text" />
          {!isSharingInfo && (
            <Icon
              name={iconCircleExclamation}
              size="medium"
              sx={{ marginLeft: "0.5rem", color: optimaColors.neutralWarning }}
            />
          )}
        </Box>
        <Switch onClick={handleOnClick} color={"default"} checked={isSharingInfo} />
      </ListItem>
      {isSharingInfo ? (
        // TODO Letter spacing stämmer inte med Figma
        <FormattedTypography variant="note" id="educator.details.share.info" />
      ) : (
        <Box marginTop={2}>
          {/* // TODO Letter spacing stämmer inte med Figma */}
          <InfoMessage type="warning" message="educator.details.share.info.warning" />
        </Box>
      )}
    </>
  );
};

export const EducatorEducations = ({ children: shareInfoItem }: { children?: React.ReactNode }) => {
  const educations = useRecoilValue(userEducationsState);

  if (!educations) return null;

  return (
    <Box data-testid={testId.listItem.educator.educationBox}>
      <FormattedTypography variant="h2" id="educator.details.my.educations.header" />
      <List>
        {educations.map((education, index) => (
          <EducatorEducation education={education} key={education.identifier} index={index} />
        ))}
        {shareInfoItem}
      </List>
    </Box>
  );
};

const EducatorEducation = ({ education, index }: { education: UserEducation; index: number }) => {
  const theme = useTheme();
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <ListItem
      key={education.identifier}
      sx={!isMediumOrSmallScreen ? listStyle : { ...listStyle, ...listStyleMobile }}
      divider
      data-testid={`${testId.listItem.educator.educationItem}-${index + 1}`}
    >
      <Icon name={getEducationIcon(education.name)} size="medium" htmlColor={theme.palette.primary.main} />
      <Typography variant="subtitle2">{education.name}</Typography>
    </ListItem>
  );
};
