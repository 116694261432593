import React from "react";
import { Button, Stack, Box } from "@mui/material";
import {
  Icon,
  iconLogout,
  iconTriangleSolid,
  iconBookSolid,
  iconBookmarkSolid,
  iconArrowRightRegular,
  iconEducator,
  iconUserSolid,
  iconMessageQuestion,
  iconRoute,
  iconSolidGlobe,
  iconCircleInfoSolid,
  iconStarFilled,
} from "theme/icons";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { desktopWidth420 } from "theme/theme";
import ReactGA from "react-ga4";
import { useRecoilValue } from "recoil";
import shadows from "@mui/material/styles/shadows";
import { connectedEducatorsSelector } from "state/educationState";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { IntlMessageKeys, useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useLogout } from "hooks/useLogout";
import { rateUs, getPlatform, PlatformEnum } from "layout/rateUs";
import { useEducation } from "hooks/useEducation";
import SoundSupport from "layout/Components/SoundSupport";
import { ShowConsentWarningIcon } from "layout/Components/ShowConsentWarningIcon";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import SvgImage, { svgColor, ellipse2, ellipse3 } from "utils/helpers/SvgImage";

type Props = {
  desktopMenu?: boolean;
};
const analytics = (path: string) => {
  ReactGA.event({
    category: "more",
    action: path,
  });
};

export const More: React.FC<Props> = ({ desktopMenu }: Props) => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const useBookmarks = useFeatureFlag("Bookmarks");
  const useLanguage = useFeatureFlag("Language");
  const useRateUs = useFeatureFlag("RateUs");
  const { logout } = useLogout();
  const menuWidth = "430px";
  const { education } = useEducation();
  const platform = getPlatform();

  const showMyDriverEducation = () => {
    const eduId = education?.productIdentifier ?? "";
    // id för Bil och Bil - English, dev + prod
    return [
      "3f3fa211-9ffd-4b44-a59b-88db03cbbe50",
      "fccfa553-c93d-45e6-8cbb-1bca0803c65e",
      "99efd65a-2144-4bcc-96b3-5e2d208e1596",
    ].includes(eduId);
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Box
      sx={
        desktopMenu
          ? {
            position: "fixed",
            top: "64px",
            marginLeft: `calc(100vw / 2 - ${menuWidth} / 2 + 24px)`,
            marginRight: "auto",
            width: menuWidth,
            borderRadius: 2,
            background: "white",
            padding: 2,
            color: "black",
            boxShadow: shadows[4],
            maxHeight: "82%",
            overflowY: "auto",
          }
          : null
      }
    >
      {!desktopMenu && (
        <BackgroundShape top={-160} left={318}>
          <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="650" height="300" color={svgColor.pink} />
        </BackgroundShape>
      )}
      <Stack spacing={desktopMenu ? 2 : 4} sx={{ maxWidth: desktopWidth420, marginLeft: "auto", marginRight: "auto" }}>
        <Stack spacing={1.5}>
          <NavigationItem
            text={intl.formatMessage({ id: "more.title.education" })}
            icon={iconBookSolid}
            iconSize="medium"
            href={routes.education.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.education}
            onClick={() => analytics(routes.education.path)}
          />
          <NavigationItem
            text={intl.formatMessage({ id: "more.title.profile" })}
            icon={iconUserSolid}
            iconSize="medium"
            href={routes.profile.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.profile}
            onClick={() => analytics(routes.profile.path)}
          />
          <MyEducatorItem />
        </Stack>

        <Stack spacing={1.5}>
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.checklist" })}
            icon={iconCheckboxSolid}
            iconSize="medium"
            href="/more/checklist"
            navigationIcon={iconArrowRightRegular}
            /> */}
          {/* <NavigationItem
            text={intl.formatMessage({ id: "more.title.glossary" })}
            icon={iconList}
            iconSize="medium"
            href={routes.glossary.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.glossary}
          /> */}
          {useBookmarks && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmarkSolid}
              iconSize="medium"
              href={routes.bookmarks.path}
              navigationIcon={iconArrowRightRegular}
              dataTestIdString={testId.listItem.more.bookmarks}
            />
          )}
          <NavigationItem
            text={intl.formatMessage({ id: "common.roadsigns" })}
            icon={iconTriangleSolid}
            iconSize="medium"
            href={routes.roadsigns.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.roadSigns}
            onClick={() => analytics(routes.roadsigns.path)}
          />
          {showMyDriverEducation() && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.title.myeducation" })}
              icon={iconRoute}
              iconSize="medium"
              href={routes.myeducation.path}
              navigationIcon={iconArrowRightRegular}
              dataTestIdString={testId.listItem.more.myeducation}
              onClick={() => analytics(routes.myeducation.path)}
            />
          )}
        </Stack>
        <Stack spacing={1.5}>
          <NavigationItem
            text={intl.formatMessage({ id: "more.title.getstarted" })}
            icon={iconCircleInfoSolid}
            iconSize="medium"
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.getstarted}
            href={routes.getstarted.path}
          />
          <NavigationItem
            text={intl.formatMessage({ id: "more.title.support" })}
            icon={iconMessageQuestion}
            iconSize="medium"
            href={routes.support.path}
            navigationIcon={iconArrowRightRegular}
            dataTestIdString={testId.listItem.more.support}
            onClick={() => analytics(routes.support.path)}
          />
          {useRateUs && platform !== PlatformEnum.Desktop && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.rateus.button-text" })}
              icon={iconStarFilled}
              iconSize="medium"
              navigationIcon={iconArrowRightRegular}
              dataTestIdString={testId.listItem.more.support}
              onClick={() => rateUs()}
            />
          )}
          {useLanguage && (
            <NavigationItem
              text={intl.formatMessage({ id: "more.title.language" })}
              icon={iconSolidGlobe}
              iconSize="medium"
              href={routes.language.path}
              navigationIcon={iconArrowRightRegular}
            // dataTestIdString={testId.listItem.more.settings}
            />
          )}
        </Stack>
        <SoundSupport />
        <Button
          variant="contained"
          onClick={handleLogoutClick}
          data-testid={`${testId.listItem.listItem}-${testId.listItem.more.logout}`}
        >
          <Icon name={iconLogout} sx={{ marginRight: "0.5rem" }} />
          <FormattedMessage id="common.logout" />
        </Button>
      </Stack>
      {!desktopMenu && (
        <BackgroundShape stickToBottom bottom={-250} left={-140}>
          <SvgImage d={ellipse3.d} viewBox={ellipse3.viewBox} width="480" height="230" color={svgColor.turquoise} />
        </BackgroundShape>
      )}
    </Box>
  );
};

const MyEducatorItem = () => {
  const routes = useRouteConfiguration();
  const connectedEducators = useRecoilValue(connectedEducatorsSelector);

  if (!connectedEducators || connectedEducators.length === 0)
    return (
      <EducatorNavigationItem
        path={routes.educatorFind.path}
        text="more.title.find.educator"
        icon={<ShowConsentWarningIcon color={"#476EB8"} />}
      />
    );

  const icon = !connectedEducators.every((educator) => educator.consented) ? <ShowConsentWarningIcon /> : null;
  return <EducatorNavigationItem path={routes.educator.path} text="more.title.educator" icon={icon} />;
};

const EducatorNavigationItem = ({
  path,
  text,
  icon,
}: {
  path: string;
  text: IntlMessageKeys;
  icon: JSX.Element | null;
}) => {
  return (
    <NavigationItem
      text={
        <Box display={"flex"} flexDirection={"row"} width={"100%"}>
          <FormattedTypography id={text} variant="bodyStrong" fontSize={16} />
          <Box sx={{ position: "relative", top: "12px", left: "-12px" }}>{icon}</Box>
        </Box>
      }
      icon={iconEducator}
      iconSize="medium"
      href={path}
      navigationIcon={iconArrowRightRegular}
      dataTestIdString={testId.listItem.more.educator}
      onClick={() => analytics(path)}
    />
  );
};
