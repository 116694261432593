import { ReferenceKey, SubjectCategories } from "types/cds";
import { ItemAssessment } from "@strmediaochitab/optima-component-library";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { assessmentStateSelector, EducationContentTypes } from "state/learningStructureState";

export type AssessmentType = Extract<EducationContentTypes, "answer" | "roadsigns" | "study" | "final">;
interface InitAssessment {
  type: AssessmentType;
  key: ReferenceKey;
}
/**
 * Hook for getting an assessment based on content
 */
export const useAssessment = ({ type, key }: InitAssessment) => {
  const [assessment, setAssessment] = useState<ItemAssessment | null | undefined>();
  const [subjectCategories, setSubjectCategories] = useState<SubjectCategories[]>([]);

  const assessmentState = useRecoilValue(
    assessmentStateSelector({
      key: key,
      educationContentType: type,
    })
  );

  useEffect(() => {
    if (assessmentState === undefined) return;

    console.log("assessment loaded", assessmentState);

    if (assessmentState === null) {
      setAssessment(null);
      return;
    }

    setSubjectCategories(assessmentState.subjectCategories);
    setAssessment(assessmentState.assessment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentState]);

  return { assessment, subjectCategories };
};
