import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { iconTriangle, iconArrowCircleRight, iconBookmark } from "theme/icons";
import { Title } from "utils/helpers/TitleDivider";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { TheoryAreas } from "./areas";
import { testId } from "tests/testIdStrings";
import { AreasSkeleton } from "utils/helpers/LoadingSkeletons";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import SoundSupport from "layout/Components/SoundSupport";
import { desktopWidth420 } from "theme/theme";
import ContinueTheory from "layout/Components/ContinueTheory";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";

export const Theory = () => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const theme = useTheme();
  const useBookmarks = useFeatureFlag("Bookmarks");

  return (
    <>
      <BackgroundShape top={-250} center>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="1200" height="500" color={svgColor.pink} />
      </BackgroundShape>
      <Box sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: desktopWidth420 }}>
        <Title title={intl.formatMessage({ id: "common.title.theory" })} />
        <Stack spacing={2}>
          <ContinueTheory />

          <NavigationItem
            size="auto"
            href={routes.roadsigns.path}
            text={intl.formatMessage({ id: "common.roadsigns" })}
            icon={iconTriangle}
            iconSize="medium"
            color={theme.palette.info.main}
            navigationIcon={iconArrowCircleRight}
            dataTestIdString={testId.listItem.theory.roadSigns}
          />
          {useBookmarks && (
            <NavigationItem
              size="auto"
              href={routes.bookmarks.path + "#theory"}
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmark}
              iconSize="medium"
              color={theme.palette.info.main}
              navigationIcon={iconArrowCircleRight}
              dataTestIdString={testId.listItem.theory.bookmark}
            />
          )}
        </Stack>

        <Box sx={{ marginTop: "6rem" }}>
          <Title title={intl.formatMessage({ id: "common.title.education.parts" })} variant="h2" />
        </Box>

        <React.Suspense fallback={<AreasSkeleton />}>
          <TheoryAreas />
        </React.Suspense>

        <SoundSupport />
      </Box>

      <BackgroundShape bottom={-250} center>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};
