export const testId = {
  menu: {
    bottomNavigation: {
      home: "bottom-navigation.home",
      theory: "bottom-navigation.theory",
      test: "bottom-navigation.test",
      progress: "bottom-navigation.progress",
      more: "bottom-navigation.more",
    },
    bottomActionNavigation: {
      left: "bottom-action.left",
      right: "bottom-action.right",
      list: "bottom-action.list",
    },
    header: {
      item: "header.item",
      select: "header.select.menu",
      button: "header.button",
      backButton: "header.back.button",
      pageTitle: "header.page.title",
    },
    tab: {
      read: "header.tab.read",
      practice: "header.tab.practice",
      answer: "header.tab.answer",
    },
  },
  link: {
    cardLink: "cardlink",
    home: {
      progress: "home.progress",
      percent: "home.percent",
    },
    profile: {
      forgotPassword: "profile.link.forgotpassword",
    },
  },
  listItem: {
    listItem: "listitem",
    home: {
      theoryContinue: "home.button.theory.continue",
      roadsigns: "home.button.roadsigns",
      testContinue: "home.button.test.continue",
      testTodaysQuestion: "home.button.test.todays.question",
      bookmark: "home.button.bookmark",
    },
    theory: {
      areas: "theory.areas",
      chapters: "theory.chapters",
      sections: "theory.sections",
      articleTitle: "theory.article.title",
      continue: "theory.button.continue",
      bookmark: "theory.button.bookmark",
      roadSigns: "theory.button.roadsigns",
    },
    question: {
      previous: "question.previous",
      next: "question.next",
      overview: "question.overview",
      item: "question.item",
    },
    section: {
      navigation: {
        read: "section.navigation.read",
        practice: "section.navigation.practice",
        answer: "section.navigation.answer",
      },
      learningObjective: "section.learningobjective",
      sectionTitle: "section.section-title",
      listen: "section.listen",
      bookmark: "section.bookmark",
      star: "section.star",
      goToPractice: "section.practice",
      goToPracticeEnd: "section.practice-end",
    },
    more: {
      education: "more.education",
      educationPaused: "more.educationPaused",
      profile: "more.profile",
      educator: "more.educator",
      glossary: "more.glossary",
      bookmarks: "more.bookmarks",
      roadSigns: "more.roadsigns",
      support: "more.support",
      settings: "more.settings",
      logout: "more.logout",
      dropdown: "more.dropdown",
      myeducation: "more.myeducation",
      getstarted: "more.getstarted",
    },
    profile: {
      avatarChooser: "profile.button.avatar.select",
      avatarImage: "profile.avatar.image",
      profilePicture: "profile.picture",
      unsavedChanges: "profile.unsaved.dialog",
      userTerms: "profile.link.userTerms",
      gdprTerms: "profile.link.gdprTerms",
      contactInfoBox: "profile.contact.information",
    },
    test: {
      custom: {
        back: "test.custom.button.back",
        questionTypes: "test.custom.questiontypes",
        numOfQuestions: "test.custom.numOfQuestions",
        duration: "test.custom.duration",
      },
      type: {
        quick: "test.type.quick",
        roadsign: "test.type.roadsign",
        custom: "test.type.custom",
        final: "test.type.final",
      },
      common: {
        historyAll: "test.common.history.all",
        historyLatest: "test.common.history.latest",
        close: "test.common.history.close",
        correction: "test.common.correction",
        leave: "leave.test",
        overview: "test.common.overview",
        pagination: "test.common.pagination",
        result: "test.common.result",
        unlock: "test.common.final.test.unlock",
      },
    },
    education: {
      educationNavigationItem: "education.navigation.item",
      educationNavigationHandle: "education.navigation.handle",
      educationNavigationAvailable: "education.navigation.available",
    },
    educator: {
      educatorItem: "educator.item",
      educationBox: "educator.education.box",
      educatorAdressBox: "educator.adress.box",
      educationItem: "educator.education.item",
      educationItemTitle: "educator.education.item.title",
      contact: {
        website: "educator.contact.website",
        email: "educator.contact.email",
        phone: "educator.contact.phone",
      },
      tab : {
        map: "educator.find.map",
        list: "educator.find.list",
      },
    },
    roadSigns: "roadsigns.link",
    getStarted: {
      howToTest: "getstarted.howto.test",
      howToKb: "getstarted.howto.kb",
    },
  },
  common: {
    text: {
      welcomeMessage: "welcome.message",
      educator: "educator",
      profile: "profile",
      roadSigns: "roadsigns",
    },
    dropDown: {
      buttonToggle: "dropdown.button.toggle",
      menuWrapper: "dropdown.menu.wrapper",
      menuItem: "dropdown.menu.item",
      subscription: {
        educations: "dropdown.menu.subscription.education",
        logout: "dropdown.menu.subscription.logout",
      },
    },
    headerTitle: "header.title",
    dialogHeader: "dialog.header",
    noContent: "image.nocontent",
    save: "save",
    close: "close",
    ok: "ok",
    cancel: "cancel",
    back: "back",
    start: "start",
  },
  input: {
    profile: "profile.input",
  },
  checkbox: {
    profile: "profile.checkbox",
  },
  button: {
    profile: "profile.button",
    education: "education.button",
    dialogMessage: "button.dialogmessage",
    bookmarkItem: "bookmark",
    testPrefix: "test",
    moreQuestions: "more.questions",
    primary: "primary",
    secondary: "secondary",
    cancel: "cancel",
    test: "test.button",
    agreement: "agreement.button",
  },
};
