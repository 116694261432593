import { useAppContext } from "context/AppContext";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userEducationsState, educationState } from "state/educationState";

/**
 * Hook for getting/setting the current selected education. If no education is set it will default to the first one.
 * @returns userEducations, currentEducationIdentifier, setCurrentEducation()
 */
export const useEducation = () => {
  const userEducations = useRecoilValue(userEducationsState);
  const [education, setEducation] = useRecoilState(educationState);
  const { setActiveLanguage } = useAppContext();

  useEffect(() => {
    if (!userEducations) return;
    if (!userEducations.length) return;

    // Re-set current (if refreshed from elsewhere) or set first as default
    const active = education?.identifier
      ? userEducations.find((e) => e.identifier === education.identifier)
      : userEducations[0];

    setActiveLanguage(active?.language || "sv");
    setEducation(active);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, education?.identifier]);

  return { userEducations, education, setEducation };
};
