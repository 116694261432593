import React, { PropsWithChildren } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ADB2C_ERROR_CODES, loginRequest } from "authConfig";
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { AppIntro } from "layout/appIntro";
import { AppOutro } from "layout/appOutro";

export const ProtectedRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  const msal = useMsal();
  const location = useLocation();
  const authRequest = {
    ...loginRequest,
  };
  const LoadingComponent =
    msal.inProgress === InteractionStatus.Logout || location.hash === "#logout" ? AppOutro : AppIntro;

  function ErrorComponent({ error }: MsalAuthenticationResult) {
    if (!error) return null;
    // User har requested to reset password
    if (error.errorMessage?.indexOf(ADB2C_ERROR_CODES.FORGOTTEN_PASSWORD) > -1) return null;

    return <p>We encountered an error trying to authenticate: {error.errorMessage}</p>;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={LoadingComponent}
      errorComponent={ErrorComponent}
    >
      {children ? children : <Outlet />}
    </MsalAuthenticationTemplate>
  );
};
