import { useEffect, useState } from "react";
import { useEducation } from "hooks/useEducation";
import { EducationStateEnum } from "types/education";
import { MORE_EDUCATION_PATH, NEW_ACCOUNT_PATH } from "hooks/useRouteConfiguration";
import { useValidateBasicInfo } from "hooks/useValidateBasicInfo";

/**
 * @returns path to navigate to when user is logged in or is newly created
 */
export const useInitNavigate = () => {
  const { userEducations } = useEducation();
  const { basicInfoIsValidated, basicInfoHasErrors } = useValidateBasicInfo();
  const [state, setState] = useState<string | null>(null);

  const handleInitNavigation = () => {
    // BasicInfo is not valid
    if (basicInfoHasErrors) return setState(NEW_ACCOUNT_PATH);

    if (userEducations) {
      const activeNavigation = userEducations.filter((x) => x.educationState === EducationStateEnum.Active);
      // One active education
      if (activeNavigation.length === 1) return setState("/");

      // There is none, 2 or more active educations
      return setState(MORE_EDUCATION_PATH);
    }
  };

  useEffect(() => {
    if (!basicInfoIsValidated || !userEducations) return;

    handleInitNavigation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, basicInfoIsValidated]);

  return state;
};
