import { Box } from "@mui/material";
import { SwipeAction, TrailingActions } from "react-swipeable-list";
import { Icon, iconTrashcanSolid } from "theme/icons";
import { BookmarkState } from "services/appStateService";
import "react-swipeable-list/dist/styles.css";

export const swipeLeftAction = (item: BookmarkState, handleDeleteDialog: (item: BookmarkState) => void) => (
  <TrailingActions>
    <SwipeAction destructive={false} onClick={() => handleDeleteDialog(item)}>
      <Box
        sx={{
          background: "#FEEAE4",
          height: "100%",
          display: "flex",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            background: "#FEEAE4",
            height: "100%",
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Box
            sx={{
              background: "#8b2638",
              borderRadius: "50%",
              width: "44px",
              height: "44px",
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <Icon size="medium" name={iconTrashcanSolid} htmlColor="white" />
          </Box>
        </Box>
      </Box>
    </SwipeAction>
  </TrailingActions>
);
