import { Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { testId } from "tests/testIdStrings";
import { DialogMessage } from "utils/helpers/DialogMessage";


interface ICompleteDialogProps {
  isOpen: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  action: () => void;
}

export const CompleteDialog: FC<ICompleteDialogProps> = ({ isOpen, close, action }) => {
  return (
    <DialogMessage
      title={"education.paused.button.title.complete"}
      open={isOpen}
      close={() => close(false)}
      primaryButtonText={"education.complete.button"}
      dataTestIdString={`${testId.button.dialogMessage}-complete`}
      primaryButtonAction={action}
    >
      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
        <FormattedMessage id={"education.dialog.complete.caption1"} />
      </Typography>
    </DialogMessage>
  );
};