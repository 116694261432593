import { ItemArticle, ItemGlossary } from "@strmediaochitab/optima-component-library";
import { selector, selectorFamily } from "recoil";
import { itemStateSelector, learningStructureState } from "./learningStructureState";

// Gets the Activities for the current selected list of ReferenceKeys
export const glossaryStateSelector = selector<ItemGlossary | undefined>({
  key: "glossaryStateSelector",
  get: async ({ get }) => {
    const learningStructure = get(learningStructureState);
    if (!learningStructure) return undefined;

    const glossaryTreeNode = learningStructure.find((ls) => ls.parentId === 0);
    if (!glossaryTreeNode) return undefined;

    const learningObject = get(itemStateSelector({ materialReferences: glossaryTreeNode.materials }));
    if (!learningObject) return undefined;

    const glossary: ItemGlossary = learningObject[0].items[0];

    return glossary;
  },
});

export const glossaryStateDetail = selectorFamily<ItemArticle | undefined, { id: string }>({
  key: "glossaryStateDetail",
  get:
    ({ id }) =>
    ({ get }) => {
      const state = get(glossaryStateSelector);
      if (!state) return undefined;

      let article: ItemArticle | undefined = undefined;

      state.items.forEach((item: ItemArticle) => {
        const heading = item.items.find(
          (item) => item.configuration.type === "Heading" && "value" in item && item.value === id
        );
        if (heading) article = item;
      });

      return article;
    },
});
