import { useAccount } from "@azure/msal-react";

/**
 * Helper functions for msal account
 */
export const useAccountHelper = () => {
  const account = useAccount();

  const hasIdTokenClaimNewUser = () => {
    return account?.idTokenClaims && account.idTokenClaims["newUser"] === true;
  };

  return { account, hasIdTokenClaimNewUser };
};
