import { Stack } from "@mui/system";
import { testId } from "tests/testIdStrings";
import { AgreementViewer } from "utils/helpers/AgreementViewer";
import ButtonBack from "utils/helpers/ButtonBack";

export const Agreements = () => {
  return (
    <Stack spacing={4}>
      <AgreementViewer />
      <ButtonBack dataTestIdString={testId.button.agreement} />
    </Stack>
  );
};
