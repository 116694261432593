import { useInitNavigate } from "utils/helpers/msal/useInitNavigate";
import { Navigate } from "react-router-dom";
import { AppIntro } from "./appIntro";

export const Start = () => {
  const initialPage = useInitNavigate();

  if (!initialPage) return <AppIntro />;

  return <Navigate to={initialPage} />;
};
