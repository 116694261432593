import { Educator } from "types/educator";

function deg2rad(deg: any) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d: number = R * c; // Distance in km
  return d; // distance returned
}

export function sortByDistance(location: any, educatorList: Educator[]) {
  const listWithDistance = [];
  const listWithoutDistance = [];
  for (let i = 0; i < educatorList.length; i++) {
    if (educatorList[i].location != null) {
      const distance = getDistanceFromLatLonInKm(
        location.latitude,
        location.longitude,
        educatorList[i].location.latitude,
        educatorList[i].location.longitude
      );
      listWithDistance.push({ ...educatorList[i], distance });
    } else {
      listWithoutDistance.push({ ...educatorList[i], distance: 999999 });
    }
  }
  // add non positioned schools to the end of list
  listWithDistance.push(...listWithoutDistance);
  const sorted = listWithDistance.sort((a, b) => a.distance - b.distance);

  return sorted;
}

export function distanceKm(d: number = 0) {
  if (d.toString().charAt(0) === "0") {
    return ", " + d.toFixed(1) + " km";
  } else if (d === 999999) {
    return "";
  } else {
    return ", " + d.toFixed(0) + " km";
  }
}
