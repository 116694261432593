import { Box, Button, Stack, Typography, useTheme, Link } from "@mui/material";
import { useDateService } from "hooks/useDateService";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IntlMessageKeys } from "services/useAppIntl";
import { educationStateEducationAdded, userEducationState } from "state/educationState";
import { testId } from "tests/testIdStrings";
import { Icon, iconArrowLeft } from "theme/icons";
import { EducationActionRequest, EducationActivateRequest } from "types/EducationActionRequest";
import { EducationStateEnum } from "types/education";
import { API_URL_BO_EDUCATION } from "utils/constants";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import ButtonBack from "utils/helpers/ButtonBack";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { getEducationIcon } from "utils/helpers/GetEducationIcon";
import { InfoMessage } from "utils/helpers/InfoMessage";
import { ActivateDialog } from "./ActivateDialog";
import { CompleteDialog } from "./CompleteDialog";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";
import { ValueCode } from "layout/Components/ValueCode";

type EducationStateLowerCase = "active" | "paused" | "completed";

const getFormattedDate = (state: string, date: string) => (
  <Typography key={state}>
    <FormattedMessage
      id={`education.details.date.${state.toLowerCase()}.caption` as IntlMessageKeys}
      description="Dates in education details"
      values={{
        date: date,
      }}
    />
  </Typography>
);

export const EducationDetails = () => {
  const { getDateAsLocalDateString: getDateAsString } = useDateService();
  const theme = useTheme();
  const [activateDrawerIsOpen, setActivateDrawerIsOpen] = useState<boolean>(false);
  const [completeDrawerIsOpen, setCompleteDrawerIsOpen] = useState<boolean>(false);
  const [activatedConfirmIsVisible, setActivatedConfirmIsVisible] = useState(false);
  const { id } = useParams();
  const education = useRecoilValue(userEducationState({ identifier: id }));
  const completeEducation = useFetch();
  const activateEducation = useFetch();
  const educationAdded = useSetRecoilState(educationStateEducationAdded);
  const refreshEducations = () => educationAdded((n: number) => n + 1);
  const navigate = useNavigate();

  useEffect(() => {
    if (completeEducation.json) {
      setCompleteDrawerIsOpen(false);

      // Need a little dealy to make sure the education is completed before refreshing the educations
      setTimeout(() => {
        refreshEducations();
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeEducation.json]);

  useEffect(() => {
    if (activateEducation.json) {
      setActivatedConfirmIsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateEducation.json]);

  if (!education) return null;

  const { educationState } = education;
  const educationStateLowerCase = educationState
    ? (educationState.toLowerCase() as EducationStateLowerCase)
    : undefined;

  if (!educationStateLowerCase) {
    console.error("Not a valid education state in EducationDetails");
    return null;
  }

  const handleCompleteEducation = () => {
    const url = `${API_URL_BO_EDUCATION}complete`;
    completeEducation.patch(url, new EducationActionRequest(education.identifier));
  };

  const handleActivateEducation = () => {
    const url = `${API_URL_BO_EDUCATION}activate`;

    const body = new EducationActivateRequest(education.identifier, educationState);
    activateEducation.post(url, body);
    setActivateDrawerIsOpen(false);
  };

  const validUntilString = getDateAsString(education.validUntil);
  const handleEducationView = (educationState: EducationStateEnum | undefined) => {
    switch (educationState) {
      case EducationStateEnum.Active:
        return getFormattedDate(EducationStateEnum.Active, validUntilString);
      case EducationStateEnum.Paused:
        return getFormattedDate(EducationStateEnum.Paused, validUntilString);
      case EducationStateEnum.Completed:
        return [
          getFormattedDate(EducationStateEnum.Paused, validUntilString),
          education.completed && getFormattedDate(EducationStateEnum.Completed, getDateAsString(education.completed)),
        ];
      default:
        return undefined;
    }
  };

  const getConfirmMessage = () =>
    activatedConfirmIsVisible && <InfoMessage message="education.details.activate.confirm.messages" type="success" />;

  return (
    <>
      <BackgroundShape top={-235} left={-135}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>
      <Box height={"100%"} display="flex" flexDirection="column">
        <Box marginTop={-6.5}>
          <Box mb={2} ml={2} height={theme.spacing(3)}>
            <Link
              onClick={() => navigate(-1)}
              sx={{ color: theme.palette.primary.main }}
              data-testid={testId.menu.header.backButton}
            >
              <Icon name={iconArrowLeft} />
            </Link>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">{education.name}</Typography>
            <Icon name={getEducationIcon(education.name)} htmlColor={theme.palette.primary.main} size={"large"} />
          </Box>
        </Box>
        <Box marginBottom={4} marginTop={5}>
          {getConfirmMessage()}
        </Box>
        <Typography variant={"h2"}>
          <FormattedMessage id="education.status" />
          <FormattedMessage id={`education.${educationStateLowerCase}`} />
        </Typography>
        <FormattedTypography
          id={`education.active.from.caption`}
          values={{
            date: getDateAsString(education.validFrom),
          }}
          sx={{ marginTop: "1rem" }}
        />

        {handleEducationView(educationState)}
        <FormattedTypography id={`education.${educationStateLowerCase}.text.extend`} mt={1.5} mb={2} />

        <Stack spacing={1.5} mt={10}>
          <Button
            variant="contained"
            onClick={() => setActivateDrawerIsOpen(true)}
            data-testid={`${testId.button.education}-activate`}
          >
            <FormattedMessage
              {...(educationState === EducationStateEnum.Active
                ? { id: "education.active.button" }
                : { id: "education.activate.button" })}
            />
          </Button>
          {educationState === EducationStateEnum.Paused && (
            <Button
              variant="outlined"
              onClick={() => setCompleteDrawerIsOpen(true)}
              data-testid={`${testId.button.education}-terminate`}
            >
              <FormattedMessage id="education.complete.button" />
            </Button>
          )}
          <ValueCode />
        </Stack>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          marginBottom={5}
          marginTop={2}
        >
          <ButtonBack dataTestIdString={testId.button.education} />
        </Box>

        <ActivateDialog
          isOpen={activateDrawerIsOpen}
          close={setActivateDrawerIsOpen}
          action={handleActivateEducation}
        />
        <CompleteDialog
          isOpen={completeDrawerIsOpen}
          close={setCompleteDrawerIsOpen}
          action={handleCompleteEducation}
        />
      </Box>
    </>
  );
};
