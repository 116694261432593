import { useState } from "react";
import { InfoMessage, InfoMessageProps } from "utils/helpers/InfoMessage";

export interface IInfoMessageResponse {
  infoMessage: React.ReactElement | null;
  setInfoMessageValues: (message?: InfoMessageProps) => void;
}

export const useInfoMessage = (): IInfoMessageResponse => {
  const [infoMessage, setInfoMessage] = useState<React.ReactElement | null>(null);

  const setInfoMessageValues = (message?: InfoMessageProps) => {
    if (!message) {
      setInfoMessage(null);
      return;
    }
    const props: InfoMessageProps = {
      type: message.type,
      message: message.message,
      messageValue: message.messageValue,
    };
    const element = InfoMessage(props);
    setInfoMessage(element);
  };

  return { infoMessage, setInfoMessageValues };
};
