import React, { useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { TopNavigationDetailsView } from "utils/helpers/TopNavigationDetailsView";
import { Icon, iconMotorCycle, iconMoped, iconCar, iconTruck, iconBus, iconCircle, iconCircleDot } from "theme/icons";
import { cardContent, cardContentAlignLeft, cardContentList } from "theme/styles";

type Props = {};
const mySettings = [
  {
    id: "0",
    qualifications: {
      MCA: {
        id: "0",
        title: "Mc A",
        icon: iconMotorCycle,
        status: false,
      },
      MopedAM: {
        id: "1",
        title: "Moped Am",
        icon: iconMoped,
        status: false,
      },
      PersonbilB: {
        id: "2",
        title: "Personbil B",
        icon: iconCar,
        status: false,
      },
      TungLastbilC: {
        id: "3",
        title: "Tung lastbil c",
        icon: iconTruck,
        status: false,
      },
      TungLastbilSlapCE: {
        id: "4",
        title: "Tung lastbil + släp CE",
        icon: iconTruck,
        status: false,
      },
      BussD: {
        id: "5",
        title: "Buss D",
        icon: iconBus,
        status: false,
      },
    },
    dispatch: {
      title: "Utskick från STR",
      status: false,
    },
    guideOnStart: {
      title: "Guide när appen startar",
      status: false,
    },
    reminders: {
      title: "Påminnelse om att öva",
      status: false,
    },
    appId: {
      title: "App ID: 2556534545",
    },
  },
];

export const Settings: React.FC<Props> = (props) => {
  const [defaultValues, setDefaultValues] = useState(mySettings);

  return (
    <Stack spacing={2}>
      <Box>
        <TopNavigationDetailsView iconSize={"large"} topTitle={"Mer"} title={"Inställningar"} to={`/more/`} />
      </Box>
      {defaultValues.map((settings) => (
        <Box key={settings.id}>
          <CheckboxOption
            settings={settings.qualifications.MCA}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.MCA.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <CheckboxOption
            settings={settings.qualifications.MopedAM}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.MopedAM.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <CheckboxOption
            settings={settings.qualifications.PersonbilB}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.PersonbilB.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <CheckboxOption
            settings={settings.qualifications.TungLastbilC}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.TungLastbilC.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <CheckboxOption
            settings={settings.qualifications.TungLastbilSlapCE}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.TungLastbilSlapCE.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <CheckboxOption
            settings={settings.qualifications.BussD}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj.qualifications };
                updatedValue.BussD.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <SwitchOption
            settings={settings.dispatch}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj };
                updatedValue.dispatch.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <SwitchOption
            settings={settings.guideOnStart}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj };
                updatedValue.guideOnStart.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <SwitchOption
            settings={settings.reminders}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newState = defaultValues.map((obj) => {
                const updatedValue = { ...obj };
                updatedValue.reminders.status = event.target.checked;
                return { ...obj };
              });
              setDefaultValues(newState);
            }}
          />
          <Divider />
          <List>
            <ListItem>
              <ListItemText>{settings.appId.title}</ListItemText>
            </ListItem>
          </List>
        </Box>
      ))}
    </Stack>
  );
};

interface IOptionProps {
  settings: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const CheckboxOption: React.FC<IOptionProps> = (props) => {
  return (
    <FormGroup>
      <Box sx={{ margin: "0 1rem 1rem 1rem" }}>
        <Box sx={cardContent}>
          <Box sx={cardContentAlignLeft}>
            <ListItemIcon>
              <Icon name={props.settings.icon} size={"large"} htmlColor={"gray"} />
            </ListItemIcon>
            <Box>
              <ListItemText primary={props.settings.title} />
            </Box>
          </Box>
          <Box>
            <Checkbox
              icon={<Icon name={iconCircle} size={"large"} htmlColor={"gray"} />}
              checkedIcon={<Icon name={iconCircleDot} size={"large"} htmlColor={"gray"} />}
              onChange={props.onChange}
              color={"default"}
            />
          </Box>
        </Box>
      </Box>
    </FormGroup>
  );
};

const SwitchOption: React.FC<IOptionProps> = (props) => {
  return (
    <Box>
      <Divider />
      <Card sx={cardContentList}>
        <Typography variant="body1">{props.settings.title}</Typography>
        <Switch onChange={props.onChange} color={"default"} />
      </Card>
    </Box>
  );
};
