import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { learningStructureState } from "state/learningStructureState";
import { useAccount } from "@azure/msal-react";
import { getTestReport } from "services/lrsService";
import { ItemTestResult, ItemTypeKnowledge, ItemTestResultItem } from "services/reportingService";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";

interface IUseTestResult {
  /** Use this to get the results for a specific acticity */
  referenceKey?: ReferenceKey;
  /** Use this to get the result for a specific test */
  testId?: string;
}

/**
 * Get test result data from an education. Supply props if you want to filter the results
 */
export const useTestResult = ({ referenceKey, testId }: Partial<IUseTestResult> = {}) => {
  const account = useAccount();
  const [testResults, setTestResults] = useState<undefined | ItemTestResultItem[]>();
  const [testResult, setTestResult] = useState<undefined | ItemTestResultItem>();
  const learningStructure = useRecoilValue(learningStructureState);

  useEffect(() => {
    if (!account || !learningStructure) return;
    let key: any = referenceKey;

    // Get education root if no referenceKey
    if (!referenceKey) key = learningStructure?.find((x) => x.parentId === 0)?.activityKey;

    const getResults = async () => {
      const testReport = await getTestReport(account.localAccountId, key);
      const testResults = testReport.items.filter(
        (item: any) => item.type === ItemTypeKnowledge.TestResults
      ) as ItemTestResult[];

      const results = testResults.length ? testResults[0].items : [];

      // Filter result by testId
      if (testId) {
        const result = results.filter((result) => result.statementRef === testId)[0];
        setTestResult(result);
      }

      setTestResults(results);
    };

    getResults();
  }, [account, learningStructure, referenceKey, testId]);

  return { testResults, testResult };
};
