import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { alignCenter } from "theme/styles";

export const PresentationText: FC<{ presentationText: string }> = ({ presentationText }) => {
  return (
    <Box sx={alignCenter}>
      <Typography variant="body2">
        {presentationText}
      </Typography>
    </Box>
  );
};