import { Box } from "@mui/material";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

export const NoBookmarks = () => (
  <>
    <FormattedTypography id="more.title.bookmarksandnotes" variant="h1" />
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        alignItems: "center",
        flexDirection: "column",
        ">img": { width: "110px" },
        marginTop: 6,
      }}
    >
      <img src={process.env.PUBLIC_URL + "/img/illustrations/person-roller.svg"} alt="Man painting" />
      <FormattedTypography id="bookmarks.no.bookmarks" variant="body1" mt={2} mb={2} />
    </Box>
  </>
);
