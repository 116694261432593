import { useRecoilValue } from "recoil";
import { learningStructureState } from "state/learningStructureState";
import { useCallback, useEffect, useState } from "react";
import { ExtendedTreeNode } from "types/cds";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";
import { useRouteConfiguration } from "./useRouteConfiguration";
import { useSetDefaultTheoryState } from "./useSetDefaultTheoryState";
import { StateKey, TheoryState, getState, theoryStateId } from "services/appStateService";
import { educationState } from "state/educationState";
import { UserEducation } from "types/education";

/**
 * Hook for getting the latest activity and tab in theory
 */
export const useContinueWhereIWas = () => {
  type Activity = ReturnType<typeof setDefaultTheoryState>;
  const { theory, theoryPractice, theoryAnswer, theoryRead } = useRouteConfiguration();
  const learningStructure = useRecoilValue(learningStructureState);
  const education = useRecoilValue(educationState);
  const { setDefaultTheoryState } = useSetDefaultTheoryState();
  const [activity, setActivity] = useState<Activity | undefined>();
  const [path, setPath] = useState<string>();
  const [notStarted, setNotStarted] = useState<boolean>(false);

  const getPath = useCallback(
    (currentActivity: Activity, state: TheoryState[] | undefined) => {
      // We couldn´t find any currentActivity, let user start on theory main page
      if (!currentActivity) return theory.path;

      let path = "";
      const areaId = currentActivity?.parentId!.toString();
      const chapterId = currentActivity?.id!.toString();
      const selectedTab = !state ? TheoryTab.read : state![0].page;

      switch (selectedTab) {
        case TheoryTab.practice:
          path = theoryPractice.path;
          break;

        case TheoryTab.answer:
          path = theoryAnswer.path;
          break;

        default:
          path = theoryRead.path;
          break;
      }

      path = path.replace(":areaId", areaId).replace(":chapterId", chapterId);
      return path;
    },
    [theory.path, theoryAnswer.path, theoryPractice.path, theoryRead.path]
  );

  const setState = useCallback(
    async (learningStructure: ExtendedTreeNode[], education: UserEducation) => {
      let currentArea: Activity = undefined;

      // Get saved state
      const key: StateKey = { typeId: theoryStateId, auxId1: education.contentId, auxId2: education.versionId };
      let state = await getState<TheoryState>(key);

      // Set default theory state
      currentArea = setDefaultTheoryState(learningStructure, state);

      if (!state) setNotStarted(true);

      setActivity(currentArea);
      setPath(getPath(currentArea, state));
    },
    [setDefaultTheoryState, getPath]
  );

  useEffect(() => {
    if (!learningStructure) return;
    if (!education) return;

    setState(learningStructure, education);
  }, [learningStructure, education, setState]);

  return { activity, path, notStarted };
};
