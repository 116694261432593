import { ListItemIcon, MenuItem, useTheme } from "@mui/material";
import { IconProp, Icon } from "theme/icons";
import { testId } from "tests/testIdStrings";
import { DataTestType } from "tests/test.setup";

export interface IMenuItem {
  /** If no id is set, the item will not be selectable */
  id?: string;
  title: string;
  icon?: IconProp;
  /** Route link */
  to?: string;
  selected?: boolean;
}

export interface IMenuItems extends DataTestType {
  items: IMenuItem[];
  onClick?: (item: IMenuItem) => void;
}

export const MenuItems = (props: IMenuItems) => {
  const theme = useTheme();

  const handleClick = (item: IMenuItem) => () => {
    if (props.onClick) props.onClick(item);
  };

  return (
    <>
      {props.items.map((item, index) => (
        <MenuItem
          key={item.id ?? item.title}
          onClick={handleClick(item)}
          data-testid={`${props.dataTestIdString}-${testId.common.dropDown.menuItem}-${index + 1}`}
        >
          {item.icon && (
            <ListItemIcon sx={{ marginRight: 1 }}>
              <Icon name={item.icon} size={"medium"} htmlColor={theme.palette.primary.dark06} />
            </ListItemIcon>
          )}
          {item.title}
        </MenuItem>
      ))}
    </>
  );
};
