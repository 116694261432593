import React, { FC, useMemo, useRef } from "react";
import { Educator } from "types/educator";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { appConfig } from "appConfig";
import { useNavigate } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import pinEducator from "static/pinEducator.png";
import pinEducatorStr from "static/pinEducatorStr.png";

type StartLocation = {
  latitude: number;
  longitude: number;
};

const EducatorsMap: FC<{
  centerOfSweden: StartLocation;
  dynamicHeight: number;
  educators?: Educator[];
  position: StartLocation;
}> = ({ centerOfSweden, dynamicHeight, educators, position }) => {
  const mapRef = useRef<GoogleMap>(null);
  const navigate = useNavigate();
  const routes = useRouteConfiguration();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appConfig.googleMapsApiKey,
    language: "sv-SE",
    region: "sv-SE",
  });

  const markersMemo = useMemo(() => {
    if (!educators) return;

    const filteredEducators = educators?.filter((x) => x.location && x.location.latitude && x.location.longitude);

    const handleOnclick = (educationIdentifier: string) => {
      navigate(`${routes.educator.path}/${educationIdentifier}`);
    };

    return filteredEducators.map((x) => (
      <MarkerF
        icon={x.isSTRMember ? pinEducatorStr : pinEducator}
        onClick={() => handleOnclick(x.identifier)}
        key={x.identifier}
        position={{ lat: x.location.latitude, lng: x.location.longitude }}
        title={x.name}
      />
    ));
  }, [educators, navigate, routes.educator.path]);

  const zoom = JSON.stringify(position) === JSON.stringify(centerOfSweden) ? 5 : 13;

  if (!isLoaded) return null;

  return (
    <GoogleMap
      ref={mapRef}
      clickableIcons={false}
      mapContainerStyle={{ height: dynamicHeight }}
      center={{ lat: position.latitude, lng: position.longitude }}
      zoom={zoom}
    >
      {markersMemo}
    </GoogleMap>
  );
};

export default React.memo(EducatorsMap);
