import { FC, useMemo } from "react";
import { Stack } from "@mui/system";
import { testId } from "tests/testIdStrings";
import { iconPhoneSolid, iconSolidEnvelope, iconExternalLink, iconSolidGlobe } from "theme/icons";
import { EducatorDetails } from "types/educator";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

export const ContactInfo: FC<{
  homePage: EducatorDetails["homepage"];
  email: EducatorDetails["email"];
  phone: EducatorDetails["phone"];
}> = ({ homePage, email, phone }) => {
  const homePageUrl = useMemo(() => {
    const regex = /^https?:\/\//;

    if (regex.test(homePage)) return homePage;

    return `https://${homePage}`;
  }, [homePage]);

  return (
    <>
      <FormattedTypography variant="h2" id="educator.details.contact.header" />
      <Stack spacing={2} marginTop="12px!important">
        <NavigationItem
          text={homePage}
          navigationIcon={iconExternalLink}
          dataTestIdString={testId.listItem.educator.contact.website}
          iconSize="medium"
          icon={iconSolidGlobe}
          href={homePageUrl}
          hrefTarget="_blank"
        />
        <NavigationItem
          text={email}
          navigationIcon={iconExternalLink}
          dataTestIdString={testId.listItem.educator.contact.email}
          onClick={() => (window.location.href = `mailto:${email}`)}
          iconSize="medium"
          icon={iconSolidEnvelope}
        />
        <NavigationItem
          text={phone}
          icon={iconPhoneSolid}
          navigationIcon={iconExternalLink}
          dataTestIdString={testId.listItem.educator.contact.phone}
          onClick={() => (window.location.href = `tel:${phone}`)}
          iconSize="medium"
        />
      </Stack>
    </>
  );
};
