import { Modal, Stack, useTheme } from "@mui/material";
import { Title } from "utils/helpers/TitleDivider";
import { useAppIntl } from "services/useAppIntl";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import SvgImage, { svgColor, ellipse4 } from "utils/helpers/SvgImage";
import { IconProp, SizeProp, iconLightBulb, iconPlay } from "theme/icons";
import { TestHowToVideoModal } from "layout/test/testHowToVideoModal";
import { useState } from "react";
import { GetStartedModal } from "layout/getStartedModal";
import { Variant } from "@mui/material/styles/createTypography";

export const GetStarted = () => {
  const intl = useAppIntl();

  return (
    <>
      <BackgroundShape top={-240} right={-410}>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={130}
          width="615"
          height="430"
          color={svgColor.pink}
        />
      </BackgroundShape>

      <Title title={intl.formatMessage({ id: "more.title.getstarted" })} />

      <FormattedTypography id="more.getstarted.info" mb={3} variant="body2" />
      <Stack spacing={2}>
        <HowToUseKKBVideo />
        <HowToUseTestVideo />
      </Stack>

      {/* <ButtonBack dataTestIdString={testId.common.text.roadSigns} /> */}

      <BackgroundShape bottom={-160} left={-230}>
        <SvgImage d={ellipse4.d} viewBox={ellipse4.viewBox} width="450" height="300" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};

type HowToProps = { subTextVariant?: Variant; icon?: IconProp | IconProp[]; iconSize?: SizeProp };

export const HowToUseKKBVideo = ({ subTextVariant, icon, iconSize }: HowToProps) => {
  const intl = useAppIntl();
  const theme = useTheme();
  const [showKKBHowToVideo, setShowKKBHowToVideo] = useState(false);

  return (
    <>
      <NavigationItem
        size="large"
        color={theme.palette.info.main}
        text={intl.formatMessage({ id: "home.getstarted" })}
        subText={<FormattedTypography id="home.getstarted.subtext" variant={subTextVariant ?? "breadcrumbs"} />}
        icon={icon ?? iconLightBulb}
        iconSize={iconSize ?? "medium"}
        navigationIcon={iconPlay}
        onClick={() => setShowKKBHowToVideo(true)}
        dataTestIdString={testId.listItem.getStarted.howToKb}
      />

      <Modal open={showKKBHowToVideo} onClick={() => setShowKKBHowToVideo(false)}>
        <>
          <GetStartedModal triggerGetStartedModal={setShowKKBHowToVideo} />
        </>
      </Modal>
    </>
  );
};

export const HowToUseTestVideo = ({ subTextVariant, iconSize }: HowToProps) => {
  const intl = useAppIntl();
  const theme = useTheme();
  const [showTestHowToVideo, setShowTestHowToVideo] = useState(false);

  return (
    <>
      <NavigationItem
        size="auto"
        color={theme.palette.info.main}
        text={intl.formatMessage({ id: "test.type.study.howto.video" })}
        subText={
          <FormattedTypography id="test.type.study.howto.video.subtitle" variant={subTextVariant ?? "breadcrumbs"} />
        }
        icon={iconLightBulb}
        iconSize={iconSize ?? "medium"}
        navigationIcon={iconPlay}
        onClick={() => setShowTestHowToVideo(true)}
        dataTestIdString={testId.listItem.getStarted.howToTest}
      />

      <Modal open={showTestHowToVideo} onClick={() => setShowTestHowToVideo(false)}>
        <>
          <TestHowToVideoModal triggerTestHowToVideoModal={setShowTestHowToVideo} />
        </>
      </Modal>
    </>
  );
};
