export const isAnyTrue = (values: {}) => {
  return Object.values(values).includes(true);
};

export const isSwedishPhoneNumber = (value: string) => {
  const regexSwedishPhoneNumber = /^([+]46|0)\s*(7[02369])\s*(\d{4})\s*(\d{3})$/;
  return !!value.match(regexSwedishPhoneNumber);
};

export const isSwedishSocialSecurityNumber = (value: string) => {
  const regexSwedishSocialSecurityNum = /^(\d{6})[-]\d{4}|(\d{8})[-]\d{4}$/; // YYMMDD-XXXX / YYYYMMDD-XXXX

  value = formatIdentityNumber(value);
  const isValid = regexSwedishSocialSecurityNum.test(value);

  if (!isValid) {
    return false;
  }

  return isCheckSumValid(value);
};

export const formatIdentityNumber = (value: string): string => {
  const regexSwedishSocialSecurityNumNoDash = /^(\d{10}|\d{12})$/; // YYMMDDXXXX / YYYYMMDDXXXX
  const isValidNoDash = regexSwedishSocialSecurityNumNoDash.test(value);

  if (isValidNoDash) {
    // Add dash if missing
    value = value.slice(0, value.length - 4) + "-" + value.slice(value.length - 4);
  }
  if (value.length === 13) {
    // remove first two numbers
    value = value.substring(2, value.length);
  }

  return value;
};

/**
 *
 * @param value identity number/social security number
 * @returns true if the last digit is valid, else false
 */
const isCheckSumValid = (value: string) => {
  value = value.replace("-", "");
  let sum = 0;
  let alternate = false;
  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value[i]);
    if (alternate) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    alternate = !alternate;
  }
  return sum % 10 === 0;
};
