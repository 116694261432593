import {
  SpeechSynthesizer,
  SpeechConfig,
  ResultReason,
  SpeakerAudioDestination,
  AudioConfig,
  IPlayer,
} from "microsoft-cognitiveservices-speech-sdk";
import { API_URL_BO_SPEECH } from "utils/constants";
import { SupportedLanguages } from "./localizationService";

const getMicrosoftSpeechToken = async () => {
  const url = API_URL_BO_SPEECH + "GetToken";
  const response = await fetch(url);
  const { token } = await response.json();

  return token;
};

// Global scoped vars to only use one player at a time
let player: SpeakerAudioDestination;
let currentPlayer: IPlayer | null = null;
export const textToSpeech = (
  setSpeechInProgress: React.Dispatch<React.SetStateAction<boolean>>,
  locale?: SupportedLanguages
) => {
  let lang = "";
  let voice = "";

  switch (locale) {
    case "en":
      lang = "en-GB";
      voice = `${lang}-LibbyNeural`;
      break;
    default:
      lang = "sv-SE";
      voice = `${lang}-SofieNeural`;
      break;
  }

  function pause() {
    if (player) player.pause();
  }

  function resume() {
    if (player) player.resume();
  }

  const stop = () => {
    if (player) player.pause();
  };

  function start(text: string) {
    getMicrosoftSpeechToken().then((token) => {
      console.log("tts with language", lang, "and voice", voice);

      const speechConfig = SpeechConfig.fromAuthorizationToken(token, "northeurope");
      speechConfig.speechSynthesisLanguage = lang;
      speechConfig.speechSynthesisVoiceName = voice;
      player = new SpeakerAudioDestination();
      const audioConfig = AudioConfig.fromSpeakerOutput(player);
      let synthesizer: SpeechSynthesizer | undefined = new SpeechSynthesizer(speechConfig, audioConfig);

      player.onAudioEnd = (p) => {
        setSpeechInProgress(false);
        currentPlayer = null;
      };

      player.onAudioStart = (p) => {
        // Stop current player first
        if (currentPlayer) currentPlayer.pause();

        setSpeechInProgress(true);
        currentPlayer = p;
      };

      synthesizer.speakTextAsync(
        text ?? "",
        function (result) {
          if (result.reason === ResultReason.SynthesizingAudioCompleted) {
            synthesizer?.close();
            synthesizer = undefined;
          } else if (result.reason === ResultReason.Canceled) {
            console.log(result.errorDetails);
            synthesizer?.close();
            synthesizer = undefined;
          }
        },
        function (err) {
          console.log("synthesizer error", err);
          synthesizer?.close();
          synthesizer = undefined;
        }
      );
    });
  }

  return { start, pause, resume, stop };
};
