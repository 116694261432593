import React, { ReactNode } from "react";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { DataTestType } from "tests/test.setup";

interface IButtonLinkProps extends DataTestType {
  children: ReactNode;
  to: string;
}
export const ButtonLink: React.FC<IButtonLinkProps> = (props) => {
  const size = "40px";

  return (
    <IconButton
      to={props.to}
      component={Link}
      data-testid={`${testId.menu.header.button}-${props.dataTestIdString}`}
      sx={{
        width: size,
        height: size,
        borderRadius: "50px",
        border: "0.1rem white solid",
      }}
    >
      {props.children}
    </IconButton>
  );
};
