import { Button, List, Stack, Typography, alpha, Theme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { IResultOverview, NavigateTestState } from "./testResult";
import { ListItemButton } from "utils/helpers/ListItemButton";
import { Icon, iconCommentsQuestionActive } from "theme/icons";
import { common } from "@mui/material/colors";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { useEffect } from "react";
import { deleteLrsState } from "services/lrsService";
import { useTestResult } from "hooks/useTestResult";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppIntl } from "services/useAppIntl";

export const Overview = ({ xapiKey, isAnswer }: IResultOverview) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useAppIntl();
  const { getDateAsLocalDateString: getDateAsString } = useDateService();
  const { testResults } = useTestResult({ referenceKey: xapiKey });
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // Always delete previous test from LRS when entering this page
  useEffect(() => {
    const deleteTest = async () => {
      if (!xapiKey) return;
      await deleteLrsState(xapiKey);
    };

    deleteTest();
  }, [xapiKey]);

  const handleMoreQuestions = async () => {
    const navigateState: NavigateTestState = { redirectTo: location.pathname };
    navigate("/resetTest", { state: navigateState });
  };

  if (!testResults) return null;

  return (
    <>
      <FormattedTypography id="common.overview" variant="h1" mb={3} />

      <Stack spacing={3}>
        {/* TODO: Lägga in mer data när den väl är på plats */}
        {/* <LinearProgressIndicator value={Math.floor(Math.random() * 50)} color="success" progressText height="8px" />
        <Stack>
          <FormattedTypography id="home.knowledgelevel.progress" variant="h2" mb={2} />
          <Box>Todo: diagram</Box>
        </Stack> */}

        <Stack>
          <FormattedTypography id="common.title.test.previous-result" variant="h2" mb={2} />
          <List component={"nav"} sx={{ mx: -2, pb: "3rem" }}>
            {testResults.map((item: ItemTestResultItem, index: number) => {
              const finishedDate = new Date(item.finished);
              return (
                <ListItemButton
                  key={item.finished}
                  startIcon={<Icon name={iconCommentsQuestionActive} color="info" />}
                  primaryText={`${item.value}/${item.max} ${intl.formatMessage({
                    id: "common.result-overview.correct",
                  })}`}
                  endIcon={
                    <Typography variant="breadcrumbs" color={alpha(common.black, 0.87)}>
                      {getDateAsString(finishedDate)}
                    </Typography>
                  }
                  dataTestIdString={`${testId.listItem.question.item}.${index + 1}`}
                />
              );
            })}
          </List>
        </Stack>
      </Stack>

      {isAnswer && (
        <Stack
          spacing={2}
          sx={{
            position: isMobile ? "fixed" : "relative",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            p: 2,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{ px: 2 }}
            data-testid={`${testId.button.testPrefix}.${testId.button.moreQuestions}`}
            onClick={handleMoreQuestions}
          >
            <FormattedMessage id="question.more-questions" />
          </Button>
        </Stack>
      )}
    </>
  );
};
