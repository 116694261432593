import { useEffect, useState } from "react";

/**
 * A custom hook to get/set localStorage data. Use it as getItem when only "key" is supplied.
 * @param key key to store your value
 * @param initialValue if supplied will set the value on mount. if not you can use the setValue function later to set your value
 * @returns [value, setValue, removeItem]
 */
const useLocalStorage = <T>(key: string, initialValue?: T) => {
  const item = localStorage.getItem(key);
  const val = item === "undefined" || item === null ? null : JSON.parse(item);
  const [value, setValue] = useState(initialValue ?? val);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  const removeItem = () => {
    localStorage.removeItem(key);
  };

  return [value, setValue, removeItem];
};

export default useLocalStorage;
