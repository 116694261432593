import React from "react";
import { Box, Button } from "@mui/material";
import { Icon, iconClose } from "theme/icons";
import { alignCenter } from "theme/styles";
import Video from "layout/Components/Video";

type Props = {
  triggerTestHowToVideoModal: Function;
};

export const TestHowToVideoModal = React.forwardRef(({ triggerTestHowToVideoModal }: Props, ref) => {
  return (
    <Box height={"100%"} width={"100%"} ref={ref}>
      <Button
        style={{ position: "absolute", right: "10px", top: "10px" }}
        onClick={() => triggerTestHowToVideoModal(false)}
      >
        <Icon name={iconClose} htmlColor={"white"} size={"xlarge"} />
      </Button>
      <Box sx={alignCenter} height={"100%"} width={"100%"}>
        <Video id="testHowTo" height="75%" />
      </Box>
    </Box>
  );
});
