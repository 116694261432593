import { PropsWithChildren, SyntheticEvent, useEffect, useState } from "react";
import { alpha, Container, Tab, Tabs, Theme, useMediaQuery, useTheme } from "@mui/material";
import {
  Icon,
  iconTheoryActive,
  iconTheory,
  iconCommentsQuestion,
  iconCommentsQuestionActive,
  iconPuzzle,
  iconPuzzleActive,
} from "theme/icons";
import { styleRoundedBottom } from "theme/styles";
import { Link, useLocation, useParams } from "react-router-dom";
import FormattedMessage from "../FormattedMessage";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { testId } from "tests/testIdStrings";
import { common } from "@mui/material/colors";

export enum TheoryTab {
  read = "read",
  practice = "practice",
  answer = "answer",
}

export const TopNavigationTabs = () => {
  const location = useLocation();
  const { areaId, chapterId } = useParams();
  const [activeTab, setActiveTab] = useState<TheoryTab>(TheoryTab.read); /* Todo: temporary context*/
  const routes = useRouteConfiguration();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const handleChange = (event: SyntheticEvent, newValue: TheoryTab) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const tab = location.pathname.split("/").pop() as TheoryTab;
    setActiveTab(tab);
  }, [location]);

  return (
    <StyledTabs>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={{ sx: { top: "0" } }}
        textColor={isDesktop ? "primary" : "inherit"}
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          component={Link}
          to={routes.theoryRead.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.read ? iconTheoryActive : iconTheory} />}
          iconPosition="start"
          value={TheoryTab.read}
          data-testid={testId.menu.tab.read}
          label={<FormattedMessage id="common.title.read" />}
        />
        <Tab
          component={Link}
          to={routes.theoryPractice.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.practice ? iconPuzzleActive : iconPuzzle} />}
          iconPosition="start"
          value={TheoryTab.practice}
          data-testid={testId.menu.tab.practice}
          label={<FormattedMessage id="common.title.practice" />}
        />
        <Tab
          component={Link}
          to={routes.theoryAnswer.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.answer ? iconCommentsQuestionActive : iconCommentsQuestion} />}
          iconPosition="start"
          value={TheoryTab.answer}
          data-testid={testId.menu.tab.answer}
          label={<FormattedMessage id="common.title.answer" />}
        />
      </Tabs>
    </StyledTabs>
  );
};

const StyledTabs = (props: PropsWithChildren) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const styleDesktop = {
    mt: 1,
    alignSelf: "center",
    ".MuiTabs-root": {
      boxShadow: theme.shadows[2],
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      overflow: "hidden",
    },
    ".MuiTab-root": {
      backgroundColor: theme.palette.common.white,
      "&.Mui-selected": { backgroundColor: alpha(theme.palette.primary.main, 0.04) },
    },
    ".MuiTabs-indicator": { backgroundColor: theme.palette.primary.main, top: "unset" },
  };
  const styleMobile = {
    ...styleRoundedBottom,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    px: "0 !important",
    ".MuiTab-root": { color: "#FFFFFF !important" },
    ".MuiTabs-indicator": { backgroundColor: common.white },
  };

  return (
    <Container maxWidth="lg" sx={isDesktop ? styleDesktop : styleMobile}>
      {props.children}
    </Container>
  );
};
