import { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { iconTrashcan } from "theme/icons";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { BookmarkState } from "services/appStateService";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { bookmarkStateId, StateKey, saveState } from "services/appStateService";
import { educationState } from "state/educationState";
import { bookmarkStateBookmarkAdded } from "state/bookmarkState";
import { DeleteBookmarkDialog } from "./deleteBookmarkDialog";
import { educationContentTypeState } from "state/learningStructureState";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { useAppIntl } from "services/useAppIntl";
import { scrollToElement } from "utils/helpers/ScrollHandler";

type Props = {
  selectedBookmark: BookmarkState;
  open: boolean;
  close: () => void;
};

export const EditBookmarkDialog = ({ selectedBookmark, open, close }: Props) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [noteText, setNoteText] = useState(selectedBookmark?.text ?? "");
  const education = useRecoilValue(educationState);
  const classificationId = useRecoilValue(educationContentTypeState).get(selectedBookmark.type);
  const { setSnackbarValues } = useGlobalSnackbarMessage();
  const intl = useAppIntl();
  const added = useSetRecoilState(bookmarkStateBookmarkAdded);
  const refreshBookmarks = () => added((n: number) => n + 1);

  const handleSave = async () => {
    const key: StateKey = {
      typeId: bookmarkStateId,
      auxId1: education?.identifier,
      auxId2: selectedBookmark.key.contentId,
      auxId3: classificationId,
    };

    const bookmark = { ...selectedBookmark, text: noteText, date: new Date().toLocaleDateString() };

    await saveState<BookmarkState>(key, bookmark);
    refreshBookmarks();
    setTimeout(() => {
      close();
      setSnackbarValues({ message: intl.formatMessage({ id: "bookmarks.save.message" }), type: "success" });

      // We need to scroll to the bookmarked activity (again) since the refreshBookmarks() causes a re-render
      const selector = `[id='${selectedBookmark.key.contentId}']`;
      scrollToElement({ selector, offsetTop: -100 });
    }, 250);
  };

  return (
    <>
      <DeleteBookmarkDialog
        open={showDeleteDialog}
        close={() => setShowDeleteDialog(false)}
        selectedBookmark={selectedBookmark}
      />
      <DialogMessage
        title={"bookmarks.edit.note.title"}
        open={open}
        close={() => close()}
        primaryButtonText={"common.save"}
        primaryButtonColor="primary"
        primaryButtonDisabled={selectedBookmark.text === noteText}
        primaryButtonAction={() => handleSave()}
        secondaryButtonText={"bookmarks.remove.note"}
        secondaryButtonAction={() => {
          close();
          setShowDeleteDialog(true);
        }}
        secondaryButtonColor="error"
        secondaryButtonVariant="contained"
        secondaryButtonIcon={iconTrashcan}
      >
        <Box>
          <FormattedTypography fontSize={14} id="bookmarks.chosen.episode">
            <Typography variant="caption" fontSize={14} border={"1px solid black"} borderRadius={40} padding={1}>
              {selectedBookmark.title}
            </Typography>
          </FormattedTypography>
          <TextField
            multiline
            rows={5}
            sx={{ width: "calc(100% - 16px)", marginLeft: 1, marginRight: 1, marginTop: 2 }}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          ></TextField>
        </Box>
      </DialogMessage>
    </>
  );
};
