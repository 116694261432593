import { useState } from "react";
import { useTestResult } from "hooks/useTestResult";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { TestLabel } from "layout/test/test";
import { useLocation } from "react-router";
import { Box, Tabs, Tab, Theme } from "@mui/material";
import { Icon, iconTestActive, iconAward } from "theme/icons";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { desktopWidth640 } from "theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ItemConfigurationTypeKnowledge } from "services/reportingService";
import { NoTestPage } from "./noTestPage";
import { TabPanel } from "./tabPanel";
import { StudyTest } from "./studyTest";
import { FinalTest } from "./finalTest";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";
import { useAppIntl } from "services/useAppIntl";

const styleTab = { display: "flex", flexDirection: "row", fontSize: 14, width: "50%", maxWidth: "unset" };
export const styleTabContent = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  minHeight: "75px",
  px: 2,
};

export const ProgressTestResults = () => {
  const { testResults } = useTestResult();
  const location = useLocation();
  const intl = useAppIntl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [tabIndex, setTabIndex] = useState(location.hash ? (location.hash === "#final" ? 1 : 0) : 0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    window.location.replace(newValue === 1 ? "#final" : "#study");
  };

  if (testResults === undefined) return null;

  const studyTests = testResults.filter(
    (result) => result.configuration.type === ItemConfigurationTypeKnowledge.StudyA
  );
  const finalTests = testResults.filter(
    (result) => result.configuration.type === ItemConfigurationTypeKnowledge.FinalA
  );

  return (
    <>
      <BackgroundShape top={-215} left={-160}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.blue} />
      </BackgroundShape>

      <Box sx={{ maxWidth: desktopWidth640, marginLeft: isMobile ? -2 : "auto", marginRight: isMobile ? -2 : "auto" }}>
        <FormattedTypography id="progress.testresults.title" variant="h1" mb={5} ml={isMobile ? 2 : 0} />
        <Tabs value={tabIndex} onChange={handleChange}>
          <Tab
            icon={<Icon name={iconTestActive} />}
            label={intl.formatMessage({ id: "progress.testresults.study" })}
            sx={styleTab}
          />
          <Tab
            icon={<Icon name={iconAward} />}
            label={intl.formatMessage({ id: "progress.testresults.final" })}
            sx={styleTab}
          />
        </Tabs>
        <TabPanel currentIndex={tabIndex} index={0}>
          {studyTests.length > 0 ? <StudyTest testResults={studyTests} /> : <NoTestPage />}
        </TabPanel>
        <TabPanel currentIndex={tabIndex} index={1}>
          {finalTests.length > 0 ? <FinalTest testResults={finalTests} /> : <NoTestPage />}
        </TabPanel>
      </Box>
    </>
  );
};

export function testTypeName(title: TestLabel) {
  switch (title) {
    case TestLabel.Answer:
      return <FormattedMessage id="common.title.answer" />;

    case TestLabel.StudyQuick:
      return <FormattedMessage id="test.type.study.quick" />;

    case TestLabel.StudyCustom:
      return <FormattedMessage id="test.type.study.custom" />;

    case TestLabel.RoadsignQuick:
      return <FormattedMessage id="test.type.roadsign.quick" />;

    case TestLabel.RoadsignCustom:
      return <FormattedMessage id="test.type.roadsign.custom" />;

    case TestLabel.Final:
      return <FormattedMessage id="common.title.test.final" />;

    default:
      return "unknown test type: " + title;
  }
}
