import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { appConfig } from "appConfig";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: true, // Disable logging

    connectionString: appConfig.app_insights_connection_string,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    maxAjaxCallsPerView: -1, // Default 500 - controls how many Ajax calls are monitored per page view. Set to -1 to monitor all (unlimited) Ajax calls on the page.
    enableAjaxErrorStatusText: true,
    enableUnhandledPromiseRejectionTracking: true,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "LearningClient_SC";
});

export { reactPlugin, appInsights };
