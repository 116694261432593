import { useLocation, useParams } from "react-router-dom";
import type { Location, Params } from "react-router-dom";

const useRoutePath = () => {
  const location = useLocation();
  const params = useParams();

  const getRoutePath = (location: Location, params: Params): string => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
      return pathname;
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`);
      }
    });
    return path;
  };

  return getRoutePath(location, params);
};

export default useRoutePath;
