import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { testId } from "tests/testIdStrings";

interface ITitleDividerProps {
  title: string | ReactNode;
  variant?: "h1" | "h2" | "h3";
  sx?: {};
}

export const Title: React.FC<ITitleDividerProps> = (props) => {
  const variant = props.variant ? props.variant : "h1";

  return (
    <Box sx={{ ...props.sx, position: "relative" }} mb={variant === "h1" ? 3 : 2}>
      <Typography variant={props.variant ? props.variant : "h1"} data-testid={testId.common.headerTitle}>
        {props.title}
      </Typography>
    </Box>
  );
};
