import { FC } from "react";
import { AppBar, Box, Toolbar, Theme } from "@mui/material";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { styleRoundedBottom } from "theme/styles";
import { useNavigate } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { useRouteConfiguration, RouteKey, SubRouteKey } from "hooks/useRouteConfiguration";
import useRoutePath from "hooks/useRoutePath";
import { IHeaderProps } from "layout/more/header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderDesktopMenu } from "../headerDesktopMenu";

export const Header: FC<IHeaderProps> = ({ children, callback }) => {
  const path = useRoutePath();
  const routes = useRouteConfiguration();
  const navigate = useNavigate();
  const intl = useAppIntl();
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  // Extract route names
  const paths = path.replace("/", "").split("/");
  const main = routes[paths[0] as RouteKey];
  const topTitle = main.name;
  let title = main.routes ? main.routes[paths[1] as SubRouteKey].name : "";
  const url = window.location.href;

  if (url.includes("roadsigns")) {
    title = intl.formatMessage({ id: "header.title.roadsignstest" });
  }

  const navigateBack = () => {
    if (callback) return callback();

    return navigate(-1);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={styleRoundedBottom}>
      <Toolbar>
        <HeaderNavigation iconSize={"large"} topTitle={topTitle} title={title} onNavigate={navigateBack} />
        {!isMediumOrSmallScreen && <HeaderDesktopMenu />}
        <Box width="220px" />
      </Toolbar>
      {children}
    </AppBar>
  );
};
