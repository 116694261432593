import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title } from "utils/helpers/TitleDivider";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useAppIntl } from "services/useAppIntl";
import { GlossaryProvider, Glossary as GlossaryComponent } from "@strmediaochitab/optima-component-library";
import { useRecoilValue } from "recoil";
import { glossaryStateSelector } from "state/glossaryState";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";

export const Glossary: React.FC = () => {
  const intl = useAppIntl();
  const glossary = useRecoilValue(glossaryStateSelector);
  const navigate = useNavigate();
  const routes = useRouteConfiguration();

  const navigateTo = (word: string) => {
    navigate(routes.glossaryDetail.path.replace(":id", word));
  };

  if (!glossary) return null;

  return (
    <>
      <BackgroundShape top={-210} left={-100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.turquoise} />
      </BackgroundShape>
      <Title title={intl.formatMessage({ id: "more.title.glossary" })}></Title>
      <GlossaryProvider callback={navigateTo} scrollOffset={80}>
        <Box sx={{ marginLeft: -2, marginRight: -2 }}>
          <GlossaryComponent items={glossary.items} />
        </Box>
      </GlossaryProvider>
    </>
  );
};
