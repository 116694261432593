import { useScrollSpy } from "@strmediaochitab/optima-component-library";
import { useRecoilValue } from "recoil";
import { theoryChapterState, theoryStateChildrenByParentId } from "state/theoryState";

export const useActiveSectionScrollSpy = () => {
  const chapterState = useRecoilValue(theoryChapterState);
  const sections = useRecoilValue(theoryStateChildrenByParentId(chapterState ? chapterState.id : 0));
  const linkItems = sections.map((section) => {
    return { hash: section.activityKey!.contentId, label: section.title };
  });

  return useScrollSpy(linkItems);
};
