import { Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";
import FormattedMessage, { FormattedMessageProps } from "utils/helpers/FormattedMessage";

type InheritedProps = FormattedMessageProps & TypographyProps;
type IFormattedTypographyProps = Omit<InheritedProps, "children"> & {
  children?: ReactNode;
};

export const FormattedTypography: React.FC<IFormattedTypographyProps> = ({ id, children, values, ...rest }) => {
  return (
    <Typography {...rest}>
      <FormattedMessage id={id} values={values} />
      {children}
    </Typography>
  );
};
