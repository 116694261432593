import { Gloss, GlossaryProvider } from "@strmediaochitab/optima-component-library";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { glossaryStateDetail } from "state/glossaryState";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import ButtonBack from "utils/helpers/ButtonBack";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";

export const GlossaryDetail = () => {
  const { id } = useParams();
  const glossaryItem = useRecoilValue(glossaryStateDetail({ id: id ? id : "" }));

  if (!glossaryItem) return null;

  return (
    <GlossaryProvider>
      <BackgroundShape top={-90} right={-200}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.turquoise} />
      </BackgroundShape>

      <Gloss item={glossaryItem} />

      <ButtonBack dataTestIdString={testId.common.text.roadSigns} />
    </GlossaryProvider>
  );
};
