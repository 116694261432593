import { atom } from "recoil";

export const loadingState = atom<boolean | null>({
  key: "loader",
  default: null,
});
export const loadingStateText = atom<string | null>({
  key: "loaderText",
  default: null,
});

export const maintenanceState = atom<boolean>({
  key: "maintenance",
  default: true,
});

export const appMessageState = atom<JSX.Element | undefined>({
  key: "appMessageState",
  default: undefined,
});
