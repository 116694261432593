import { EducationStateEnum } from "./education";

export class EducationActionRequest
{
  constructor(educationIdentifier: string) {
    this.educationIdentifier = educationIdentifier;    
  }
    educationIdentifier: string;
}

export class EducationActivateRequest extends EducationActionRequest
{
  constructor( educationIdentifier: string, state : EducationStateEnum) {
    super(educationIdentifier);
    this.state = state;
  }
  state : EducationStateEnum;

}