import { Box } from "@mui/system";
import { BasicLayout } from "layout";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";

export const AppOutro = () => {
  return (
    <BasicLayout paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0}>
      <BackgroundShape top={-210} left={-100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>

      <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" padding="2rem">
        <FormattedTypography id="common.app.title" variant="h1" color="primary.dark06" fontSize="32px" mb={5} />
        <Box display="flex" justifyContent="center">
          <img src={process.env.PUBLIC_URL + "/img/illustrations/sign-out.png"} alt="Woman skateboarding" />
        </Box>
      </Box>

      <BackgroundShape bottom={-300} position="fixed" center stickToBottom>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="1000" height="400" color={svgColor.turquoise} />
      </BackgroundShape>
    </BasicLayout>
  );
};
