import {
  iconSeedling,
  iconRoad,
  iconCity,
  IconProp,
  iconBookParagraph,
  iconGasPump,
  iconBus,
  iconUserSafety,
  iconHeadHeart,
  iconTaxiBus,
} from "theme/icons";

type AreaName = {
  name: string[]; // [Swedish, English] in lowercase
  icon: IconProp;
};

type Areas = {
  [key: string]: AreaName;
};

export const areas: Areas = {
  // Bil + Lastbil
  grund: {
    name: ["grund", "???"],
    icon: iconSeedling,
  },
  city: {
    name: ["stad", "city"],
    icon: iconCity,
  },
  mainRoad: {
    name: ["landsväg", "main road"],
    icon: iconRoad,
  },

  // Lastbil
  transportRules: {
    name: ["regler inom transporter", "transport rules"],
    icon: iconBookParagraph,
  },

  // YKB
  lawsAndRules: {
    name: ["lagar och regler", "laws and rules"],
    icon: iconBookParagraph,
  },
  energy: {
    name: ["energioptimering", "energyoptimization"],
    icon: iconGasPump,
  },
  personTransport: {
    name: ["persontransporter", "person transports"],
    icon: iconBus,
  },
  itemTransport: {
    name: ["godstransporter", "item transports"],
    icon: iconBus,
  },
  safety: {
    name: ["trafiksäkerhet", "traffic safety"],
    icon: iconUserSafety,
  },
  health: {
    name: ["personlig hälsa och säkerhet", "personal health and safety"],
    icon: iconHeadHeart,
  },
  transportBusiness: {
    name: ["transportbranschen", "transport business"],
    icon: iconTaxiBus,
  },
};

export const getAreaIcon = (name: string) => {
  const area = name.toLowerCase();

  switch (true) {
    case areas.city.name.includes(area):
      return areas.city.icon;

    case areas.mainRoad.name.includes(area):
      return areas.mainRoad.icon;

    case areas.lawsAndRules.name.includes(area):
      return areas.lawsAndRules.icon;

    case areas.transportRules.name.includes(area):
      return areas.transportRules.icon;

    case areas.energy.name.includes(area):
      return areas.energy.icon;

    case areas.personTransport.name.includes(area):
      return areas.personTransport.icon;

    case areas.itemTransport.name.includes(area):
      return areas.itemTransport.icon;

    case areas.safety.name.includes(area):
      return areas.safety.icon;

    case areas.health.name.includes(area):
      return areas.health.icon;

    case areas.transportBusiness.name.includes(area):
      return areas.transportBusiness.icon;

    default:
      return iconSeedling;
  }
};
