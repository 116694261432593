import { useMsal } from "@azure/msal-react";
import { msalConfig } from "authConfig";

/**
 *
 * @returns Logout from application and clear local- and session storage
 */
export const useLogout = () => {
  const { instance } = useMsal();

  const logout = async (reason?: "forgotPassword") => {
    sessionStorage.clear();

    await instance.handleRedirectPromise();
    await instance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri + (reason !== "forgotPassword" ? "#logout" : ""),
    });
  };

  return { logout };
};
