import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { DataTestType } from "tests/test.setup";
import { testId } from "tests/testIdStrings";
import { Icon, iconArrowLeftRegular } from "theme/icons";

interface IButtonBack extends DataTestType {
  dataTestIdString: string;
}

const ButtonBack = (props: IButtonBack) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(-1)}
      variant="contained"
      sx={{
        width: "50%",
        maxWidth: "12rem",
      }}
      data-testid={`${props.dataTestIdString}-${testId.common.back}`}
    >
      <Icon name={iconArrowLeftRegular} sx={{ marginRight: "0.5rem" }} />
      <FormattedMessage id={"common.back"} />
    </Button>
  );
};

export default ButtonBack;
