import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputPhoneNumberProps {
  control: Control<any, any> | undefined;
}
export const FormInputLastName: React.FC<IFormInputPhoneNumberProps> = ({ control }) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name="lastName"
      label={intl.formatMessage({ id: "profile.label.last-name" })}
      dataTestIdString={`${testId.input.profile}-lastname`}
      control={control}
    />
  );
};
