import React, { FC, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { Educator } from "types/educator";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Box, IconButton, ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { ReactComponent as MissingImg } from "static/defaultImg.svg";
import { Icon, iconArrowRightRegular, iconLocationArrow } from "theme/icons";
import { common } from "@mui/material/colors";
import { alpha } from "@mui/material";
import { distanceKm } from "utils/helpers/distanceHelper";
import SearchField from "layout/Components/SearchField";

export const DefaultImg: FC = () => (
  <Box
    display={"flex"}
    width="57px"
    height="57px"
    alignItems="center"
    justifyContent="center"
    sx={{
      background: alpha(common.black, 0.04),
    }}
  >
    <MissingImg />
  </Box>
);

const RenderRow: FC<ListChildComponentProps<Educator[]>> = ({ index, style, data: educators }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const routes = useRouteConfiguration();

  const handleOnclick = useCallback(
    (educationIdentifier: string) => {
      navigate(`${routes.educator.path}/${educationIdentifier}`);
    },
    [navigate, routes.educator]
  );
  const educator = educators[index];

  const address = useMemo(
    () =>
      educator.street1.trim() +
      educator.street2.trim() +
      `${(educator.street1 || educator.street2) && educator.city ? ", " : ""}` +
      educator.city.trim(),
    [educator.street1, educator.street2, educator.city]
  );

  return (
    <ListItem
      style={style}
      sx={{ borderBottom: "1px solid", borderColor: "#0000001f", borderRadius: "0", paddingLeft: 2 }}
      key={index}
      component="div"
      disablePadding
      onClick={() => handleOnclick(educators[index].identifier)}
    >
      {/* Hide for now #6965
      <ListItemAvatar sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <DefaultImg />
      </ListItemAvatar>
      */}
      <ListItemText
        primary={
          <Typography component="span" color="text.primary" variant="h3">
            {educator.name}
          </Typography>
        }
        secondary={
          <Typography component="span" variant="body2" fontSize={13} color="text.primary">
            {address}
            {distanceKm(educator.distance!)}
          </Typography>
        }
      />

      <IconButton sx={{ paddingRight: 3 }}>
        <Icon name={iconArrowRightRegular} htmlColor={theme.palette.primary.dark06} />
      </IconButton>
    </ListItem>
  );
};

// TODO: Some styling, (with and divider length etc) #5555
const EducatorsList: FC<{ dynamicHeight: number; educatorsSortedByDistance: Educator[] }> = ({
  dynamicHeight,
  educatorsSortedByDistance,
}) => {
  const getItemKey = (index: number, data: Educator[]) => data[index].identifier;
  const intl = useAppIntl();
  const [search, setSearch] = useState("");
  const searchFieldRef = useRef(null);
  const [searchFieldHeight, setSearchFieldHeight] = useState(0);
  const filteredEducator = educatorsSortedByDistance.filter(
    (e) =>
      e.city.toLowerCase().includes(search.toLowerCase()) ||
      e.street1.toLowerCase().includes(search.toLowerCase()) ||
      e.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (searchFieldRef && searchFieldRef.current) {
      const sfRef = searchFieldRef!.current as Element;
      setSearchFieldHeight(sfRef.clientHeight);
    }
  }, []);

  return (
    <>
      <Box
        ref={searchFieldRef}
        sx={{ background: "rgba(216, 237, 238, 0.3)", padding: "16px", display: "flex", alignItems: "row" }}
      >
        <SearchField
          placeholder={intl.formatMessage({ id: "more.find.educator.search" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          startIcon={<Icon name={iconLocationArrow} color="primary" />}
        />
      </Box>
      <FixedSizeList
        height={dynamicHeight - searchFieldHeight}
        width={"100%"}
        itemKey={getItemKey}
        itemSize={90}
        itemCount={search.length > 1 ? filteredEducator.length : educatorsSortedByDistance.length}
        overscanCount={20}
        itemData={search.length > 1 ? filteredEducator : educatorsSortedByDistance}
      >
        {RenderRow}
      </FixedSizeList>
    </>
  );
};

export default React.memo(EducatorsList);
