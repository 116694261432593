import React from "react";
import { ConnectedEducator } from "types/educator";
import { Icon, iconArrowCircleRight, iconArrowRightRegular, iconCircleExclamation } from "theme/icons";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { EducatorDetail } from "./educatorDetail";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useRecoilValue } from "recoil";
import { connectedEducatorsSelector } from "state/educationState";
import { testId } from "tests/testIdStrings";
import { Title } from "utils/helpers/TitleDivider";
import { useAppIntl } from "services/useAppIntl";
import SvgImage, { svgColor, ellipse3 as ellips } from "utils/helpers/SvgImage";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { Link } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";

export const Educator = () => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const connectedEducators = useRecoilValue(connectedEducatorsSelector);

  if (!connectedEducators) return null;
  if (connectedEducators.length === 1) return <EducatorDetail />;

  return (
    <>
      <BackgroundShape top={-200} left={-40}>
        <SvgImage
          d={ellips.d}
          viewBox={ellips.viewBox}
          width="530"
          height="360"
          color={svgColor.blue2}
          rotation={-15}
        />
      </BackgroundShape>

      <Stack spacing={2}>
        <Title title={intl.formatMessage({ id: "more.title.educator" })} />
        {connectedEducators.map((ce: ConnectedEducator, index) => (
          <NavigationItem
            size="large"
            key={ce.identifier}
            href={ce.identifier}
            text={<EducatorName educator={ce} />}
            navigationIcon={iconArrowCircleRight}
            dataTestIdString={`${testId.listItem.educator.educatorItem}-${index + 1}`}
          />
        ))}
      </Stack>

      <Button
        variant="outlined"
        endIcon={<Icon name={iconArrowRightRegular} />}
        fullWidth
        sx={{ mt: 6 }}
        component={Link}
        to={routes.educatorFind.path}
      >
        {intl.formatMessage({ id: "more.title.find.educator" })}{" "}
      </Button>

      <BackgroundShape bottom={-310} left={240}>
        <SvgImage
          d={ellips.d}
          viewBox={ellips.viewBox}
          width="530"
          height="360"
          color={svgColor.blue}
          rotation={-125}
        />
      </BackgroundShape>
    </>
  );
};

const EducatorName = ({ educator }: { educator: ConnectedEducator }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Typography>{educator.name}</Typography>
      {!educator.consented && (
        <Icon name={iconCircleExclamation} htmlColor={theme.palette.warning.main} sx={{ marginLeft: 1 }} />
      )}
    </Box>
  );
};
