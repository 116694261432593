import { Theme, useMediaQuery } from "@mui/material";
import SvgImage, { svgColor, ellipse2, ellipse4 } from "utils/helpers/SvgImage";
import { BackgroundShape } from "utils/helpers/BackgroundShape";

export const Picasso = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <BackgroundShape top={-340} left={-280}>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.pink} />
      </BackgroundShape>
      <BackgroundShape top={-240} right={isMobile ? "-480px" : "-323px"}>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.turquoise2} />
      </BackgroundShape>
      {!isMobile && (
        <>
          <BackgroundShape top="30vh" left="-460px">
            <SvgImage
              d={ellipse2.d}
              rotation={200}
              viewBox={ellipse2.viewBox}
              width="570"
              height="410"
              color={svgColor.turquoise}
            />
          </BackgroundShape>

          <BackgroundShape top="35vh" right="-490px">
            <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.pink} />
          </BackgroundShape>
        </>
      )}
      <BackgroundShape position="fixed" bottom="-230px" left={isMobile ? "0" : "23vw"}>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={350}
          width="570"
          height="310"
          color={svgColor.pink2}
        />
      </BackgroundShape>
    </>
  );
};
