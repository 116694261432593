import { memo, FC, useMemo, useState } from "react";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useAppIntl } from "services/useAppIntl";
import { DropdownMenu, DropdownMenuItems } from "utils/helpers/dropdown/DropdownMenu";
import { IMenuItem } from "utils/helpers/dropdown/MenuItems";
import { Icon, iconLogout, iconMobile, iconMore } from "theme/icons";
import { testId } from "tests/testIdStrings";
import { useLogout } from "hooks/useLogout";
import { Modal } from "@mui/material";
import { GetStartedModal } from "layout/getStartedModal";
import { useRecoilValue } from "recoil";
import { connectedEducatorsSelector } from "state/educationState";

export const MoreDropdownMenu: FC = memo(() => {
  const { logout } = useLogout();
  const routes = useRouteConfiguration();
  const intl = useAppIntl();
  const [getStartedModal, triggerGetStartedModal] = useState(false);
  const connectedEducators = useRecoilValue(connectedEducatorsSelector);

  const getMenuItems = useMemo(() => {
    if (!connectedEducators) return [];

    const getStarted = intl.formatMessage({ id: "home.getstarted" });
    const logout = intl.formatMessage({ id: "common.logout" });

    const moreMenuRoutes = [
      routes.profile,
      connectedEducators.length ? routes.educator : routes.educatorFind,
      routes.roadsigns,
      routes.myeducation,
      {
        name: getStarted,
        icon: iconMobile,
        path: "",
      },
      routes.support,
      {
        name: logout,
        icon: iconLogout,
        path: "",
      },
    ];

    const items: IMenuItem[] = moreMenuRoutes.map((route) => {
      if (route.name === getStarted) return { title: route.name, icon: route.icon };
      if (route.name === logout) return { id: "logout", title: route.name, icon: route.icon };

      return { id: route.name, title: route.name, icon: route.icon, to: route.path };
    });

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedEducators]);

  const handleOnClick = (item: IMenuItem) => {
    if (item.id === "logout") return logout();

    if (!item.id) return triggerGetStartedModal(true);
  };
  return (
    <>
      <DropdownMenu
        id="ddMore"
        label={intl.formatMessage({ id: "common.title.more" })}
        endIcon={<Icon name={iconMore} />}
        sx={{ minWidth: "200px" }}
        onClick={handleOnClick}
      >
        <DropdownMenuItems items={getMenuItems} dataTestIdString={testId.listItem.more.dropdown} />
      </DropdownMenu>
      <Modal open={getStartedModal} onClick={() => triggerGetStartedModal(false)}>
        <GetStartedModal triggerGetStartedModal={triggerGetStartedModal} />
      </Modal>
    </>
  );
});
