export const optimaColors = {
  primary: "#3CA6AC",
  primaryLight: "#63B7BC",
  primaryDark: "#2A7478",
  primary01: "#D8EDEE",
  primary02: "#B1DBDE",
  primary03: "#8ACACD",
  primary04: "#63B8BD",
  primary05: "#3CA6AC",
  primary06: "#30858A",
  primary07: "#246467",
  primary08: "#184245",
  primary09: "#0C2122",

  secondary: "#FB9678",
  secondaryLight: "#FBAB93",
  secondaryDark: "#AF6954",
  secondary01: "#FEEAE4",
  secondary02: "#FDD5C9",
  secondary03: "#FDC0AE",
  secondary04: "#FCAB93",
  secondary06: "#C97860",
  secondary07: "#975A48",
  secondary08: "#643C30",
  secondary09: "#321E18",

  tertiary01: "#DAE2F1",
  tertiary02: "#B5C5E3",
  tertiary03: "#91A8D4",
  tertiary04: "#6C8BC6",
  tertiary05: "#476EB8",
  tertiary06: "#395893",
  tertiary07: "#2B426E",
  tertiary08: "#1C2C4A",
  tertiary09: "#0E1625",

  error: "#8B2638",
  errorLight: "#F1E5E7",
  success: "#45A78F",
  successLight: "#CCE9E2",
  info: "#476EB8",
  warning: "#FFF7E4",
  neutralWarning: "#F1B634"
};

export const optimaFontSize = {
  h1: "24px",
  h2: "20px",
  h3: "20px",
  h4: "20px",
  body1: "18px",
  body2: "16px",
  bodyStrong: "20px",
  input: "14px",
  subtitle1: "16px",
  subtitle2: "14px",
  caption: "12px",
  button: "16px",
  breadcrumb: "10px",
  note: "14px",
  imageText: "12px",
};
