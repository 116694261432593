import React from "react";

export const ellipse1 = {
  d: "M848.476 484.761C745.309 714.131 649.554 688.11 418.45 688.11C187.347 688.11 0 573.508 0 378.603C0 183.697 692.578 0 923.682 0C1154.79 0 956.845 243.826 848.476 484.761Z",
  viewBox: "0 0 1031 689",
};

export const ellipse2 = {
  d: "M236.63 28.1358C295.306 45.9381 372.963 24.7314 412.246 28.1358C508.382 36.4673 525.838 59.9595 572.615 201.103C599.548 325.188 604.756 459.863 279.894 377.517C54.1144 340.418 -1.71362 283.324 0.548444 174.272C2.8105 65.2188 19.3119 -55.2279 236.63 28.1358Z",
  viewBox: "0 0 585 403",
};

export const ellipse3 = {
  d: "M310.517 179.856C251.04 252.849 132.359 230.451 60.3289 206.886C-11.7017 183.321 -11.0272 138.309 21.5764 68.6568C77.9729 -29.6909 282.537 1.42971 354.568 24.9949C426.598 48.56 372.993 103.182 310.517 179.856Z",
  viewBox: "0 0 390 230",
};

export const ellipse4 = {
  d: "M396 70.4179C396 138.041 207.931 159.997 118.85 159.997C29.7684 159.997 0 161.513 0 93.8906C0 26.2677 123.151 0 212.232 0C301.314 0 396 2.79503 396 70.4179Z",
  viewBox: "0 0 396 160",
};

export const svgColor = {
  pink: "#FFF2EF",
  pink2: "#FDD5C9",
  turquoise: "#D8EDEE",
  turquoise2: "#3CA6AC",
  blue: "#DAE2F1",
  blue2: "#F2F4F9",
  blue3: "#EBF2FE",
};

type Props = {
  color?: string;
  d: string;
  height?: string;
  rotation?: number;
  viewBox: string;
  width?: string;
};

const SvgImage: React.FC<Props> = ({
  color = "red",
  d = "",
  height = "300",
  rotation = 0,
  viewBox = "0 0 585 403",
  width = "300",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation})`}
    >
      <path d={d} fill={color} />
    </svg>
  );
};

export default SvgImage;
