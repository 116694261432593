import { useEffect, useState } from "react";
import { Box, Stack, ListItem, Theme } from "@mui/material";
import {
  iconSolidGlobe,
  iconExternalLink,
  iconSolidEnvelope,
  iconCommentExclamation,
  removeAccount,
  iconTrashcan,
} from "theme/icons";
import { desktopWidth640 } from "theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { FormattedMessage } from "react-intl";
import { User } from "types/user";
import { useFetch } from "hooks/useFetch";
import { API_URL_BO_ME, FEEDBACK_LINK, SUPPORT_LINK, SUPPORT_MAIL } from "utils/constants/index";

const illustration = process.env.PUBLIC_URL + "/img/illustrations/helpdesk-man.svg";
declare const window: any;

export const Support = () => {
  const intl = useAppIntl();
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);
  const { setSnackbarValues, snackbarMessage } = useSnackbarMessage();
  const { remove } = useFetch<User | Response>(API_URL_BO_ME);
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navItemWidth = isMediumOrSmallScreen ? "100%" : `calc(${desktopWidth640} / 2 - 8px)`;
  const navItemHeight = isMediumOrSmallScreen ? "auto" : "125px";
  openDeleteAccountDialog ? window.zE("webWidget", "hide") : window.zE("webWidget", "show");

  useEffect(() => {
    window.zE("webWidget", "show");
    return () => {
      window.zE("webWidget", "hide");
    };
  }, []);

  const deleteAccount = () => {
    remove(API_URL_BO_ME, null);
    setOpenDeleteAccountDialog(false);
    setSnackbarValues({
      message: intl.formatMessage({ id: "support.remove.account.snackmessage" }),
      type: "success",
      customSx: { zIndex: 10000000 },
    });
  };

  return (
    <Box maxWidth={desktopWidth640} ml={"auto"} mr={"auto"}>
      <Stack spacing={3}>
        <FormattedTypography variant="h1" id="more.title.support" />
        <FormattedTypography variant="body1" id="support.help.questions" />
        <FormattedTypography variant="body1" id="support.help.questions2" />
      </Stack>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2} marginBottom={2}>
        <img width={"auto"} height={"230px"} src={illustration} alt="Illustration" />
      </Box>
      <Stack spacing={3}>
        <FormattedTypography variant="h2" id="support.contact.us" />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumOrSmallScreen ? "column" : "row",
            flexFlow: isMediumOrSmallScreen ? "column wrap" : "row wrap",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "more.title.support" })}
            subText={intl.formatMessage({ id: "support.qa" })}
            subTextVariant="caption"
            icon={iconSolidGlobe}
            dataTestIdString={testId.listItem.theory.continue}
            navigationIcon={iconExternalLink}
            href={SUPPORT_LINK}
            hrefTarget="_blank"
            width={navItemWidth}
            height={navItemHeight}
          />
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "support.feedback" })}
            subText={intl.formatMessage({ id: "support.feedback.subtext" })}
            subTextVariant="caption"
            icon={iconCommentExclamation}
            dataTestIdString={testId.listItem.theory.continue}
            navigationIcon={iconExternalLink}
            href={FEEDBACK_LINK}
            hrefTarget="_blank"
            width={navItemWidth}
            height={navItemHeight}
          />
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "support.mail.us" })}
            icon={iconSolidEnvelope}
            dataTestIdString={testId.listItem.theory.continue}
            // TODO: email address should be in a config file
            onClick={() => (window.location.href = "mailto:support@korkort.nu")}
            navigationIcon={iconExternalLink}
            subText={SUPPORT_MAIL}
            subTextVariant="caption"
            width={navItemWidth}
            height={navItemHeight}
          />
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "support.remove.account.title" })}
            icon={removeAccount}
            dataTestIdString={testId.listItem.theory.continue}
            onClick={() => setOpenDeleteAccountDialog(true)}
            width={navItemWidth}
            height={navItemHeight}
          />
        </Box>
        <Box position={"absolute"} bottom="20px" display={"flex"} alignSelf={"center"}>
          <img src={process.env.PUBLIC_URL + "/img/logoSTR-text.png"} width={"127px"} height={"30px"} alt="STR logo" />
        </Box>
      </Stack>

      <DialogMessage
        title={"support.remove.account.dialog.title"}
        open={openDeleteAccountDialog}
        close={() => setOpenDeleteAccountDialog(false)}
        primaryButtonText={"support.remove.account.dialog.title"}
        primaryButtonColor="error"
        primaryButtonIcon={iconTrashcan}
        primaryButtonAction={() => deleteAccount()}
      >
        <FormattedTypography id="support.remove.account.dialog.text1" />
        <ListItem sx={{ display: "list-item", padding: "20px 16px" }}>
          <FormattedMessage id="support.remove.account.dialog.text2" />
        </ListItem>
        <ListItem sx={{ display: "list-item", padding: "8px 16px" }}>
          <FormattedMessage id="support.remove.account.dialog.text3" />
        </ListItem>
      </DialogMessage>
      {snackbarMessage}
    </Box>
  );
};
