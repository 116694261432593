import { ItemAssessment, LRSActionType, LRSProviderState, LRSRecord } from "@strmediaochitab/optima-component-library";
import { get, post, remove, trackException } from "./httpService";
import { API_URL_LRS_XAPI, API_URL_CD_RESPONSE_PATTERN } from "utils/constants";
import { copyObject } from "state/stateHelper";
import {
  createAndSaveXapiStatement,
  getProgressReport as getProgressReportXapi,
  getTestReport as getTestReportXapi,
} from "./xapiService";
import { ExtendedTreeNode, ReferenceKey } from "types/cds";
import { TestLabel } from "layout/test/test";
import { getResultOverview } from "./reportingService";
import { v4 as uuidv4 } from "uuid";
import { appConfig } from "appConfig";

type ResponsePattern = {
  responsePattern: string;
  contentId: string;
  versionId: string;
};

type CorrectResponseModel = {
  correctResponse: string;
  isCorrect: boolean;
  errors?: { error: string; title: string };
};

export type XapiKey = {
  userId: string;
  contentId: string;
  versionId: string;
  stateId?: string;
};

export interface ILrs {
  key: XapiKey;
  state: LRSProviderState;
  setState?: (state: LRSProviderState) => void;
  action?: LRSActionType;
  assessment?: ItemAssessment;
  assessmentLabel?: TestLabel;
  learningStructure?: ExtendedTreeNode[];
  questionState?: LRSRecord;
  assessmentState?: LRSProviderState;
  assessmentTypes?: {
    assessmentStudy?: ItemAssessment;
    assessmentFinal?: ItemAssessment;
    assessmentRoadsigns?: ItemAssessment;
  };
}

const getUrl = (xapiKey: XapiKey): string => {
  let path = "state/" + xapiKey.userId + "/" + xapiKey.contentId + "/" + xapiKey.versionId;
  if (xapiKey.stateId) path += "/" + xapiKey.stateId;
  return appConfig.lrsOldApiUrl + "/v1/xapi/" + path;
};

export const correctQuestion = async (state: LRSProviderState): Promise<void> => {
  let model: CorrectResponseModel;

  // If no result just return
  if (!state.result) return;

  // If no response, also just return
  let response = state.result.response;
  if (!response) return;

  //TODO: Denna konvertering bör hanteras på servern...
  let responseArray = JSON.parse(response);
  const numberArray = responseArray.map((value: string) => {
    if (Array.isArray(value)) return value;

    return Number(value);
  });
  response = JSON.stringify(numberArray);

  const pattern: ResponsePattern = {
    responsePattern: response,
    contentId: state.referenceKey.contentId,
    versionId: state.referenceKey.versionId,
  };

  const httpResponse = await post(API_URL_CD_RESPONSE_PATTERN + "evaluate/model", pattern);

  // Response could return a regular response object (for some questions (klick-i-bild neutral) which returns a 204 when no correctResponse exists)
  if (httpResponse.status && httpResponse.status === 204) {
    model = {
      correctResponse: "[]",
      isCorrect: true,
    };
  } else {
    model = httpResponse;
  }

  // TODO: Bestämma hantering av ev fel här
  if (model.errors) return console.error("Error when correcting question", model.errors.error);

  try {
    state.result.success = model.isCorrect;
    state.correctResponse = JSON.parse(model.correctResponse);
  } catch (error) {
    trackException(
      "JSONParseError",
      `JSONParseError: Error when parsing correct response: ${error}, correctResponse: ${model.correctResponse}`,
      "lrsService"
    );

    return console.error("Error when parsing correct response json", model.correctResponse);
  }
};

export const saveLrsState = async (key: XapiKey, state: LRSProviderState) => {
  const entry = { [state.referenceKey.versionId]: state };

  //console.log("SAVE LRS", entry.result.result?.completion);

  await post(getUrl(key), entry);
};

export const deleteLrsState = async (key: XapiKey) => {
  await remove(getUrl(key));
};

export const getLrsState = async (xapiKey: XapiKey): Promise<LRSRecord> => {
  const response = await get(getUrl(xapiKey))
    .then((data: any) => {
      if (data.status >= 400) {
        if (data.status !== 404) console.log("LRS state error", data.title, data.errors);

        return new Map<string, LRSProviderState>() as LRSRecord;
      }

      return new Map(Object.entries(data)) as LRSRecord;
    })
    .catch((error) => {
      console.log("LRS error", error);
      const empty = new Map<string, LRSProviderState>();
      return empty as LRSRecord;
    });

  return response;
};

export const handleLrsQuestion = async ({ key, state, setState, action, assessmentState }: ILrs) => {
  const newState = copyObject(state);

  console.log("handle lrs question state", newState);

  // TEMP: Skip set state if we are just checking if the state exists
  if (!action) {
    var lrsState = await getLrsState(key);

    if (!Array.from(lrsState.keys()).includes(newState.referenceKey.versionId)) {
      await saveLrsState(key, newState);
    }
  }

  if (action?.correct) {
    await correctQuestion(newState);

    if (newState.correctResponse && newState.correctResponse.length > 0) {
      if (setState) setState(newState);
      await saveLrsState(key, newState);
    }
  }

  if (action?.finalized) {
    if (newState.result) newState.result.completion = true;
    await createAndSaveXapiStatement({
      statementId: newState.id,
      statementType: "question",
      state: newState,
      assessmentState: assessmentState,
    });

    if (setState) setState(newState);
    await saveLrsState(key, newState);
  }
};

export const handleLrsAssessment = async ({
  key,
  state,
  setState,
  action,
  assessment,
  assessmentLabel,
  learningStructure,
  assessmentTypes,
  questionState,
}: ILrs) => {
  const newState = copyObject(state);

  console.log("handle lrs assessment state", newState);

  if (action?.initialized) {
    await createAndSaveXapiStatement({
      statementId: uuidv4(),
      statementType: "assessment2",
      state: newState,
      key,
      assessment,
      //assessmentLabel,
      //learningStructure,
      //assessmentTypes,
      //questionState,
    });
  }
  if (action?.finalized) {
    if (newState.result) newState.result.completion = true;
    const result = await createAndSaveXapiStatement({
      statementId: newState.id,
      statementType: "assessment",
      state: newState,
      key,
      assessment,
      assessmentLabel,
      learningStructure,
      assessmentTypes,
      questionState,
    });
    console.log("assessment finalized", result, newState);
  }
  if (setState) setState(newState);

  saveLrsState(key, newState);
};

export const getProgressReport = (master: ItemAssessment, userId: string, referenceKey: ReferenceKey) => {
  return getProgressReportXapi(master, userId, referenceKey);
};

export const getTestReport = async (userId: string, referenceKey: ReferenceKey) => {
  return await getTestReportXapi(userId, referenceKey);
};

export const getTestResultOverview = async (id: string) => {
  return await getResultOverview(id);
};
