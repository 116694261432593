import { useMemo } from "react";
import { useTheme, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Icon, iconAdd, iconCaretDown } from "theme/icons";
import { getEducationIcon } from "utils/helpers/GetEducationIcon";
import { useNavigate } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { CasingType, useAppIntl } from "services/useAppIntl";
import { EducationStateEnum, UserEducation } from "types/education";
import { IntlMessageKeys } from "services/useAppIntl";
import { DropdownMenu } from "utils/helpers/dropdown/DropdownMenu";
import { ButtonLabel, EducationMenuItems, IEducationMenuItem } from "utils/helpers/dropdown/MenuItemsEducation";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { testId } from "tests/testIdStrings";
import { useEducation } from "hooks/useEducation";

const ADD_NEW_EDUCATION_ID = "addNewEducation";

const HeaderEducationMenu: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useAppIntl();
  const routeConfig = useRouteConfiguration();
  const navigate = useNavigate();
  const { setSnackbarValues, snackbarMessage } = useSnackbarMessage();
  const { userEducations, education, setEducation } = useEducation();
  const currentEducationIdentifier = education?.identifier;

  const addNewEducationItem = {
    id: ADD_NEW_EDUCATION_ID,
    title: intl.formatMessage({ id: "more.title.education" }),
    icon: iconAdd,
    dataTestIdString: testId.common.dropDown.subscription.logout,
    customAction: () => navigateToSelectNewEducation(),
  };
  const navigateToSelectNewEducation = (id?: string) => {
    let path = routeConfig.education.path;
    if (id) path += `/${id}`;
    navigate(path);
  };

  const handleCallBack = (id?: string) => {
    if (ADD_NEW_EDUCATION_ID === id) {
      navigate(routeConfig.education.path);
    }

    const selectedEducation = userEducations?.find((x) => x.identifier === id);
    if (!selectedEducation) return;

    setEducation(selectedEducation);
    setSnackbarValues({
      message: intl.formatMessage(
        { id: "education.change.current.acknowledge.message" },
        { values: { name: selectedEducation.name } }
      ),
      type: "success",
      // Custom position of snackbar in start page
      customSx: isMobile
        ? { right: "16px", left: "16px", bottom: "95px" }
        : { top: "calc(100vh - 150px)", position: "relative" },
    });
  };

  const menuItems = useMemo(() => {
    const getTitle = (isActive: boolean, education: UserEducation): string => {
      const getTranslatedStatus = () => {
        const intlId = `education.${education.educationState.toLocaleLowerCase()}` as IntlMessageKeys;
        return intl.formatMessage({ id: intlId }, { casingType: CasingType.lowerCase });
      };

      return isActive ? education.name : `${education.name} (${getTranslatedStatus()})`;
    };

    // remove 'Completed'
    let educationCopy = userEducations?.filter((x) => x.educationState !== EducationStateEnum.Completed) ?? [];

    // put all 'Active' education first in the list
    educationCopy = [
      ...educationCopy
        .filter((x) => x.educationState === EducationStateEnum.Active)
        .sort((a, b) => (a.validUntil > b.validUntil ? 1 : -1)),
      ...educationCopy
        .filter((x) => x.educationState !== EducationStateEnum.Active)
        .sort((a, b) => (a.validUntil > b.validUntil ? 1 : -1)),
    ];

    const items =
      educationCopy
        ?.filter((x) => x.educationState !== EducationStateEnum.Completed)
        ?.map((education) => {
          const isActive = education.educationState === EducationStateEnum.Active;
          const item: IEducationMenuItem = {
            id: education.identifier,
            selected: education.identifier === currentEducationIdentifier,
            title: getTitle(isActive, education),
            icon: getEducationIcon(education.name),
            customAction: !isActive ? () => navigateToSelectNewEducation(education.identifier) : undefined,
            customStyle:
              education.educationState === EducationStateEnum.Paused
                ? { iconColor: theme.palette.grey[500] }
                : undefined,
          };
          return item;
        }) ?? [];

    items.push(addNewEducationItem);

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, currentEducationIdentifier]);

  const styleSelect = {
    color: "white",
    textTransform: "none",
    width: "220px",
    maxWidth: "220px",
  };

  let selectedEducationItem = menuItems.find((x) => x.id === currentEducationIdentifier);
  selectedEducationItem = selectedEducationItem ?? addNewEducationItem;

  return (
    <>
      <DropdownMenu
        id="ddEducationsHeader"
        label={<ButtonLabel item={selectedEducationItem} />}
        endIcon={<Icon sx={{ marginLeft: -2 }} name={iconCaretDown} />}
        sxButton={styleSelect}
        variant="text"
        onSetLabel={(x) => x}
        onClick={(item) => handleCallBack(item.id)}
        sx={{ width: "320px" }}
      >
        <EducationMenuItems items={menuItems} />
      </DropdownMenu>
      <Box
        sx={
          isMobile
            ? null
            : {
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }
        }
      >
        {snackbarMessage}
      </Box>
    </>
  );
};
export default HeaderEducationMenu;
