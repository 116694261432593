import React from "react";
import { List, ListItem, Link, Box, Stack } from "@mui/material";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";
import { useAppIntl } from "services/useAppIntl";

export const MyEducation: React.FC = (props) => {
  const intl = useAppIntl();
  const goToExternalPage = (url: string) => {
    window.open(url, "_blank");
  };

  const PngImg = ({ imgUrl }: { imgUrl: string }) => (
    <Box sx={{ display: "flex", alignSelf: "center", ">img": { width: "100%", height: "auto" } }}>
      <img src={process.env.PUBLIC_URL + imgUrl} alt="My education images" />
    </Box>
  );

  return (
    <>
      <BackgroundShape top={-210} left={-100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>
      <Stack spacing={4} style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <FormattedTypography
          id="more.title.myeducation"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <PngImg imgUrl="/img/myeducation/img1.png" />
        <FormattedTypography
          id="more.myeducator.text2"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text3" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img2.png" />
        <FormattedTypography id="more.myeducator.text4" variant="body1" color="primary.dark01" />

        <FormattedTypography
          id="more.myeducator.text5"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text6" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img3.png" />
        <FormattedTypography id="more.myeducator.text7" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img4.png" />
        <FormattedTypography id="more.myeducator.text8" variant="body1" color="primary.dark01" />
        <FormattedTypography id="more.myeducator.text9" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img5.png" />
        <FormattedTypography
          id="more.myeducator.text10"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text11" variant="body1" color="primary.dark01" />
        <FormattedTypography id="more.myeducator.text12" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img6.png" />
        <FormattedTypography
          id="more.myeducator.text13"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text14" variant="body1" color="primary.dark01" />
        <FormattedTypography id="more.myeducator.text15" variant="body1" color="primary.dark01" />
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            listStylePosition: "inside",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <FormattedTypography
              display={"inline"}
              id="more.myeducator.text16"
              variant="body2"
              color="primary.dark01"
              mb={1}
            />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <FormattedTypography
              display={"inline"}
              id="more.myeducator.text17"
              variant="body2"
              color="primary.dark01"
              mb={1}
            />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <FormattedTypography
              display={"inline"}
              id="more.myeducator.text18"
              variant="body2"
              color="primary.dark01"
              mb={1}
            />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <FormattedTypography
              display={"inline"}
              id="more.myeducator.text19"
              variant="body2"
              color="primary.dark01"
              mb={1}
            />
          </ListItem>
        </List>
        <PngImg imgUrl="/img/myeducation/img7.png" />
        <FormattedTypography
          id="more.myeducator.text20"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text21" variant="body1" color="primary.dark01" />
        <PngImg imgUrl="/img/myeducation/img8.png" />
        <FormattedTypography id="more.myeducator.text22" variant="body1" color="primary.dark01" />
        <FormattedTypography
          id="more.myeducator.text23"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text24" variant="body1" color="primary.dark01" />
        <FormattedTypography
          id="more.myeducator.text25"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <FormattedTypography id="more.myeducator.text26" variant="body1" color="primary.dark01" />
        <FormattedTypography
          id="more.myeducator.text27"
          variant="h2"
          fontSize={24}
          fontWeight={600}
          color="primary.dark01"
        />
        <PngImg imgUrl="/img/myeducation/img9.png" />
        <FormattedTypography id="more.myeducator.text28" variant="body1" color="primary.dark01" />
        <FormattedTypography
          id="more.myeducator.text29"
          variant="h2"
          fontSize={22}
          fontWeight={600}
          color="primary.dark01"
        />
        <Stack spacing={2} style={{ display: "flex", flexDirection: "column" }}>
          <Link mt={-1} onClick={() => goToExternalPage("https://www.korkort.nu")}>
            {intl.formatMessage({ id: "more.myeducation.link.str" })}
          </Link>
          <Link
            onClick={() =>
              goToExternalPage("https://www.trafikverket.se/korkort/ta-korkort/personbil-och-latt-lastbil")
            }
          >
            {intl.formatMessage({ id: "more.myeducation.link.trv" })}
          </Link>
          <Link onClick={() => goToExternalPage("https://www.transportstyrelsen.se/sv/vagtrafik/Korkort")}>
            {intl.formatMessage({ id: "more.myeducation.link.trs" })}
          </Link>
        </Stack>
      </Stack>
      <BackgroundShape stickToBottom bottom={-400}>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};
