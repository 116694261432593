import { atom, selector, selectorFamily } from "recoil";
import { get as httpGet, post as httpPost, trackException } from "services/httpService";
import { API_URL_CCS_COMPOSITE, API_URL_CCS_DATA } from "utils/constants";
import type {
  ReferenceKey,
  ExtendedTreeNode,
  DataResponse,
  ExtendedActivity,
  ExtendedMaterial,
  Activity,
  SubjectCategories,
  ApiParam,
  AssessmentStateParams,
  ActivityStateParams,
  LearningObject,
} from "types/cds";
import { localStorageEffect } from "./stateHelper";
import { ItemAssessment, ItemAssessmentGroup, ItemQuestion } from "@strmediaochitab/optima-component-library";
import { educationStateReferenceKey } from "./educationState";

// TEMP func
const filterActivity = (
  extendedActivity: ExtendedActivity,
  classificationId: string,
  practiceClassificationId: string
) => {
  const activityHasPracticeContent = extendedActivity.materials.some((material) =>
    material.classifications.ids.includes(practiceClassificationId)
  );

  const activity: Activity = {
    key: { contentId: extendedActivity.contentId, versionId: extendedActivity.versionId },
    title: extendedActivity.title,
    classificationIds: [],
    learningObjectiveIds: [],
    hasPracticeContent: activityHasPracticeContent,
    materialReferences: extendedActivity.materials?.filter((extendedMaterial: ExtendedMaterial) => {
      const classifications = extendedMaterial.classifications.ids;

      if (!classifications.length || !classifications.includes(classificationId)) return null;

      return extendedMaterial;
    }),
  };

  return activity;
};

export type EducationContentTypes = "read" | "practice" | "answer" | "roadsigns" | "study" | "final" | "extra";
export const educationContentTypeState = atom<Map<EducationContentTypes, string>>({
  key: "educationContentTypeState",
  default: new Map([
    // Theory
    ["read", "23cd7a93-91db-4c7c-b998-5bce3944e7e0"],
    ["practice", "fc046e1e-d82f-4090-b794-ab2b7e6e25a0"],
    ["answer", "cea26994-1692-43e7-a789-8d5ce05ee999"],
    ["extra", "7342f453-8283-4c40-af8f-638c7289c242"],
    // Test
    ["roadsigns", "5230d731-ea9f-45a5-a584-d6505a3081fb"],
    ["study", "946ebcb3-9608-4422-a203-475015697d55"],
    ["final", "b2d82dcb-eb9e-45ad-94c9-07c0819178a5"],
  ]),
});

// State set when an activity (chapter/section) is selected
export const selectedActivityKeyState = atom<ReferenceKey | undefined>({
  key: "selectedActivityKeyState",
  default: undefined,
  effects: [localStorageEffect("activity-key")],
});

// TEMP! För att hantera (rendera) ett kapitels aktiviteter
export const selectedActivitesKeyState = atom<any[]>({
  key: "selectedActivitiesKeyState",
  default: [],
  effects: [localStorageEffect("activities-key")],
});

const dataByContentAndVersionState = selectorFamily<any, ApiParam>({
  key: "dataByContentAndVersionState",
  get:
    ({ key }) =>
    async () => {
      const url = `${API_URL_CCS_DATA}${key.contentId}/${key.versionId}`;
      return await httpGet(url).then((data: any) => data);
    },
});

const dataMultipleByContentAndVersionState = selectorFamily<DataResponse[] | undefined, { keys: ReferenceKey[] }>({
  key: "dataMultipleByContentAndVersionState",
  get:
    ({ keys }) =>
    async () => {
      if (!keys) throw new Error("No keys for dataMultipleByContentAndVersionState");

      const url = `${API_URL_CCS_DATA}multiple`;
      return await httpPost(url, keys).then((data: any) => data);
    },
});

export const compositeByContentAndVersionState = selectorFamily<any, ApiParam>({
  key: "compositeByContentAndVersionState",
  get: (params) => async () => {
    const { key } = params;

    // TODO: OBS! Anropas nu med querystring param på versionId. Hur vill vi ha det egentligen?
    const url = `${API_URL_CCS_DATA}${key.contentId}?versionId=${key.versionId}`;

    return await httpGet(url).then((data: any) => data);
  },
});

const compositeMultipleByContentAndVersionState = selectorFamily<DataResponse[], { keys: ReferenceKey[] }>({
  key: "compositeMultipleByContentAndVersionState",
  get: (params) => async () => {
    const { keys } = params;
    const url = `${API_URL_CCS_COMPOSITE}multiple`;

    if (!keys) return console.log("No keys for compositeMultipleByContentAndVersionState");

    return await httpPost(url, keys).then((data: any) => data);
  },
});

const learningStructureStateByKey = selectorFamily<ExtendedTreeNode[], ApiParam>({
  key: "learningStructureStateByKey",
  get:
    (params) =>
    async ({ get }) => {
      const treeNodes: any[] = get(dataByContentAndVersionState(params));

      if (!treeNodes.length) return [];

      // Get the assessments (material keys) for this learningStructure
      let base = treeNodes.filter((node: any) => node.parentId === 0)[0];
      const apiParam = { ...params };
      apiParam.key = base.activity!;
      const assessments = get(dataByContentAndVersionState(apiParam));

      console.log("Education assessments", assessments, treeNodes);

      return treeNodes.map((x: ExtendedTreeNode) => {
        return {
          id: x.id,
          parentId: x.parentId,
          title: x.title,
          activity: x.activity,
          activityKey: {
            contentId: x.activity.contentId,
            versionId: x.activity.versionId,
          },
          materials: x.parentId === 0 ? assessments.materials : x.materials,
        };
      });
    },
});

// Gets the education tree structure
export const learningStructureState = selector<ExtendedTreeNode[] | undefined>({
  key: "learningStructureState",
  get: ({ get }) => {
    // Get the reference key from the current educationState
    const educationKey = get(educationStateReferenceKey);
    if (!educationKey) return undefined;

    return learningStructureStateByKey({ key: educationKey });
  },
});

// Gets the Activities for the current selected list of ReferenceKeys
export const activitiesStateSelector = selectorFamily<Activity[] | undefined, ActivityStateParams>({
  key: "activitiesStateSelector",
  get:
    ({ keys, classificationId }) =>
    async ({ get }) => {
      const activitiesData = get(dataMultipleByContentAndVersionState({ keys }));

      if (!activitiesData) return undefined;

      const activitiesExtended: ExtendedActivity[] = activitiesData.map(
        (a: { data: string; contentId: string; versionId: string }) => {
          const activity: ExtendedActivity = JSON.parse(a.data);
          activity.contentId = a.contentId;
          activity.versionId = a.versionId;
          return activity;
        }
      );

      // Filter activities based on classificationId (e.g. read/practice/answer)
      const practiceClassificationId = get(educationContentTypeState).get("practice");
      return activitiesExtended.map((activity) =>
        filterActivity(activity, classificationId, practiceClassificationId!)
      );
    },
});

export const assessmentStateSelector = selectorFamily<
  { subjectCategories: SubjectCategories[]; assessment: ItemAssessment } | undefined | null,
  AssessmentStateParams
>({
  key: "assessmentStateSelector",
  get:
    ({ key, educationContentType }) =>
    async ({ get }) => {
      let subjectCategories: SubjectCategories[];

      if (educationContentType !== "answer") {
        // Get test assessment by education node in learningStructure
        const structure = get(learningStructureState);
        if (!structure) return undefined;

        // Get the classificationId of the educationContentType
        const classificationId = get(educationContentTypeState).get(educationContentType as EducationContentTypes);
        if (!classificationId) return undefined;

        // Find material content key in the educationNode by classificationId
        const educationNode = structure.filter((node) => node.parentId === 0)[0];
        const assessmenReference = educationNode.materials.filter((material) => {
          const classifications = material.classifications.ids;

          if (!classifications.length || !classifications.includes(classificationId)) return null;

          return material.content;
        })[0];

        if (!assessmenReference) {
          console.error(
            "No assessment found for test type " + educationContentType + " with classificationId " + classificationId
          );
          return null;
        }

        key = assessmenReference.content;
      } else {
        // Get assessment data ref by chapter key
        const assessmentRef = get(dataByContentAndVersionState({ key: key }));

        // No assessment for this chapter
        if (!assessmentRef.materials.length) return null;

        key = assessmentRef.materials[0].content;
      }

      // Get assessment
      const assessmentData = get(compositeMultipleByContentAndVersionState({ keys: [key] }));
      if (!assessmentData) return undefined;

      try {
        // TODO: Fix type mapping...
        const assessmentDataItem = assessmentData[0] as any;

        const assessment = assessmentDataItem.items[0];
        const groups: ItemAssessmentGroup[] = assessment.items;

        subjectCategories = groups.map((group) => {
          return { id: group.id ? group.id : "no-id", title: group.name ? group.name : "no-name" };
        });

        return { subjectCategories, assessment };
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.log("Assessment error", error);
        trackException("AssessmentError", "Problem med att hämta assessment " + error, window.location.href);

        return undefined;
      }
    },
});

export const itemStateSelector = selectorFamily<
  LearningObject[] | undefined,
  { materialReferences: ExtendedMaterial[] }
>({
  key: "itemState",
  get:
    ({ materialReferences }) =>
    async ({ get }) => {
      if (!materialReferences) return undefined;

      const keys = materialReferences.map((m) => m.content);
      const learningObjects = (await get(
        compositeMultipleByContentAndVersionState({ keys })
      )) as unknown as LearningObject[];

      return learningObjects;
    },
});

export const questionStateSelector = selectorFamily<ItemQuestion | undefined, ApiParam>({
  key: "questionState",
  get:
    ({ key }) =>
    async () => {
      if (!key) return undefined;

      // TODO: OBS! Anropas nu med querystring param på versionId. Hur vill vi ha det egentligen?
      const url = `${API_URL_CCS_COMPOSITE}${key.contentId}?versionId=${key.versionId}`;

      const question: ItemQuestion = await httpGet(url).then((data: any) => data);

      return question;
    },
});
