import React, { useEffect } from "react";
import { Divider, ListItemButton, ListItemText, Typography } from "@mui/material";
import { TreeNode } from "types/cds";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  theoryStateChildrenByParentId,
  theorySectionState,
  theoryChapterState,
  theoryStateById,
  theorySectionScrollSpyState,
} from "state/theoryState";
import { useNavigate } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import useChapterActivities from "hooks/useChapterActivities";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";

interface TheorySectionsProps {
  chapterId: number;
  tabName?: TheoryTab;
  selectFirst?: boolean;
  menuClose?: () => void;
}

export const TheorySections: React.FC<TheorySectionsProps> = ({ chapterId, tabName, selectFirst, menuClose }) => {
  const navigate = useNavigate();
  const chapter = useRecoilValue(theoryStateById(chapterId));
  const setChapterState = useSetRecoilState(theoryChapterState);
  const sections = useRecoilValue(theoryStateChildrenByParentId(chapterId));
  const [sectionState, setSectionState] = useRecoilState(theorySectionState);
  const { setChapterActivities } = useChapterActivities();
  const routes = useRouteConfiguration();
  const activeScrollSection = useRecoilValue(theorySectionScrollSpyState); // useActiveSectionScrollSpy(chapterId);

  useEffect(() => {
    if (!sections.length) return;
    if (selectFirst) setSectionState(sections[0]);
  }, [selectFirst, sections, setSectionState]);

  const handleSelectedItem = (event: any, section: TreeNode) => {
    event.stopPropagation();

    if (!chapter) return;

    setChapterState(chapter);
    setSectionState(section);
    // För learning state
    setChapterActivities(section, true);

    const tab = tabName ? tabName : TheoryTab.read;
    navigate(`${routes.theory.path}/${chapter.parentId}/${chapter.id}/${tab}#${section.activityKey?.contentId}`);

    if (menuClose) menuClose();
  };

  return (
    <>
      {sections.map((section: TreeNode, index: number) => (
        <React.Fragment key={section.id}>
          <Divider sx={{ margin: 0 }} />
          <ListItemButton
            sx={{ height: "4.75rem" }}
            onClick={(e) => handleSelectedItem(e, section)}
            data-testid={`${testId.listItem.theory.sections}-${index + 1}`}
            selected={
              activeScrollSection
                ? activeScrollSection === section.activityKey!.contentId
                : sectionState?.activityKey?.contentId === section.activityKey!.contentId
            }
          >
            <ListItemText primary={<Typography variant="subtitle1">{section.title}</Typography>} />
          </ListItemButton>
        </React.Fragment>
      ))}
    </>
  );
};
