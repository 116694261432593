import { useEffect, useState } from "react";
import { useProgress } from "./useProgress";
import { useEducation } from "./useEducation";

/**
 * Hook for getting final test status, e.g. if the user is allowed to take final test
 */
export const useFinalTestStatus = () => {
  const finalTestThreshold = 75;
  const { knowledgeLevelUser } = useProgress();
  const { education } = useEducation();
  const [finalTestUnlocked, setFinalTestUnlocked] = useState<boolean | undefined>();
  const [finalTestEnabled, setFinalTestEnabled] = useState<boolean | undefined>();

  useEffect(() => {
    if (!education || knowledgeLevelUser === undefined) return;

    const finalTestUnlocked = education.finalTestIsUnlocked;
    const finalTestEnabled = finalTestUnlocked
      ? finalTestUnlocked
      : knowledgeLevelUser
      ? knowledgeLevelUser >= finalTestThreshold
      : false;

    setFinalTestUnlocked(education.finalTestIsUnlocked ? education.finalTestIsUnlocked : false);
    setFinalTestEnabled(finalTestEnabled);
  }, [education, knowledgeLevelUser]);

  return { finalTestEnabled, finalTestUnlocked };
};
