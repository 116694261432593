import { useEffect, useState } from "react";
import { userStateBasicInfo } from "state/userState";
import { useRecoilValue } from "recoil";
import { instanceOfRequestError } from "state/stateHelper";

interface IValidateBasicInfoState {
  basicInfoIsValidated: boolean;
  basicInfoHasErrors: boolean;
}

const initState: IValidateBasicInfoState = {
  basicInfoIsValidated: false,
  basicInfoHasErrors: false,
};

/**
 * Validate if UserBasicInfo exist and is valid
 * @returns
 *  basicInfoIsValidated: 'false' if validation is in progress, 'true' when finished
 *
 *  basicInfoHasErrors: 'true' if basicInfo is missing or note valid, in other cases 'false'
 */
export const useValidateBasicInfo = (): IValidateBasicInfoState => {
  const [state, setState] = useState<IValidateBasicInfoState>(initState);
  const basicInfo = useRecoilValue(userStateBasicInfo);

  useEffect(() => {
    if (basicInfo) {
      if (instanceOfRequestError(basicInfo) || !basicInfo.validation.isValid) {
        setState({ basicInfoIsValidated: true, basicInfoHasErrors: true });
      } else {
        setState({ basicInfoIsValidated: true, basicInfoHasErrors: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  return { ...state };
};
