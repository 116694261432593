/**
 * Retrieves the key from a Map based on a given value.
 * @template T - The type of the keys in the Map.
 * @param {Map<T, string>} map - The Map to search in.
 * @param {string | undefined} mapValue - The value to search for.
 * @returns {T | undefined} - The key associated with the given value, or undefined if not found.
 */
export function getMapKey<T>(map: Map<T, string>, mapValue: string | undefined) {
  for (const [key, value] of map.entries()) {
    if (value === mapValue) return key;
  }
  return undefined;
}
