import { useCallback, useState } from "react";

/**
 * Custom toggle function. Basic use is to toggle a value. If you want to set a specific value, you can specify a value to the toggle function
 * @param initialState set the default boolean value
 * @returns an array [state, toggle]
 */
const useToggle = (initialState: boolean) => {
  const [state, setState] = useState(initialState);

  // Define and memoize toggler function in case we pass down the component,
  const toggle = useCallback(
    (value?: boolean) => setState((state: boolean) => (value !== undefined ? value : !state)),
    []
  );

  return [state, toggle] as const;
};

export default useToggle;
