import { PublicClientApplication } from "@azure/msal-browser";
import React, { PropsWithChildren } from "react";
import { MsalProvider } from "@azure/msal-react";
import { LoginHandler } from "./LoginHandler";
import { msalConfig } from "authConfig";

export const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);

export const AppMsalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <LoginHandler instance={msalInstance}>{children}</LoginHandler>
    </MsalProvider>
  );
};
