import React, { useEffect } from "react";
import { AppBar, BottomNavigation, BottomNavigationAction, Theme } from "@mui/material";
import {
  Icon,
  iconTheory,
  iconTheoryActive,
  iconHome,
  iconHomeActive,
  iconTest,
  iconTestActive,
  iconMoreCircle,
  iconMoreActive,
  iconProgress,
  iconProgressActive,
} from "theme/icons";
import { Link, useLocation } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import useMediaQuery from "@mui/material/useMediaQuery";

const footerPadding = "16px";
const styleFooter = {
  top: "auto",
  bottom: footerPadding,
  left: footerPadding,
  right: footerPadding,
  width: "auto",
  overflow: "hidden",
  borderRadius: "30px",
};

const paths = {
  home: "/",
  theory: "/theory",
  test: "/test",
  progress: "/progress",
  more: "/more",
};

export const Footer = () => {
  const location = useLocation();
  const [value, setValue] = React.useState("");
  const intl = useAppIntl();
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return isMediumOrSmallScreen ? (
    <AppBar position="fixed" elevation={3} color="transparent" sx={styleFooter}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label={intl.formatMessage({ id: "common.title.home" })}
          icon={<Icon name={value === paths.home ? iconHomeActive : iconHome} />}
          component={Link}
          to={paths.home}
          value={paths.home}
          data-testid={testId.menu.bottomNavigation.home}
        />
        <BottomNavigationAction
          label={intl.formatMessage({ id: "common.title.theory" })}
          icon={<Icon name={value === paths.theory ? iconTheoryActive : iconTheory} />}
          component={Link}
          to={paths.theory}
          value={paths.theory}
          data-testid={testId.menu.bottomNavigation.theory}
        />
        <BottomNavigationAction
          label={intl.formatMessage({ id: "common.title.test" })}
          icon={<Icon name={value === paths.test ? iconTestActive : iconTest} />}
          component={Link}
          to={paths.test}
          value={paths.test}
          data-testid={testId.menu.bottomNavigation.test}
        />
        <BottomNavigationAction
          label={intl.formatMessage({ id: "common.title.progress" })}
          icon={<Icon name={value === paths.progress ? iconProgressActive : iconProgress} />}
          component={Link}
          to={paths.progress}
          value={paths.progress}
          data-testid={testId.menu.bottomNavigation.progress}
        />
        <BottomNavigationAction
          label={intl.formatMessage({ id: "common.title.more" })}
          icon={<Icon name={value === paths.more ? iconMoreActive : iconMoreCircle} />}
          component={Link}
          to={paths.more}
          value={paths.more}
          data-testid={testId.menu.bottomNavigation.more}
        />
      </BottomNavigation>
    </AppBar>
  ) : null;
};
