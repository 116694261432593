import { Box, Button, Stack, Typography, Link } from "@mui/material";
import { Component, ErrorInfo, ReactNode } from "react";
import { SUPPORT_LINK } from "utils/constants";
import ReactGA from "react-ga4";
import { FormattedTypography } from "./FormattedTypography";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      ...this.state,
      errorMessage: "Uncaught error: " + error + ", se konsolloggen för mer information.",
    });
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      ReactGA.event({
        category: "error",
        action: "uncaughtError",
        label: this.state.errorMessage,
      });

      return <ErrorPage error={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export const AppErrorPage = ({ error }: { error?: string }) => {
  return (
    <Box
      display={"flex"}
      height={"100vh"}
      justifyContent={"center"}
      alignContent={"center"}
      flexDirection={"column"}
      flexWrap={"wrap"}
      textAlign={"center"}
      px={2}
    >
      <Stack spacing={2}>
        <FormattedTypography id="common.page.error.title" variant="h3" />
        <FormattedTypography
          id="common.page.error.text"
          variant="body1"
          values={{
            br: <br />,
            link: (
              <Link href={SUPPORT_LINK} target="_blank">
                support.korkort.nu
              </Link>
            ),
          }}
        />
      </Stack>
      <Button
        sx={{ marginTop: 2, marginBottom: 2 }}
        variant="contained"
        LinkComponent={Link}
        // eslint-disable-next-line no-script-url
        href="javascript: window.location = '/'"
      >
        <FormattedTypography id="common.page.error.button" />
      </Button>
      <Typography sx={{ lineBreak: "normal", paddingRight: 1, paddingLeft: 1 }} variant="body1">
        {error}
      </Typography>
    </Box>
  );
};

// This cant be dependant on intl since it is used in the ErrorBoundary before the intl provider is loaded
export const ErrorPage = ({ error }: { error?: string }) => {
  return (
    <Box
      display={"flex"}
      height={"100vh"}
      justifyContent={"center"}
      alignContent={"center"}
      flexDirection={"column"}
      flexWrap={"wrap"}
      textAlign={"center"}
      px={2}
    >
      <Stack spacing={2}>
        <Typography variant="h3">FÖRLÅT</Typography>
        <Typography variant="body1">
          Ett fel har tyvärr inträffat. <br />
          Det har anmälts och vi jobbar på att lösa det så fort vi kan. <br />
          <br />
          För mer information gå till
          <br />
          <Link href={SUPPORT_LINK} target="_blank">
            support.korkort.nu
          </Link>
        </Typography>
      </Stack>
      <Button
        sx={{ marginTop: 2, marginBottom: 2 }}
        variant="contained"
        LinkComponent={Link}
        // eslint-disable-next-line no-script-url
        href="javascript: window.location = '/'"
      >
        <Typography>Börja om</Typography>
      </Button>
      <Typography sx={{ lineBreak: "normal", paddingRight: 1, paddingLeft: 1 }} variant="body1">
        {error}
      </Typography>
    </Box>
  );
};
