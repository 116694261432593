import { ReactNode, useEffect } from "react";
import useCountdown from "hooks/useCountdown";
import { Box, LinearProgress, Typography } from "@mui/material";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentEnd } from "theme/styles";
import { FormattedTypography } from "./FormattedTypography";

interface ICountdown {
  minutes: number;
  onFinish?: () => void;
}
/**
 * Countdown with progress indicator
 * @param length in minutes
 */
const Countdown = ({ minutes, onFinish }: ICountdown) => {
  const seconds = minutes * 60;
  const [min, sec, progress] = useCountdown(seconds);

  useEffect(() => {
    if (progress > 0) return;
    if (onFinish) onFinish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return <CountdownProgress minutes={min} seconds={sec} progress={progress} />;
};

interface ICountdownProgress {
  minutes: number;
  seconds: number;
  progress: number;
}
const CountdownProgress = ({ minutes, seconds, progress }: ICountdownProgress) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" color="info" value={progress} />
      </Box>
      <Box sx={[styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentEnd, { lineHeight: 1 }]}>
        <Time value={minutes.toString()} isDanger={false}>
          <Typography variant="breadcrumbs">:</Typography>
        </Time>
        <Time
          value={seconds < 10 ? seconds.toString().padStart(2, "0") : seconds.toString()}
          isDanger={seconds <= 20 ? true : false}
        >
          <FormattedTypography id="test.countdown.time-left" variant="breadcrumbs" ml={0.5} />
        </Time>
      </Box>
    </>
  );
};

interface ITime {
  value: string;
  isDanger: boolean;
  children: ReactNode;
}
const Time = ({ value, children, isDanger }: ITime) => {
  return (
    <Box className={isDanger ? "countdown danger" : "countdown"}>
      <Typography variant="breadcrumbs">{value}</Typography>
      {children}
    </Box>
  );
};

export default Countdown;

export const getMinutes = (minutes: number) => {
  return new Date().getTime() + minutes * 60 * 1000;
};
