import React, { useEffect, useState } from "react";
import { Box, IconButton, Theme, useTheme } from "@mui/material";
import { optimaColors } from "theme/optima";
import Typography from "@mui/material/Typography";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import useMediaQuery from "@mui/material/useMediaQuery";
import { midGrey, styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { Icon, iconCircleInfoSolid } from "theme/icons";
import { useProgress } from "hooks/useProgress";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";

const grey = "#D9D9D9";
const styleContainer = {
  display: "flex",
  zIndex: 0,
  position: "relative",
  flexDirection: "row",
  width: "100%",
  my: "0.5rem",
};

const styleColumn = {
  display: "flex",
  width: "50%",
  flexDirection: "column",
  marginRight: "3px",
};

const styleSeparator = {
  display: "flex",
  position: "absolute",
  height: "100%",
};

const topicStyle = (isSmallDevice: boolean) => {
  return {
    fontSize: isSmallDevice ? "11px" : "11.5px",
    lineHeight: "13px",
    marginBottom: "20px",
    textAlign: "right",
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };
};

const rightSideStyle = (value: number, theme: Theme) => {
  return {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: "13px",
    lineHeight: "13px",
    zIndex: 2,
    backgroundColor: value >= 75 ? optimaColors.success : optimaColors.successLight,
    marginBottom: "20px",
    transition: theme.transitions.create("width", { delay: 500, duration: 500 }),
  };
};

const typographyStyle = (value: number) => {
  return {
    display: "flex",
    fontSize: "10px",
    lineHeight: "13px",
    color: value >= 75 ? "white" : midGrey,
    position: "absolute",
    right: value > 8 ? 0 : "unset",
    left: value <= 8 ? "2px" : "unset",
  };
};

export const ProgressPerTopic = () => {
  const theme = useTheme();
  const { knowledgeLevels } = useProgress();
  const isSmallDevice = useMediaQuery("(max-width:376px)");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    if (!knowledgeLevels) return;

    setTimeout(() => {
      setAnimate(true);
    }, 250);
  }, [knowledgeLevels]);

  if (!knowledgeLevels) return null;

  return (
    <>
      <BackgroundShape top={-210} left={-100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.blue} />
      </BackgroundShape>
      <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]} mb={5} mt={-1}>
        <FormattedTypography variant="h1" id="progress.knowledgelevel.per.topic.title" />
        <IconButton onClick={() => setOpenDialog(true)}>
          <Icon name={iconCircleInfoSolid} htmlColor={theme.palette.primary.dark06} />
        </IconButton>
      </Box>
      <FormattedTypography mb={3} variant="body1" id="progress.knowledgelevel.per.topic.body" />

      <ProgressHeaderFooter />
      <Box sx={styleContainer}>
        <Box sx={styleColumn}>
          {knowledgeLevels.map(({ title }) => (
            <Typography sx={topicStyle(isSmallDevice)}>{title}</Typography>
          ))}
        </Box>
        <Box sx={styleColumn} borderLeft={`1px solid ${grey}`} overflow="hidden">
          <Box sx={styleSeparator} ml={"34.3%"} borderRight={`1px solid ${theme.palette.success.main}`} />
          <Box sx={styleSeparator} ml={"50%"} borderRight={`1px solid ${grey}`} alignSelf={"flex-end"} />
          {knowledgeLevels.map(({ value }) => (
            <Box sx={rightSideStyle(parseInt(value), theme)} style={{ width: animate ? value + "%" : 0 }}>
              <Typography sx={typographyStyle(parseInt(value))}>{value}%</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <ProgressHeaderFooter />

      <DialogMessage
        title={"progress.knowledgelevel"}
        open={openDialog}
        close={() => setOpenDialog(false)}
        primaryButtonText={"common.close"}
        noCancelButton
      >
        <>
          <FormattedTypography variant="body1" id="progress.dialog.knowledgelevel.text" />
          <br />
          <FormattedTypography variant="body1" id="progress.dialog.knowledgelevel.text2" />
        </>
      </DialogMessage>
    </>
  );
};

const ProgressHeaderFooter = () => {
  return (
    <Box display={"flex"} flexDirection={"row"} marginLeft={"50%"} width={"50%"}>
      <Typography variant="caption" color={midGrey} textAlign={"center"}>
        0%
      </Typography>
      <Typography variant="caption" color={midGrey} position={"absolute"} ml={"30%"}>
        75%
      </Typography>
      <Typography variant="caption" color={midGrey} ml={"auto"}>
        100%
      </Typography>
    </Box>
  );
};
