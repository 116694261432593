import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { RecoilRoot } from "recoil";
import { DebugObserver } from "state/recoilDebug";
import { AppMsalProvider } from "utils/helpers/msal/AppMsalProvider";
import { ErrorPage } from "utils/helpers/ErrorBoundary";
import { appConfig } from "appConfig";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "services/microsoftApplicationInsightsService";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        {appConfig.debug_recoil === "true" && <DebugObserver />}
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
            <Suspense>
              <AppMsalProvider>
                <App />
              </AppMsalProvider>
            </Suspense>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
