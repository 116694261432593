import { Box } from "@mui/material";
import {
  styleFlex,
  styleFlexJustifyContentCenter,
  styleFlexAlignItemsCenter,
  styleFlexJustifyContentEvenly,
} from "theme/styles";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
const imgBg = process.env.PUBLIC_URL + "/img/illustrations/crossing-bg.svg";
const imgPerson = process.env.PUBLIC_URL + "/img/illustrations/person-phone-blueshirt.svg";
const imgStripes = process.env.PUBLIC_URL + "/img/illustrations/crossing-stripes.svg";

export const InfoBoxNoEducation = () => {
  return (
    <Box textAlign="center" mt={22} mb={16}>
      <Box sx={[styleFlex, styleFlexJustifyContentCenter, { position: "relative" }]} mb={4}>
        <img src={imgBg} alt="Illustration background" />
        <Box sx={{ position: "absolute", top: "-1rem", zIndex: 0 }}>
          <Box sx={[styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentEvenly]} pl="3">
            <img src={imgStripes} alt="Illustration crossing stripes" />
          </Box>
        </Box>
        <Box sx={{ position: "absolute", top: "-3.5rem", zIndex: 5 }}>
          <img src={imgPerson} alt="Illustration person" />
        </Box>
      </Box>
      <FormattedTypography variant="body2" id="education.general.no-active" sx={{ position: "relative", zIndex: 10 }} />
    </Box>
  );
};
