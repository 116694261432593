import { Button } from "@mui/material";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { useActivateEducation } from "hooks/useActivateEducation";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import useToggle from "hooks/useToggle";
import { ValueCodeDialog } from "layout/more/education/ValueCodeDialog";
import { FormattedMessage } from "react-intl";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { Icon, iconMoneyCheckPen } from "theme/icons";

export const ValueCode = () => {
  const intl = useAppIntl();
  const [openValueCodeDrawer, setOpenValueCodeDrawer] = useToggle(false);
  const { snackbarMessage, setSnackbarValues } = useSnackbarMessage();
  const { handleActivateEducation, done } = useActivateEducation();
  const useValueCode = useFeatureFlag("Voucher");

  const handleActivate = async () => {
    setSnackbarValues({
      message: intl.formatMessage({ id: "education.value-code.activate.info" }),
      type: "info",
    });
    await handleActivateEducation();
  };

  if (!useValueCode) return null;

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpenValueCodeDrawer(true)}
        data-testid={`${testId.button.education}-valuecode`}
        fullWidth
        startIcon={<Icon name={iconMoneyCheckPen} />}
      >
        <FormattedMessage id="education.value-code" />
      </Button>

      {openValueCodeDrawer && (
        <ValueCodeDialog isOpen={true} close={() => setOpenValueCodeDrawer(false)} onActivated={handleActivate} />
      )}

      {!done && snackbarMessage}
    </>
  );
};
