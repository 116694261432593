import { FormGroup, FormControlLabel, Switch, Divider, Button } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useAppIntl } from "services/useAppIntl";
import { styleFlexJustifyContentEnd } from "theme/styles";
import FormattedMessage from "../FormattedMessage";
import { FormattedTypography } from "../FormattedTypography";
import { IMenuItem } from "./MenuItems";
import { MenuItemsChips } from "./MenuItemsChips";

export interface IChipList {
  items: IMenuItem[];
  selectMultiple?: boolean;
  children?: ReactNode;
  onClick?: (item: IMenuItem | null) => void;
  onDelete?: (item: IMenuItem) => void;
  onSetLabel?: (label: string) => void;
  onSelectAll?: (items: IMenuItem[]) => void;
  onMenuClose?: () => void;
}

export default function DropDownChipItems({
  items,
  selectMultiple,
  children,
  onClick,
  onSetLabel,
  onDelete,
  onSelectAll,
  onMenuClose,
}: IChipList) {
  const [selected, setSelected] = useState<Map<string, IMenuItem>>(new Map());
  const [allSelected, setAllSelected] = useState(true);
  const intl = useAppIntl();

  // Set on init
  useEffect(() => {
    if (!items.length) return;

    toggleSelectAll(allSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (!items.length) return;

    setAllSelected(selected.size === items.length);
  }, [selected, items]);

  const handleClick = (chip: IMenuItem) => {
    if (!chip.id) return;
    let newState = new Map(selected);
    let label = chip.title;

    if (selectMultiple) {
      const exists = selected.get(chip.id);
      if (exists) newState.delete(chip.id);
      else newState.set(chip.id, { ...chip, selected: true });

      label = getLabel(newState);
    } else {
      newState = new Map().set(chip.id, chip);
    }

    setSelected(newState);

    if (onSetLabel) onSetLabel(label);
    if (onClick) onClick(chip);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleSelectAll(event.target.checked);
  };

  const toggleSelectAll = (selectAll: boolean) => {
    let selected = new Map<string, IMenuItem>();
    if (selectAll) items.forEach((item) => selected.set(item.id!, item));

    setSelected(selected);

    if (onSetLabel) onSetLabel(getLabel(selected));
    if (onSelectAll) onSelectAll(Array.from(selected.values()));
  };

  const getLabel = (selected: Map<string, IMenuItem>) => {
    switch (true) {
      case selected.size === 0:
        return intl.formatMessage({ id: "common.dropdown.select" });

      case selected.size === 1:
        return Array.from(selected.values())[0].title;

      case selected.size === items.length:
        return intl.formatMessage({ id: "common.dropdown.all-selected" });

      default:
        return intl.formatMessage({ id: "common.dropdown.x-selected" }, { values: { value: selected.size } });
    }
  };

  console.log("all", allSelected);

  return (
    <>
      {children}
      <MenuItemsChips items={items} selectedItems={selected} onClick={handleClick} onDelete={onDelete} />
      {selectMultiple && (
        <>
          <Divider sx={{ my: 0 }} />
          <FormGroup row sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <FormControlLabel
              control={<Switch checked={allSelected} onChange={handleSelectAll} />}
              label={<FormattedTypography id="test.create.custom.label.select-all" variant="captionImage" />}
              labelPlacement="start"
              sx={[styleFlexJustifyContentEnd, { py: 1 }]}
            />
            <Button variant="contained" sx={{ marginRight: "1rem", px: 5, height: "2.5rem" }} onClick={onMenuClose}>
              <FormattedMessage id="common.dropdown.select" />
            </Button>
          </FormGroup>
        </>
      )}
    </>
  );
}
