import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputEmailProps {
  control: Control<any, any> | undefined;
}
export const FormInputEmail = ({ control }: IFormInputEmailProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      disabled
      name="email"
      type="email"
      label={intl.formatMessage({ id: "profile.label.email" })}
      dataTestIdString={`${testId.input.profile}-email`}
      control={control}
    />
  );
};
