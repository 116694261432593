import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { useAppIntl } from "services/useAppIntl";
import { styleTabContent, testTypeName } from "./allTestResults";

type Props = {
  testResults: ItemTestResultItem[];
};

export const StudyTest: React.FC<Props> = ({ testResults }) => {
  const intl = useAppIntl();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  return (
    <>
      {testResults
        .map((row, i) => {
          const { duration, finished, max, title, value } = row;
          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });

          return (
            <Box key={i} sx={styleTabContent}>
              <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                <Typography width={65}>
                  {value}/{max}
                </Typography>
                <Typography variant="caption" display={"flex"} textAlign={"left"} pr={2}>
                  {testTypeName(title)}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"} textAlign={"right"}>
                <Typography variant="body2">{testDuration}</Typography>
                <Typography variant="caption">{getDateWithTime(finished)}</Typography>
              </Box>
            </Box>
          );
        })
        .reverse()}
    </>
  );
};
