import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputPhoneNumberProps {
  control: Control<any, any> | undefined;
}
export const FormInputPhoneNumber = ({ control }: IFormInputPhoneNumberProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name="phoneNumber"
      label={intl.formatMessage({ id: "profile.label.phone" })}
      helperText={intl.formatMessage({ id: "profile.label.phone.helper-text" })}
      dataTestIdString={`${testId.input.profile}-phone`}
      control={control}
    />
  );
};
