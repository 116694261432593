import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type Props = {};

export const Unauthorized: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{ marginLeft: "auto", marginRight: "auto" }}
      display="flex"
      flexWrap="wrap"
      alignContent="center"
      justifyContent="center"
      width="500px"
    >
      <FormattedTypography variant="h3" id="common.page.unauthorized" />
      <Outlet />
    </Box>
  );
};
