import { Box } from "@mui/material";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
const imgPerson = process.env.PUBLIC_URL + "/img/illustrations/person-roller.svg";

export const NoTestPage = () => (
  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" marginTop={6}>
    <img src={imgPerson} height={"280px"} alt="Person roller" />
    <FormattedTypography variant="body2" textAlign={"center"} marginTop={2} id="progress.notest.line1" />
    <FormattedTypography variant="body2" textAlign={"center"} marginTop={1} width="240px" id="progress.notest.line2" />
  </Box>
);
