import { PropsWithChildren } from "react";
import { AppBar, Avatar, Box, Divider, Toolbar, Theme, useTheme } from "@mui/material";
import { ButtonLink } from "utils/helpers/ButtonLink";
import { styleRoundedBottom } from "theme/styles";
import { testId } from "tests/testIdStrings";
import { useRecoilState, useRecoilValue } from "recoil";
import { userStateAvatar } from "state/userState";
import { InfoMessage } from "utils/helpers/InfoMessage";
import HeaderEducationMenu from "utils/helpers/dropdown/HeaderEducationMenu";
import { ShowConsentWarningIcon } from "./Components/ShowConsentWarningIcon";
import { iconEducator, Icon, iconUserSolid } from "theme/icons";
import { connectedEducatorsSelector } from "state/educationState";
import useMediaQuery from "@mui/material/useMediaQuery";
import useLocalStorage from "hooks/useLocalStorage";
import { HeaderDesktopMenu } from "./headerDesktopMenu";
import { desktopWidth420 } from "theme/theme";
import { useFetchFeatureFlag } from "@strmediaochitab/optima-react-components";
import { maintenanceState } from "state/recoilAtoms";

const triangleCssStyle = {
  width: 0,
  height: 0,
  borderLeft: "16px solid transparent",
  borderRight: "16px solid transparent",
};

const innerTriangleCssStyle = {
  position: "relative",
  bottom: "-1px",
  left: "-17px",
  width: 0,
  height: 0,
  borderLeft: "17px solid transparent",
  borderRight: "17px solid transparent",
};

export const Header = () => {
  const theme = useTheme();
  const [showLsConsentWarning, setShowLsConsentWarning] = useLocalStorage("showConsentWarning");
  const [showLsRegisterEducatorWarning, setShowLsRegisterEducatorWarning] =
    useLocalStorage("showRegisterEducatorWarning");
  const [showLsRegisterEducatorWarningCounter, setShowLsRegisterEducatorWarningCounter] = useLocalStorage(
    "showRegisterEducatorWarningCounter"
  );
  const userAvatar = useRecoilValue(userStateAvatar);
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const connectedEducators = useRecoilValue(connectedEducatorsSelector);

  const hasConnectedEducators = connectedEducators && connectedEducators.length > 0;
  const hasConsented = hasConnectedEducators ? connectedEducators[0].consented : false;
  const showConsentWarningHeader = hasConnectedEducators && !hasConsented;
  const showConsentWarning = hasConnectedEducators && !hasConsented && showLsConsentWarning;
  const showRegisterEducator =
    showLsRegisterEducatorWarning && showLsRegisterEducatorWarningCounter <= 5 && !hasConnectedEducators;

  const [showMaintenanceMessage, setShowMaintenanceMessage] = useRecoilState(maintenanceState);
  const maintenanceMessage = useFetchFeatureFlag("MaintenanceMessage");

  const closeRegisterEducatorWarning = () => {
    setShowLsRegisterEducatorWarning(false);
    const counter = Number(showLsRegisterEducatorWarningCounter) ? Number(showLsRegisterEducatorWarningCounter) + 1 : 1;
    setShowLsRegisterEducatorWarningCounter(counter);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={styleRoundedBottom}>
      <Toolbar>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <HeaderEducationMenu />
          {!isMediumOrSmallScreen && <HeaderDesktopMenu />}
          <Box display="flex" justifyContent="flex-end" width="220px" alignItems="center" columnGap={1.5}>
            {showConsentWarningHeader && (
              <>
                <ButtonLink to={"/more/educator"} dataTestIdString={testId.common.text.educator}>
                  <Icon name={iconEducator} htmlColor="white" />
                  {!hasConsented && <ShowConsentWarningIcon />}
                </ButtonLink>
                {showConsentWarning && (
                  <Box
                    sx={[
                      triangleCssStyle,
                      {
                        borderBottom: `16px solid ${theme.palette.warning.main}`,
                        position: "fixed",
                        top: "60px",
                        right: isMobile ? "65px" : "81px",
                        zIndex: 1000000,
                      },
                    ]}
                  >
                    <Box sx={[innerTriangleCssStyle, { borderBottom: "17px solid #FFF7E4" }]} />
                  </Box>
                )}
              </>
            )}
            {!hasConnectedEducators && (
              <>
                <ButtonLink to={"/more/educator/find"} dataTestIdString={testId.common.text.educator}>
                  <Icon name={iconEducator} htmlColor="white" />
                  <ShowConsentWarningIcon color={"#476EB8"} />
                </ButtonLink>
                {showRegisterEducator && (
                  <Box
                    sx={[
                      triangleCssStyle,
                      {
                        borderBottom: "17px solid #476EB8",
                        position: "fixed",
                        top: "60px",
                        right: isMobile ? "65px" : "81px",
                        zIndex: 1000000,
                      },
                    ]}
                  >
                    <Box sx={[innerTriangleCssStyle, { borderBottom: "16px solid #476EB8" }]} />
                  </Box>
                )}
              </>
            )}
            <ButtonLink to={"/more/profile"} dataTestIdString={testId.common.text.profile}>
              {userAvatar ? <Avatar src={userAvatar.src} /> : <Icon name={iconUserSolid} htmlColor="white" />}
            </ButtonLink>
          </Box>
        </Box>
        <Divider />
      </Toolbar>
      {showConsentWarning && (
        <HeaderInfoMessage>
          <InfoMessage
            type="warning"
            message="educator.details.share.info.warning"
            closeButton={() => setShowLsConsentWarning(false)}
            showCloseButton={Boolean(showLsConsentWarning)}
          />
        </HeaderInfoMessage>
      )}
      {showRegisterEducator && (
        <HeaderInfoMessage>
          <InfoMessage
            type="info"
            message="more.educator.add.educator.info.text"
            closeButton={closeRegisterEducatorWarning}
            showCloseButton={Boolean(showLsRegisterEducatorWarning)}
            closeButtonColor="white"
          />
        </HeaderInfoMessage>
      )}

      {maintenanceMessage && showMaintenanceMessage && (
        <HeaderInfoMessage>
          <InfoMessage
            type="info"
            message="common.app.maintenance.info"
            closeButton={() => setShowMaintenanceMessage(false)}
            showCloseButton={true}
            closeButtonColor="white"
          />
        </HeaderInfoMessage>
      )}
    </AppBar>
  );
};

const HeaderInfoMessage = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      position={"fixed"}
      zIndex={10001}
      marginTop="75px"
      width={isMobile ? "100%" : desktopWidth420}
      marginLeft={isMobile ? 0 : `calc(100vw - ${desktopWidth420} - 16px)`}
      paddingLeft={2}
      paddingRight={2}
    >
      {children}
    </Box>
  );
};
