import React, { useEffect, useState } from "react";
import { Button, Grid, Theme } from "@mui/material";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { Stack, Box } from "@mui/system";
import { FormInputFirstName } from "layout/Components/Inputs/formInputFirstName";
import { FormInputLastName } from "layout/Components/Inputs/formInputLastName";
import { FormInputIdentityNumber } from "layout/Components/Inputs/formInputIdentityNumber";
import { SubmitHandler, useForm } from "react-hook-form";
import { User } from "types/user";
import { FormInputPhoneNumber } from "layout/Components/Inputs/formInputPhoneNumber";
import { InfoMessage } from "utils/helpers/InfoMessage";
import { testId } from "tests/testIdStrings";
import { FormInputContact } from "layout/Components/Inputs/formInputContact";
import { FormInputSecrecy } from "layout/Components/Inputs/formInputSecrecy";
import { FormCheckbox } from "utils/helpers/FormCheckbox";
import { useAppIntl } from "services/useAppIntl";
import { useNavigate } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import { API_URL_BO_ME } from "utils/constants";
import { useSetRecoilState } from "recoil";
import { refreshUserStateBasicInfo } from "state/userState";
import { useAccountHelper } from "hooks/useAccountHelper";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { validationSchemaUserNewUser } from "services/validationSchemaUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInfoMessage } from "hooks/useInfoMessage";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Picasso } from "layout/Components/Picasso";
import { formatIdentityNumber } from "services/validationService";
import { useLogout } from "hooks/useLogout";
import { useValidateBasicInfo } from "hooks/useValidateBasicInfo";
import { ICreateMeRequest } from "types/user";
import { educationStateEducationAdded } from "state/educationState";

const defaultFormValues = {
  firstName: "",
  identityNumber: "",
  lastName: "",
  phoneNumber: "",
  preferredCommunication: { email: true, phone: false },
  hasAcceptedAgreements: false,
};

export const NewAccount: React.FC = () => {
  const routes = useRouteConfiguration();
  const { basicInfoIsValidated, basicInfoHasErrors } = useValidateBasicInfo();
  const intl = useAppIntl();
  const { logout } = useLogout();
  const { json, error, post } = useFetch<User>();
  const { json: jsonExistingData } = useFetch<User>(API_URL_BO_ME);
  const navigate = useNavigate();
  const { account, hasIdTokenClaimNewUser } = useAccountHelper();
  const errorInfoMessage = useInfoMessage();
  const validationInfoMessage = useInfoMessage();
  const refreshBasicInfo = useSetRecoilState(refreshUserStateBasicInfo);
  const educationAdded = useSetRecoilState(educationStateEducationAdded);
  const refreshEducations = () => educationAdded((n: number) => n + 1);
  const [hasExistingSsn, setHasExistingSsn] = useState(false);

  const { control, handleSubmit, reset, getValues, setValue, formState } = useForm<ICreateMeRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchemaUserNewUser),
  });
  const { isDirty, isValid, isSubmitted } = formState;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // If basicInfo is valid, user already exists and we should redirect
  // This will also redirect after save
  useEffect(() => {
    if (!basicInfoIsValidated) return;
    if (!basicInfoHasErrors) {
      refreshEducations();
      navigate(routes.start.path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfoIsValidated, basicInfoHasErrors]);

  useEffect(() => {
    if (jsonExistingData) {
      if (jsonExistingData.identityNumber?.length > 0) setHasExistingSsn(true);

      // Need to remove props from User since it should not be posted with the ICreateMeRequest
      const { customerNumber, avatarId, ...washedExistingData } = jsonExistingData;

      reset(washedExistingData);
    }
  }, [jsonExistingData, reset]);

  useEffect(() => {
    if (!isValid && isSubmitted) {
      validationInfoMessage.setInfoMessageValues({
        message: "profile.helpertext.placeholdertext.errormessage",
        type: "error",
      });
    } else {
      validationInfoMessage.setInfoMessageValues(undefined);
    }
  }, [isValid, isSubmitted, validationInfoMessage]);

  useEffect(() => {
    // When a request is ready
    // we will have a json or error object
    if (!json && !error) return;

    if (error) {
      console.log(`Error on save new profile: ${JSON.stringify(error.errors)} `);
      errorInfoMessage.setInfoMessageValues({ message: "common.error.http.request.failed", type: "error" });
      return;
    }

    reset();
    refreshBasicInfo((n: number) => n + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, json]);

  const onSubmit: SubmitHandler<ICreateMeRequest> = async (user: ICreateMeRequest) => {
    if (!isValid) return;

    // Handle null value
    if (!user.phoneNumber?.length) {
      setValue("phoneNumber", null);
      user = getValues();
    }

    // Ensure we post correct formatted identity number
    setValue("identityNumber", formatIdentityNumber(user.identityNumber));
    user = getValues();

    user.email = account?.username ?? "";

    post(API_URL_BO_ME, user);
  };

  const handleCancel = () => logout();

  return (
    <>
      <Picasso />
      <form>
        <Stack spacing={3}>
          <Stack spacing={4} sx={{ display: "flex", width: isMobile ? "90%" : "440px", alignSelf: "center" }}>
            <FormattedTypography variant="h1" fontSize={"40px"} id="common.title.book" />
            <FormattedTypography variant="h3" id="new.user.create.account" />
            {!hasIdTokenClaimNewUser() && <InfoMessage type="warning" message={"new.user.not.missing.account.info"} />}
            <FormInputPhoneNumber control={control} />
            <FormInputContact control={control} />
            <Box sx={{ ".MuiTextField-root": { width: "100%" } }}>
              <Grid container rowSpacing={4} columnSpacing={{ xs: 0, sm: 2 }} sx={{ width: "100%" }}>
                <Grid item xs={12} sm={6}>
                  <FormInputFirstName control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputLastName control={control} />
                </Grid>
              </Grid>
            </Box>
            <FormInputIdentityNumber disabled={hasExistingSsn} control={control} />
            <FormInputSecrecy
              checkboxComponent={
                <FormCheckbox
                  label={intl.formatMessage({ id: "new.user.hasAcceptedAgreements" })}
                  name={"hasAcceptedAgreements"}
                  labelPlacement={"end"}
                  dataTestIdString={`${testId.checkbox.profile}-hasAcceptedAgreements`}
                  control={control}
                />
              }
            />
          </Stack>
          <Stack spacing={2} sx={{ display: "flex", width: isMobile ? "90%" : "440px", alignSelf: "center" }}>
            {errorInfoMessage.infoMessage}
            {validationInfoMessage.infoMessage}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: isMobile ? "flex-start" : "flex-end",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Button
                sx={{ marginRight: 2, width: isMobile ? "100%" : "30%", marginBottom: 12 }}
                variant="outlined"
                data-testid={`${testId.button.profile}-${testId.common.cancel}`}
                onClick={handleCancel}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                sx={{ width: isMobile ? "100%" : "40%", order: isMobile ? -1 : 0, marginBottom: isMobile ? 2 : 0 }}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                data-testid={`${testId.button.profile}-save`}
                disabled={!isDirty || (isDirty && !isValid)}
              >
                <FormattedMessage id="new.user.create.account" />
              </Button>
            </Box>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
