import { Box, ListItemIcon, MenuItem, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import { CasingType, useAppIntl } from "services/useAppIntl";
import { DataTestType } from "tests/test.setup";
import { testId } from "tests/testIdStrings";
import { Icon, iconCheck, IconProp } from "theme/icons";
import { IMenuItem } from "./MenuItems";

export interface IEducationMenuItem extends IMenuItem, DataTestType {
  customAction?: (item: IEducationMenuItem) => void;
  customStyle?: { iconColor?: string };
}
export interface IEducationMenuItemsProps {
  items: IEducationMenuItem[];
  onClick?: (id: IEducationMenuItem) => void;
  onMenuClose?: () => void;
  onSetLabel?: (label: ReactNode) => void;
}
export const EducationMenuItems = ({ items, onClick, onMenuClose, onSetLabel }: IEducationMenuItemsProps) => {
  const theme = useTheme();

  const handleClick = (item: IEducationMenuItem) => {
    if (onMenuClose) onMenuClose();

    if (item.customAction) {
      item.customAction(item);
      return;
    }

    if (onClick) onClick(item);
    if (onSetLabel && item.id) onSetLabel(<ButtonLabel item={item} />);
  };
  return (
    <>
      {items.map((item, index) => (
        <MenuItem
          key={item.id}
          onClick={() => handleClick(item)}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
          data-testid={
            item.dataTestIdString
              ? item.dataTestIdString
              : `${testId.common.dropDown.subscription.educations}-${index + 1}`
          }
        >
          <Box display="flex">
            {item.icon && (
              <Box sx={{ display: "flex", marginRight: 1 }}>
                <StyledIcon icon={item.icon} iconColor={item.customStyle?.iconColor} />
              </Box>
            )}
            {item.title}
          </Box>
          {item.selected && <StyledIcon icon={iconCheck} iconColor={theme.palette.primary.dark06} />}
        </MenuItem>
      ))}
    </>
  );
};

export const ButtonLabel: FC<{ item: IEducationMenuItem }> = ({ item }) => {
  const intl = useAppIntl();
  return (
    <>
      <StyledIcon icon={item.icon} iconColor="white" />
      <Box display={"flex"} flexDirection="column" alignItems={"flex-start"}>
        <Typography variant="h4">
          {intl.formatMessage({ id: "common.app.title" }, { casingType: CasingType.firstLetterCapitalized })}
        </Typography>
        <Typography
          variant="captionImage"
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "160px",
            maxWidth: "160px",
            textOverflow: "ellipsis",
            textAlign: "left",
          }}
        >
          {item.title}
        </Typography>
      </Box>
    </>
  );
};

const StyledIcon: FC<{ icon?: IconProp; iconColor?: string }> = ({ icon, iconColor }) => {
  const theme = useTheme();
  if (!icon) return null;

  return (
    <ListItemIcon
      sx={{
        minWidth: "35px",
        color: iconColor ?? theme.palette.primary.main,
      }}
    >
      <Icon name={icon} size={"medium"} htmlColor={iconColor ?? theme.palette.primary.main} />
    </ListItemIcon>
  );
};
