import { useEffect, useState } from "react";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useRecoilValue } from "recoil";
import { educationContentTypeState, learningStructureState } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { useAssessment } from "hooks/useAssessment";
import { TestSettings, useTestSettings } from "hooks/useTestSettings";
import { ItemAssessment } from "@strmediaochitab/optima-component-library";
import { Test, TestLabel } from "./test";
import { XapiKey } from "services/lrsService";
import { useAccount } from "@azure/msal-react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { NotFound } from "layout/notFound";

export const TestQuick = () => {
  const account = useAccount();
  const { type } = useParams();
  const { quickTest, quickTest2, roadsignsTest } = useTestSettings();
  const [test, setTest] = useState<ItemAssessment | undefined>();
  const testType: any = {
    roadsigns: roadsignsTest,
    study: quickTest,
    study2: quickTest2,
  };

  const [testSettings, setTestSettings] = useState<TestSettings>(testType[type!]);
  const { subjectCategories, assessment } = useAssessment({
    type: testSettings.questionType,
    key: useRecoilValue(educationStateReferenceKey)!,
  });

  const learningStructure = useRecoilValue(learningStructureState);
  const learningStructureRoot = learningStructure?.filter((node: any) => node.parentId === 0)[0];

  const xapiKey: XapiKey = {
    userId: account!.localAccountId,
    contentId: learningStructureRoot?.activityKey?.contentId!,
    versionId: learningStructureRoot?.activityKey?.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get(testSettings.questionType)!,
  };

  // When subjects resolved set them to state
  useEffect(() => {
    if (!subjectCategories) return;

    let subjects = new Set<string>();
    subjectCategories.forEach((item) => subjects.add(item.id!));

    setTestSettings({ ...testSettings, subjects });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectCategories]);

  // When testSettings complete, create the test
  useEffect(() => {
    if (!assessment) return;
    if (!testSettings.subjects.size) return;

    const test = {
      ...assessment,
      configuration: {
        ...assessment.configuration,
        duration: testSettings.duration,
        quantity: testSettings.questions,
      },
    };

    console.log("startar test med följande params", testSettings, test);
    setTest(test);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSettings.subjects, assessment]);

  if (!account) return null;
  if (assessment === null) return <NotFound />;

  // Start the test
  if (test)
    return (
      <Test
        label={testSettings.questionType === "roadsigns" ? TestLabel.RoadsignQuick : TestLabel.StudyQuick}
        assessment={test}
        xapiKey={xapiKey}
        userId={account.localAccountId}
      />
    );

  return (
    <>
      <BackgroundShape top={-260} right={-200}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>

      <Box display="flex" height="80vh" alignContent="center" alignItems="center" justifyContent="center">
        <FormattedTypography variant="body1" mb={4} id="common.title.test.creating" />
      </Box>

      <BackgroundShape bottom={-260} left={-130}>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};
