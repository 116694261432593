import { Box, Typography, IconButton, alpha, FormControl, Stack, FormHelperText } from "@mui/material";
import { common } from "@mui/material/colors";
import useToggle from "hooks/useToggle";
import { Control, useFormState } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { iconCircleInfo, Icon } from "theme/icons";
import { styleFlex, styleFlexAlignItemsCenter } from "theme/styles";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormCheckbox } from "utils/helpers/FormCheckbox";

interface IFormInputContactProps {
  control: Control<any, any> | undefined;
}
export const FormInputContact = ({ control }: IFormInputContactProps) => {
  const intl = useAppIntl();
  const [openContactDialog, setOpenContactDialog] = useToggle(false);
  const { errors } = useFormState({ control });

  return (
    <>
      <Box>
        <Box sx={[styleFlex, styleFlexAlignItemsCenter]}>
          <Typography variant="subtitle2">
            <FormattedMessage id={"profile.label.prefered-contact"} />
          </Typography>
          <IconButton onClick={() => setOpenContactDialog(true)}>
            <Icon name={iconCircleInfo} htmlColor={alpha(common.black, 0.87)} />
          </IconButton>
        </Box>

        <FormControl required error={true} component="fieldset" variant="standard">
          <Stack direction="row" spacing={1}>
            <FormCheckbox
              label={intl.formatMessage({ id: "common.email" })}
              name={"preferredCommunication.email"}
              labelPlacement={"end"}
              dataTestIdString={`${testId.checkbox.profile}-email`}
              control={control}
            />
            <FormCheckbox
              label={intl.formatMessage({ id: "common.sms" })}
              name={"preferredCommunication.phone"}
              labelPlacement={"end"}
              dataTestIdString={`${testId.checkbox.profile}-sms`}
              control={control}
            />
          </Stack>
          {errors.preferredCommunication && (
            <FormHelperText>
              <FormattedMessage id="profile.helpertext.checkboxes" />
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <DialogMessage
        title={"common.information"}
        open={openContactDialog}
        close={() => setOpenContactDialog(false)}
        primaryButtonText={"common.button.ok"}
        dataTestIdString={`${testId.listItem.profile.contactInfoBox}`}
        noCancelButton
      >
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage id={"profile.helpertext.placeholdertext"} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={"profile.helpertext.placeholdertext.subtext"} />
        </Typography>
      </DialogMessage>
    </>
  );
};
