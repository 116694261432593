import { atom, selectorFamily, selector } from "recoil";
import { AvailableEducationsDto, AvailableEducation, UserEducation, UserEducations } from "types/education";
import { get as httpGet } from "services/httpService";
import { API_URL_BO_EDUCATION, API_URL_BO_PRODUCT_AVAILABLE_EDUCATIONS } from "utils/constants";
import { getEducationIcon } from "utils/helpers/GetEducationIcon";
import { instanceOfRequestError, localStorageEffect } from "./stateHelper";
import { RequestError } from "types/request";
import { ReferenceKey } from "types/cds";
import { API_URL_BO_EDUCATOR } from "utils/constants";
import { ConnectedEducator } from "types/educator";
//import * as appStateService from "services/appStateService";

export const educationState = atom<UserEducation | undefined>({
  key: "educationState",
  default: undefined,
  effects: [
    localStorageEffect("educationState"),

    // TODO: Maybe add this when we have decided how to handle this
    // ({ onSet, setSelf, getLoadable }) => {
    //   const stateKey: appStateService.StateKey = {
    //     stateId: appStateService.educationStateId,
    //   };

    //   appStateService.getState<appStateService.EducationState>(stateKey).then(async (state) => {
    //     if (!state) return;

    //     const education = await getLoadable(userEducationState({ identifier: state[0].identifier })).toPromise();
    //     if (!education) return;

    //     if (education) setSelf(education);
    //   });

    //   onSet(async (education) => {
    //     if (!education) return;

    //     const state: appStateService.EducationState = {
    //       identifier: education.identifier,
    //     };
    //     console.debug("Remember educationId:", education, "and state is", state);

    //     appStateService.saveState(stateKey, state);
    //   });
    // },
  ],
});

export const educationStateReferenceKey = selector<ReferenceKey | undefined>({
  key: "educationStateReferenceKey",
  get: ({ get }) => {
    const education = get(educationState);

    if (!education) return undefined;
    if (!education.contentId) {
      console.warn("ContentId not found on current education");
      return undefined;
    }

    const referenceKey: ReferenceKey = { contentId: education.contentId, versionId: education.versionId };
    return referenceKey;
  },
});

export const userEducationState = selectorFamily<UserEducation | undefined, { identifier: string | undefined }>({
  key: "userEducationState",
  get:
    ({ identifier }) =>
    async ({ get }) => {
      const educations = get(userEducationsState);
      return educations?.find((x) => x.identifier === identifier);
    },
});

export const educationStateEducationAdded = atom<number>({
  key: "educationStateEducationAdded",
  default: 0,
});
export const educationStateFinalTestUnlocked = atom<number>({
  key: "educationStateFinalTestUnlocked",
  default: 0,
});

// Available educations
export const educationStateAvailableEducations = selector<AvailableEducation[]>({
  key: "educationStateAvailableEducations",
  get: async ({ get }) => {
    get(educationStateEducationAdded); // Add dependency to trigger update

    const availableEducations: AvailableEducationsDto = await httpGet(API_URL_BO_PRODUCT_AVAILABLE_EDUCATIONS);

    const educations = availableEducations.educations
      ? availableEducations.educations.map<AvailableEducation>((education) => {
          return { ...education, icon: getEducationIcon(education.name) };
        })
      : [];

    return educations;
  },
});

// User educations
export const userEducationsState = selector<UserEducation[] | undefined>({
  key: "userEducationsState",
  get: async ({ get }) => {
    // Add dependencies to trigger update of other state
    get(educationStateEducationAdded);
    get(educationStateFinalTestUnlocked);

    const userEducations: UserEducations | RequestError = await httpGet(`${API_URL_BO_EDUCATION}educations`);

    // TODO Handle if we get an error
    if (instanceOfRequestError(userEducations)) {
      console.log("error when fetching educations");
      return undefined;
    }

    const educations = userEducations.educations
      ? userEducations.educations.map<UserEducation>((education) => {
          return { ...education, icon: getEducationIcon(education.name) };
        })
      : [];

    // TODO: Put CurrentEducationIdentifier directly here, instead of useEffect in App.tsx
    return educations;
  },
});

export const educationStateInfoShared = atom<number>({
  key: "educationStateInfoShared",
  default: 0,
});

export const connectedEducatorsSelector = selector<ConnectedEducator[] | undefined>({
  key: "connectedEducatorsSelector",
  get: async ({ get }) => {
    get(educationStateInfoShared); // Add dependency to trigger update

    const connectedEducatorData = await httpGet(`${API_URL_BO_EDUCATOR}connected-educators`);
    if (!connectedEducatorData) return undefined;
    return connectedEducatorData;
  },
});
