import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { svSE } from "./languages"; // base language which we use as default

export type IntlMessageKeys = keyof typeof svSE;

export enum CasingType {
  upperCase = "upperCase",
  lowerCase = "lowerCase",
  firstLetterCapitalized = "firstLetterCapitalized",
}
export const useAppIntl = () => {
  const intl = useIntl();
  type FormatMessageParameters = Parameters<typeof intl.formatMessage>;
  type DescriptorType = FormatMessageParameters[0] & { id: IntlMessageKeys };
  type ValuesType = FormatMessageParameters[1];
  type OptionsType = {
    values?: ValuesType;
    casingType?: CasingType;
  };

  const formatMessage = useCallback(
    (descriptor: DescriptorType, options?: OptionsType): string => {
      const message = intl.formatMessage(descriptor, options?.values) as string;

      if (!options) return message;

      switch (options.casingType) {
        case CasingType.upperCase:
          return message.toLocaleUpperCase();
        case CasingType.lowerCase:
          return message.toLocaleLowerCase();
        case CasingType.firstLetterCapitalized:
          return message.charAt(0).toUpperCase() + message.slice(1);
        default:
          return message;
      }
    },
    [intl]
  );

  return useMemo(() => ({ ...intl, formatMessage }), [intl, formatMessage]);
};
