import { Box, Container, Theme, useTheme } from "@mui/material";
import { FC, ReactNode, Suspense, useEffect, useState } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import ErrorBoundary from "utils/helpers/ErrorBoundary";
import { PageLoader, SuspenseLoader } from "utils/helpers/LoadingIndicator";
import { TopNavigationTabs } from "utils/helpers/theory/TopNavigationTabs";
import { Footer } from "./footer";
import { Header } from "./header";
import { Header as MoreHeader } from "./more/header";
import { Header as TestHeader } from "./test/header";
import { Footer as TheoryFooter } from "./theory/footer";
import { Header as TheoryHeader } from "./theory/header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useActiveSectionScrollSpy } from "hooks/useActiveSectionScrollSpy";
import { useSetRecoilState } from "recoil";
import { theorySectionScrollSpyState } from "state/theoryState";
import { useSaveTheoryState } from "hooks/useSaveTheoryState";

interface IPageContainerProps {
  children: ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  overflow?: string;
  height?: number | string;
}
const PageContainer: FC<IPageContainerProps> = ({
  children,
  paddingBottom,
  paddingTop,
  paddingLeft,
  paddingRight,
  height,
  overflow,
}) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: paddingTop !== undefined ? paddingTop : theme.header.height,
        paddingBottom: paddingBottom !== undefined ? paddingBottom : theme.header.height + 1,
        paddingLeft: paddingLeft !== undefined ? paddingLeft : null,
        paddingRight: paddingRight !== undefined ? paddingRight : null,
        position: "relative",
        overflow: overflow || "hidden",
        minHeight: !height ? "100vh" : "auto",
        height: height || "auto",
      }}
    >
      {children}
    </Container>
  );
};

export const DefaultLayout: FC = () => {
  return (
    <PageContainer>
      <Header />
      <PageLoader />
      <Box>
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </Box>

      <Footer />
    </PageContainer>
  );
};

export const DefaultLayoutNoFooter: FC = () => {
  return (
    <PageContainer paddingBottom={3}>
      <Header />
      <PageLoader />
      <Box>
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </Box>
    </PageContainer>
  );
};

export const TheoryLayout: FC = () => {
  const location = useLocation();
  const isAnswer = location.pathname.includes("answer");
  const [, setTopNavigate] = useState({ function: undefined });
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const activeSection = useActiveSectionScrollSpy();
  const setScrollSection = useSetRecoilState(theorySectionScrollSpyState);

  useSaveTheoryState();

  useEffect(() => {
    if (!activeSection) return;

    setScrollSection(activeSection);
    console.log("active section is", activeSection);
  }, [activeSection, setScrollSection]);

  return (
    <PageContainer>
      <TheoryHeader>
        <TopNavigationTabs />
      </TheoryHeader>
      <PageLoader />
      <Suspense fallback={<SuspenseLoader />}>
        <Outlet context={{ setTopNavigate }} />
      </Suspense>
      {!isAnswer && !isDesktop && <TheoryFooter />}
    </PageContainer>
  );
};

export const TheoryChaptersLayout: FC = () => {
  return (
    <PageContainer>
      <TheoryHeader />
      <PageLoader />
      <Box marginLeft="auto" marginRight="auto">
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </Box>
    </PageContainer>
  );
};

export const TestLayout: FC = () => {
  const [topNavigate, setTopNavigate] = useState({ function: undefined });

  return (
    <PageContainer paddingBottom={3}>
      <TestHeader callback={topNavigate.function} />
      <PageLoader />
      <Box marginLeft="auto" marginRight="auto">
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet context={{ setTopNavigate }} />
        </Suspense>
      </Box>
    </PageContainer>
  );
};

export const MoreLayout: FC = () => {
  const [topNavigate, setTopNavigate] = useState({ function: undefined });
  const url = window.location.href;
  const paddingBottom = url.includes("/educator/find") ? 0 : undefined;

  return (
    <PageContainer paddingBottom={paddingBottom}>
      <MoreHeader callback={topNavigate.function} />
      <PageLoader />
      <Box marginLeft="auto" marginRight="auto">
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet context={{ setTopNavigate }} />
        </Suspense>
      </Box>
    </PageContainer>
  );
};

export const EducationLayout: FC = () => {
  const location = useLocation();
  const overflow = location.pathname === "/new-account" ? "hidden" : "auto";
  const height = location.pathname === "/new-account" ? undefined : "100vh";

  return (
    <PageContainer paddingTop={9} paddingBottom={1} overflow={overflow} height={height}>
      <PageLoader />
      <Box marginLeft="auto" marginRight="auto" display={"flex"} flexDirection="column" height={"100%"}>
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </Box>
    </PageContainer>
  );
};

type TopNavigationContextType = {
  topNavigate: { function: () => void };
  setTopNavigate: React.Dispatch<React.SetStateAction<{ function: undefined | any }>>;
};

export function useTopNavigation() {
  const context = useOutletContext<TopNavigationContextType>();
  if (context === undefined) throw new TypeError("useTopNavigation is missing its outlet context");
  return context;
}

/**
 * Used for component that should be rendered without Routing
 */
export const BasicLayout: FC<IPageContainerProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <PageContainer {...rest}>
      <PageLoader />
      <Box marginLeft="auto" marginRight="auto" display={"flex"} flexDirection="column" height={"100%"}>
        <ErrorBoundary>
          <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>
        </ErrorBoundary>
      </Box>
    </PageContainer>
  );
};
