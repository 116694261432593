import React, { useEffect } from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Stack } from "@mui/material";
import { useLRSProviderContext } from "@strmediaochitab/optima-component-library";

type Props = {
  open: boolean;
  close: () => void;
};

export const TimesUpTestDialog: React.FC<Props> = (props) => {
  const { trackAssessment } = useLRSProviderContext();
  const { open, close } = props;

  useEffect(() => {
    if (!open) return;

    trackAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCorrectTest = () => {
    close();
  };

  return (
    <DialogMessage
      title="test.dialog.times-up.title"
      open={open}
      close={handleCorrectTest}
      primaryButtonText="test.final.dialog.finish-test.button"
      primaryButtonAction={() => handleCorrectTest()}
      noCancelButton
      dataTestIdString={testId.listItem.test.common.correction}
    >
      <Stack spacing={1}>
        <FormattedTypography id={"test.dialog.times-up.body"} />
      </Stack>
    </DialogMessage>
  );
};
