import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Icon, iconCircleCheck, iconCircleXmarkSolid, iconArrowCircleRight } from "theme/icons";
import { Link, useLocation } from "react-router-dom";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { styleTabContent } from "./allTestResults";
import { useAppIntl } from "services/useAppIntl";
import { useTestSettings } from "hooks/useTestSettings";
import FormattedMessage from "utils/helpers/FormattedMessage";

type Props = {
  testResults: ItemTestResultItem[];
};

export const FinalTest = ({ testResults }: Props) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const location = useLocation();
  const { finalTest } = useTestSettings();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  const finalTestResultLabel = (result: number) => {
    if (result >= finalTest.ratio!) {
      return <FormattedMessage id="common.title.passed" />;
    } else {
      return <FormattedMessage id="common.title.failed" />;
    }
  };

  return (
    <>
      {testResults
        .map((result, i) => {
          const { value, max, duration, finished } = result;
          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });
          const didPassTest =
            parseInt(value) >= finalTest.ratio! ? (
              <Icon name={iconCircleCheck} htmlColor={theme.palette.success.main} />
            ) : (
              <Icon name={iconCircleXmarkSolid} htmlColor={theme.palette.error.main} />
            );

          return (
            <Link
              key={i}
              style={{ textDecoration: "none", color: theme.palette.text.primary }}
              to={"/progress/finaltestresult"}
              state={{ testId: result.statementRef, from: location }}
            >
              <Box sx={styleTabContent}>
                <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                  <Box display={"flex"} flexDirection={"row"} width="140px">
                    {didPassTest}
                    <Typography marginLeft={"10px"} variant="body2">
                      {finalTestResultLabel(Number(value))}
                    </Typography>
                  </Box>
                  <Typography variant="body2" display={"flex"} textAlign={"left"}>
                    {value}/{max}
                  </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} textAlign={"right"}>
                  <Typography variant="body2">{testDuration}</Typography>
                  <Typography variant="caption">{getDateWithTime(finished)}</Typography>
                </Box>
                <Icon name={iconArrowCircleRight} color="primary" />
              </Box>
            </Link>
          );
        })
        .reverse()}
    </>
  );
};
