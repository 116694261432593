import { svSE, enUS } from "./languages";

export type SupportedLanguages = "sv" | "en";
export const messages = {
  sv: svSE,
  en: enUS,
};

type Videos = {
  [key in SupportedLanguages]: {
    [key in VideoIds]: string;
  };
};

const videoUrl = "https://player.vimeo.com/video";
export type VideoIds = "getStarted" | "testHowTo";
export const videos: Videos = {
  sv: {
    getStarted: `${videoUrl}/867101607?badge=0&autopause=0&player_id=0&app_id=58479`,
    testHowTo: `${videoUrl}/913645075?badge=0&autopause=0&player_id=0&app_id=58479`,
  },
  // TODO: Add English videos when we get them
  en: {
    getStarted: `${videoUrl}/922822538?badge=0&autopause=0&player_id=0&app_id=58479`,
    testHowTo: `${videoUrl}/922825174?badge=0&autopause=0&player_id=0&app_id=58479`,
  },
};
