import React, { useEffect, useState } from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Stack } from "@mui/material";
import { useLRSProviderContext } from "@strmediaochitab/optima-component-library";

type Props = {
  open: boolean;
  close: () => void;
};

export const FinishTestDialog: React.FC<Props> = ({ open, close }) => {
  const { getOverview, trackAssessment } = useLRSProviderContext();
  const [unanswered, setUnanswered] = useState<number>();

  useEffect(() => {
    if (!open) return;

    const overview = getOverview();
    if (!overview) return;

    let unanswered = 0;
    overview.forEach((question) => {
      if (!question.result?.response) unanswered += 1;
    });

    setUnanswered(unanswered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCorrectTest = () => {
    trackAssessment();
    close();
  };

  return (
    <DialogMessage
      title="test.final.dialog.finish-test.title"
      open={open}
      close={close}
      primaryButtonText="test.final.dialog.finish-test.button"
      primaryButtonAction={() => handleCorrectTest()}
      dataTestIdString={testId.listItem.test.common.correction}
    >
      <Stack spacing={2}>
        <FormattedTypography
          id={unanswered ? "test.final.dialog.finish-test.body.not-done" : "test.final.dialog.finish-test.body"}
          values={{
            value: unanswered,
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
      </Stack>
    </DialogMessage>
  );
};
