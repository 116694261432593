import { useRecoilValue } from "recoil";
import { bookmarksStateSelector } from "state/bookmarkState";
import { BookmarksList } from "./bookmarksList";
import { NoBookmarks } from "./noBookmarks";

export const Bookmarks = () => {
  const list = useRecoilValue(bookmarksStateSelector);

  return list && list?.length > 0 ? <BookmarksList list={list} /> : <NoBookmarks />;
};
