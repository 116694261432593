import { Icon, iconBookSolid, iconCaretDown } from "theme/icons";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { DropdownMenu, DropdownMenuItems } from "utils/helpers/dropdown/DropdownMenu";
import { useRecoilValue } from "recoil";
import { educationStateAvailableEducations } from "state/educationState";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { FC, useState, useEffect, useCallback } from "react";
import { IMenuItem } from "utils/helpers/dropdown/MenuItems";
import { AvailableEducation } from "types/education";

interface IAddEducationState {
  isOpen: boolean;
  education?: AvailableEducation;
}

interface IAvailableEducationDropdownProps {
  handleActivateNewEducation: (id: AvailableEducation) => void;
}

export const AvailableEducationDropdown: FC<IAvailableEducationDropdownProps> = ({ handleActivateNewEducation }) => {
  const intl = useAppIntl();
  const [addEducationDialog, setAddEducationDialog] = useState<IAddEducationState>({ isOpen: false });
  const availableEducations = useRecoilValue(educationStateAvailableEducations);
  const [educationMenuItems, setEducationMenuItems] = useState<IMenuItem[]>([]);
  const dropdownLabel = intl.formatMessage({ id: "more.title.education" });

  let handleUpdateLabel = useCallback(() => {
    if (!addEducationDialog.education) return dropdownLabel;

    return undefined;
  }, [addEducationDialog.education, dropdownLabel]);

  useEffect(() => {
    const items = availableEducations?.map<IMenuItem>((education) => {
      return { id: education.identifier, title: education.name, icon: education.icon };
    });

    if (items.length <= 0) {
      items.push({ title: intl.formatMessage({ id: "education.general.no-more-available" }) });
    }

    setEducationMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableEducations]);

  const handleMenuItemClick = (id?: string) => {
    if (!id) return;
    const education = availableEducations.find((x) => x.identifier === id);
    setAddEducationDialog({ isOpen: true, education });
  };

  const handlePrimaryButtonClick = () => {
    const education = addEducationDialog.education;

    if (!education) return console.warn("no selected education was set in AvailableEducationDropdown");

    setAddEducationDialog({ isOpen: false, education: undefined });
    handleActivateNewEducation(education);
  };

  return (
    <>
      <DropdownMenu
        id="ddEducations"
        label={dropdownLabel}
        variant="outlined"
        startIcon={<Icon name={iconBookSolid} />}
        endIcon={<Icon name={iconCaretDown} />}
        sx={{ minWidth: "280px" }}
        onClick={(e) => handleMenuItemClick(e.id)}
        onSetLabel={handleUpdateLabel}
      >
        <DropdownMenuItems
          items={educationMenuItems}
          dataTestIdString={testId.listItem.education.educationNavigationAvailable}
        />
      </DropdownMenu>
      {addEducationDialog.isOpen && (
        <DialogMessage
          title={"education.activate.new"}
          open={addEducationDialog.isOpen}
          close={() => setAddEducationDialog({ isOpen: false })}
          primaryButtonText={"education.general.start"}
          primaryButtonAction={handlePrimaryButtonClick}
          dataTestIdString={`${testId.button.dialogMessage}-activate`}
        >
          <FormattedTypography
            variant="body1"
            sx={{ marginBottom: "1rem" }}
            id={"education.activate.new.dialog"}
            values={{ hours: `${addEducationDialog.education?.trialPeriodInHours}` }}
          />
        </DialogMessage>
      )}
    </>
  );
};
