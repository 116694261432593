import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputIdentityNumberProps {
  control: Control<any, any> | undefined;
  disabled?: boolean;
}

export const FormInputIdentityNumber = ({ control, disabled = false }: IFormInputIdentityNumberProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name="identityNumber"
      label={intl.formatMessage({ id: "profile.label.social-security-number" }, { values: { required: "*" } })}
      dataTestIdString={`${testId.input.profile}-security.number`}
      control={control}
      disabled={disabled}
      helperText={intl.formatMessage({ id: "profile.helpertext.social-security-number" })}
    />
  );
};
