import { TextField } from "@mui/material";
import { ClipboardEvent } from "react";
import { useAppIntl } from "services/useAppIntl";

interface IFormInputValueCode {
  valueCode: string;
  onSetCode: (code: string) => void;
}

/**
 * Renders a value code component with an input for the value
 * @param {number} valueCode - The value code to enter/paste.
 */
export const FormInputValueCode = ({ valueCode, onSetCode }: IFormInputValueCode) => {
  const intl = useAppIntl();

  function onPaste(event: ClipboardEvent<HTMLInputElement>) {
    event.preventDefault();

    const pasted = event.clipboardData.getData("text/plain");
    onSetCode(pasted);
  }

  function onChange(event: any) {
    onSetCode(event.target.value);
  }

  return (
    <TextField
      label={intl.formatMessage({ id: "education.value-code" })}
      variant="outlined"
      value={valueCode}
      fullWidth
      inputProps={{ onPaste, onChange, maxLength: 9 }}
      inputRef={(input) => input && input.focus()}
      sx={{
        ".MuiInputBase-input": { textAlign: "center", fontSize: "1.25rem" },
      }}
    />
  );
};
