import { SupportedLanguages } from "services/localizationService";
import { IconProp } from "theme/icons";

// TODO: Vi bör se över dessa typer och konsolidera lite...

export type AvailableEducationDto = {
  identifier: string;
  name: string;
  trialPeriodInHours: number;
};

/**
 * Educations that are available to subscribe to
 */
export type AvailableEducation = AvailableEducationDto & {
  icon: IconProp;
};

export type AvailableEducationsDto = {
  educations: AvailableEducationDto[];
};

export type AvailableEducations = {
  educations: AvailableEducation[] | undefined;
};

/**
 * Educations that are connected to user
 */
export type UserEducation = AvailableEducation & {
  educationState: EducationStateEnum;
  completed?: Date;
  validFrom: Date;
  validUntil: Date;

  /** Only available if educationState is 'Active' */
  contentId?: string;
  /** Only available if educationState is 'Active' */
  versionId?: string;
  /** True if user has manually unlocked hens final test */
  finalTestIsUnlocked?: boolean;

  language?: SupportedLanguages;
  productIdentifier?: string;
};

export type UserEducations = {
  educations: UserEducation[] | undefined;
};

export enum EducationStateEnum {
  Active = "Active",
  Paused = "Paused",
  Completed = "Completed",
}
