import { InputAdornment, TextField, TextFieldProps, alpha } from "@mui/material";
import { common } from "@mui/material/colors";
import { ReactNode } from "react";

type Props = TextFieldProps & {
  startIcon?: ReactNode;
  endIcon?: any;
};
const SearchField = ({ ...props }: Props) => {
  const { startIcon, endIcon, ...restProps } = props;

  return (
    <TextField
      variant="outlined"
      color="primary"
      fullWidth
      sx={{
        ".MuiInputBase-root": {
          borderRadius: "15px",
        },
        "&.MuiTextField-root": {
          borderRadius: "15px",
          border: "1px solid",
          borderColor: alpha(common.black, 0.12),
          backgroundColor: "white",
        },
        fieldset: {
          border: 0,
        },
      }}
      InputProps={{
        startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null,
        endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
      }}
      {...restProps}

      // value={search}
      // onChange={(e) => setSearch(e.target.value)}
    />
  );
};

export default SearchField;
