import { Box, Typography } from "@mui/material";
import { useAppIntl } from "services/useAppIntl";
import { Title } from "utils/helpers/TitleDivider";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { educationStateEducationAdded, userEducationsState } from "state/educationState";
import { EducationHandler } from "layout/more/education/EducationHandler";
import { useFetch } from "hooks/useFetch";
import { API_URL_BO_PRODUCT } from "utils/constants";
import { useEffect, useMemo, useState } from "react";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { MoreDropdownMenu } from "./moreDropdownMenu";
import { AvailableEducationDropdown } from "./availableEducationDropdown";
import { InfoBoxNoEducation } from "./infoBoxNoEducation";
import { AvailableEducation } from "types/education";
import { StartTrialResponse } from "types/StartTrialResponse";
import { useDateService } from "hooks/useDateService";
import SvgImage, { svgColor, ellipse1, ellipse2 } from "utils/helpers/SvgImage";
import { ValueCode } from "layout/Components/ValueCode";

export const Education = () => {
  const intl = useAppIntl();
  const { snackbarMessage, setSnackbarValues } = useSnackbarMessage();
  const [activatedEducation, setActivatedEducation] = useState<AvailableEducation | undefined>();
  const educationAdded = useSetRecoilState(educationStateEducationAdded);
  const refreshEducations = () => educationAdded((n: number) => n + 1);
  const { getDateAsLocalDateString: getDateAsString } = useDateService();

  const startTrialFetch = useFetch<StartTrialResponse>();

  useEffect(() => {
    if (startTrialFetch.error) {
      return setSnackbarValues({
        message: startTrialFetch.error?.status + JSON.stringify(startTrialFetch.error?.errors),
        type: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTrialFetch.error]);

  useEffect(() => {
    if (startTrialFetch.json) {
      refreshEducations();

      const validUntilString = startTrialFetch.json.validUntil ? getDateAsString(startTrialFetch.json.validUntil) : "";

      const messages = intl.formatMessage(
        { id: "education.activate.confirm.messages" },
        { values: { date: validUntilString, educationName: activatedEducation?.name } }
      );
      return setSnackbarValues({ message: messages, type: "success" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTrialFetch.json]);

  const handleActivateNewEducation = useMemo(
    () => (education: AvailableEducation) => {
      setActivatedEducation(education);
      startTrialFetch.post(`${API_URL_BO_PRODUCT}start-trial`, { productIdentifier: education.identifier }, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <BackgroundShape top={-220} left={-162}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>

      <Box display={"flex"} justifyContent={"space-between"} alignItems="start">
        <Typography variant="h1" data-testid={testId.common.text.welcomeMessage}>
          <Title title={intl.formatMessage({ id: "common.title.book" })} />
        </Typography>
        <Box mt={-1.5}>
          <MoreDropdownMenu />
        </Box>
      </Box>
      <Box mt={6}>
        <AvailableEducationDropdown handleActivateNewEducation={handleActivateNewEducation} />
      </Box>
      <Box mt={6}>
        <EducationList />
      </Box>
      <Box mt={2} flexGrow={1} display="flex" justifyContent={"flex-end"} flexDirection="column" pb={13}>
        <ValueCode />
      </Box>

      <BackgroundShape bottom={-325} center position="fixed">
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.turquoise} />
      </BackgroundShape>

      {snackbarMessage}
    </>
  );
};

const EducationList = () => {
  const userEducations = useRecoilValue(userEducationsState);

  if (userEducations === undefined) return null;

  if (userEducations.length === 0) return <InfoBoxNoEducation />;

  return <EducationHandler educations={userEducations} />;
};
