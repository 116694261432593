import { FC } from "react";
import { testId } from "tests/testIdStrings";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";


interface IActivateDialogProps {
  isOpen: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  action: () => void;
}

export const ActivateDialog: FC<IActivateDialogProps> = ({ isOpen, close, action }) => {
  return (
    <DialogMessage
      title={"education.dialog.activate-extend.title"}
      open={isOpen}
      close={() => close(false)}
      primaryButtonText={"common.send.link"}
      dataTestIdString={`${testId.button.dialogMessage}-activate-extend`}
      primaryButtonAction={action}
    >
      <FormattedTypography id={"education.dialog.activate-extend.caption1"} sx={{ marginBottom: "1rem" }} />
      <FormattedTypography id={"education.dialog.activate-extend.caption2"} />
    </DialogMessage>
  );
};