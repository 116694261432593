import { atom, selector, selectorFamily } from "recoil";
import { User, UserAvatar, UserBasicInfo } from "types/user";
import { API_URL_BO_ME } from "utils/constants";
import { instanceOfRequestError, localStorageEffect } from "./stateHelper";
import { get as httpGet } from "services/httpService";
import { RequestError } from "types/request";

export const userState = atom<User | undefined>({
  key: "user",
  default: undefined,
});

export const refreshUserStateBasicInfo = atom<number>({
  key: "refreshUserStateBasicInfo",
  default: 0,
});

export const userStateBasicInfo = selector<UserBasicInfo | RequestError | undefined>({
  key: "userStateBasicInfo",
  get: async ({ get }) => {
    get(refreshUserStateBasicInfo);
    const url = API_URL_BO_ME + "basic-info";
    const basicInfo: UserBasicInfo | RequestError = await httpGet(url);
    return basicInfo;
  },
});

export const userStateAvatarById = selectorFamily<UserAvatar | undefined, string>({
  key: "userAvatarById",
  get:
    (id: string) =>
    ({ get }) => {
      const list = get(userStateAvatarList);
      return list.find((avatar) => avatar.id === id);
    },
});

export const userStateAvatar = selector<UserAvatar | undefined>({
  key: "userAvatar",
  get: ({ get }) => {
    const basicInfo = get(userStateBasicInfo);
    if (!basicInfo || instanceOfRequestError(basicInfo)) return undefined;

    const avatar = get(userStateAvatarById(basicInfo.avatarId));

    return avatar;
  },
});

/**
 * This state should be when user is logged in and reset password
 */
export const passwordChangedSuccess = atom<boolean>({
  key: "passwordChangedSuccess",
  default: undefined,
  effects: [localStorageEffect("passwordChanged")],
});

export const userStateAvatarList = selector<UserAvatar[]>({
  key: "userAvatarList",
  get: ({ get }) => {
    //TODO: Api anrop för att hämta ut listan

    const avatars = generateAvatars();

    return avatars;
  },
});

const generateAvatars = () => {
  const array: UserAvatar[] = [];
  const imgPath = "/img/avatars/";

  for (let index = 1; index <= 40; index++) {
    const avatar: UserAvatar = {
      id: index.toString(),
      src: process.env.PUBLIC_URL + imgPath + `Avatar-${index}.jpg`,
    };
    array.push(avatar);
  }

  return array;
};
