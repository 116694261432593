import React, { useEffect } from "react";
import { Button, Divider, FormControl, Stack, Typography } from "@mui/material";
import { FormInput } from "utils/helpers/FormInput";
import { useAppIntl } from "services/useAppIntl";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { Title } from "utils/helpers/TitleDivider";
import { useNavigate } from "react-router-dom";
import { desktopWidth640 } from "theme/theme";
import useToggle from "hooks/useToggle";
import useFetchMe from "hooks/useFetchMe";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { User } from "types/user";
import { useTopNavigation } from "layout";
import { SubmitHandler, useForm, useFormState } from "react-hook-form";
import _ from "lodash";
import ProfileImage from "./profileImage";
import { UserFormSkeleton } from "utils/helpers/LoadingSkeletons";
import { FormInputPhoneNumber } from "layout/Components/Inputs/formInputPhoneNumber";
import { FormInputIdentityNumber } from "layout/Components/Inputs/formInputIdentityNumber";
import { FormInputLastName } from "layout/Components/Inputs/formInputLastName";
import { FormInputFirstName } from "layout/Components/Inputs/formInputFirstName";
import { FormInputContact } from "layout/Components/Inputs/formInputContact";
import { FormInputSecrecy } from "layout/Components/Inputs/formInputSecrecy";
import { useSetRecoilState } from "recoil";
import { refreshUserStateBasicInfo } from "state/userState";
import { useInfoMessage } from "hooks/useInfoMessage";
import { validationSchemaUserProfile } from "services/validationSchemaUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { IUpdateMeRequest } from "types/user";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";
import { FormInputChangePassword } from "layout/Components/Inputs/formInputChangePassword";
import { FormInputEmail } from "layout/Components/Inputs/formInputEmail";

export const Profile: React.FC = () => {
  const intl = useAppIntl();
  const navigate = useNavigate();
  const { setTopNavigate } = useTopNavigation();
  const [openSaveDialog, setOpenSaveDialog] = useToggle(false);
  const errorInfoMessage = useInfoMessage();
  const validationInfoMessage = useInfoMessage();
  const { json, isSaved, error, saveMe } = useFetchMe();
  const refreshBasicInfo = useSetRecoilState(refreshUserStateBasicInfo);
  const { setSnackbarValues, snackbarMessage } = useSnackbarMessage();
  const { control, handleSubmit, reset, getValues, setValue, formState } = useForm<User>({
    mode: "onChange",
    resolver: yupResolver(validationSchemaUserProfile),
  });
  const { isDirty, isValid, isSubmitted } = formState;
  const { errors } = useFormState({ control });
  const user = getValues();

  if (!isValid && errors) {
    console.log("formState is not valid", JSON.stringify(errors));
  }

  useEffect(() => {
    if (json) {
      if (json.avatarId === null) json.avatarId = "";

      reset(json);
    }
  }, [json, reset]);

  useEffect(() => {
    if (isSaved) {
      setSnackbarValues({ message: intl.formatMessage({ id: "common.save.message" }), type: "success" });
      errorInfoMessage.setInfoMessageValues(undefined);
      reset(user);
      refreshBasicInfo((n: number) => n + 1);
      return;
    }
    if (error) {
      console.log(`Error on save profile: ${JSON.stringify(error.errors)} `);
      errorInfoMessage.setInfoMessageValues({ message: "common.error.http.request.failed", type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved, error]);

  useEffect(() => {
    if (!isValid && isSubmitted) {
      validationInfoMessage.setInfoMessageValues({
        message: "profile.helpertext.placeholdertext.errormessage",
        type: "error",
      });
    } else {
      validationInfoMessage.setInfoMessageValues(undefined);
    }
  }, [isValid, isSubmitted, validationInfoMessage]);

  // Attach cancel function to HeaderNavigation when form is dirty
  useEffect(() => {
    setTopNavigate({ function: isDirty ? handleCancel : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const onSetAvatarId = (avatarId: string) => {
    setValue("avatarId", avatarId, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<User> = (user: User) => {
    if (!isValid) return;

    const payload: IUpdateMeRequest = {
      firstName: user.firstName,
      avatarId: user.avatarId,
      identityNumber: user.identityNumber,
      lastName: user.lastName,
      phoneNumber: !user.phoneNumber?.length ? null : user.phoneNumber,
      preferredCommunication: user.preferredCommunication,
    };
    saveMe(payload);

    if (openSaveDialog) setOpenSaveDialog(false);
  };

  const handleCancel = () => {
    if (isDirty) return setOpenSaveDialog(true);

    navigate(-1);
  };

  return (
    <>
      <BackgroundShape top={-320} left="-15%">
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="1000" height="600" color={svgColor.pink} />
      </BackgroundShape>

      {!user || _.isEmpty(user) ? (
        <UserFormSkeleton />
      ) : (
        <form>
          <FormInput type="hidden" name="avatarId" defaultValue={user.avatarId} control={control} />
          <FormControl
            fullWidth
            sx={{ display: "flex", maxWidth: desktopWidth640, marginLeft: "auto", marginRight: "auto" }}
          >
            <Stack spacing={4}>
              <Title title={intl.formatMessage({ id: "more.title.profile" })} />
              <ProfileImage avatarId={user.avatarId} onSetAvatarId={onSetAvatarId} />

              <Typography variant="subtitle2" mb={3}>
                <FormattedMessage id="profile.label.customer-number" />: {user && user.customerNumber}
              </Typography>

              <Typography variant="subtitle1">
                <FormattedMessage id="profile.label.contact.information" />
              </Typography>

              <FormInputEmail control={control} />
              <FormInputChangePassword />
              <FormInputPhoneNumber control={control} />
              <FormInputContact control={control} />
            </Stack>

            <Divider />

            <Stack spacing={4}>
              <Typography variant="subtitle1">
                <FormattedMessage id="profile.label.personal-data" />
              </Typography>
              <FormInputFirstName control={control} />
              <FormInputLastName control={control} />
              <FormInputIdentityNumber disabled control={control} />
            </Stack>

            <Divider />

            <FormInputSecrecy />

            <Stack spacing={2}>
              {validationInfoMessage.infoMessage}
              {errorInfoMessage.infoMessage}
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                data-testid={`${testId.button.profile}-save`}
                disabled={!isDirty}
              >
                <FormattedMessage id="common.save" />
              </Button>
              <Button
                variant="outlined"
                data-testid={`${testId.button.profile}-${testId.common.cancel}`}
                onClick={handleCancel}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
            </Stack>
          </FormControl>
        </form>
      )}

      {/* Save dialog */}
      <DialogMessage
        title={"common.save"}
        open={openSaveDialog}
        close={() => setOpenSaveDialog()}
        primaryButtonText={"common.save"}
        primaryButtonAction={handleSubmit(onSubmit)}
        secondaryButtonText={"common.save-skip"}
        secondaryButtonAction={() => navigate(-1)}
        dataTestIdString={testId.listItem.profile.unsavedChanges}
      >
        <Typography variant="body1">
          <FormattedMessage id={"common.save.changes.question"} />
        </Typography>
      </DialogMessage>
      {snackbarMessage}
    </>
  );
};
