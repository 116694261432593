import {
  iconMotorCycle,
  iconMoped,
  iconTruck,
  iconBus,
  iconTaxi,
  iconTractor,
  iconCar,
  iconVan,
  IconProp,
  iconTruckHeavy,
} from "theme/icons";

export const educationIconKeys = {
  car: "bil",
  mc: "mc",
  moped: "moped",
  bus: "buss",
  taxi: "taxi",
  tractor: "tractor",
  van: "mellanstor buss",
  heavyTruck: "tung lastbil",
  midHeavyTryck: "medeltung lastbil",
  carWithHeavyTrailer: "bil med tungt släp",
  midHeavyTruckWithTrailer: "medeltung lastbil med släp",
  heavyTruckWithTrailer: "tung lastbil med släp",
  midBigBusWithTrailer: "mellanstor buss med släp",
  busWithTrailer: "buss med släp",
  ykbBus: "ykb buss",
  ykbTruck: "ykb lastbil",
};

export const getEducationIcon = (educationName: string) => {
  const education = educationName.toLowerCase();
  const eIK = educationIconKeys;

  switch (education) {
    case eIK.car:
      return iconCar;
    case eIK.mc:
      return iconMotorCycle;
    case eIK.moped:
      return iconMoped;
    case eIK.bus:
    case eIK.ykbBus:
      return iconBus;
    case eIK.taxi:
      return iconTaxi;
    case eIK.tractor:
      return iconTractor;
    case eIK.van:
      return iconVan;
    case eIK.heavyTruck:
      return iconTruck;
    case eIK.midHeavyTryck:
      return iconTruck;
    case eIK.ykbTruck:
      return iconTruckHeavy;
    case eIK.carWithHeavyTrailer:
      return eIK.carWithHeavyTrailer as IconProp;
    case eIK.midHeavyTruckWithTrailer:
    case eIK.heavyTruckWithTrailer:
      return eIK.heavyTruckWithTrailer as IconProp;
    case eIK.midBigBusWithTrailer:
      return eIK.midBigBusWithTrailer as IconProp;
    case eIK.busWithTrailer:
      return eIK.busWithTrailer as IconProp;
    default:
      return iconCar;
  }
};
