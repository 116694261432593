import { Box } from "@mui/material";
import useToggle from "hooks/useToggle";
import React, { ReactNode } from "react";
interface IListToggleProps {
  style?: {};
  children?: ReactNode;
  onChange: (status: boolean) => void;
}

export const FormToggle: React.FC<IListToggleProps> = (props) => {
  const [status, setStatus] = useToggle(false);
  React.useEffect(() => {
    props.onChange(status);
  }, [props, status]);

  return (
    <Box onClick={() => setStatus()} sx={props.style}>
      {props.children}
    </Box>
  );
};
