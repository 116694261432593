import { Avatar, Button, List, Stack, Typography, Theme } from "@mui/material";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { XapiKey } from "services/lrsService";
import { testId } from "tests/testIdStrings";
import { Icon, iconCircleCheck, iconCircleExclamation, iconCircleXmarkSolid } from "theme/icons";
import { styleQuestionNumber } from "theme/styles";
import { DialogMessage } from "utils/helpers/DialogMessage";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { ListItemButton } from "utils/helpers/ListItemButton";
import { IOverviewQuestion, OverviewQuestionStatus } from "utils/helpers/theory/QuestionOverview";
import { Overview } from "./overview";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRecoilValue } from "recoil";
import { testResultState } from "state/progresState";

export type NavigateTestState = {
  hideResult?: boolean;
  redirectTo?: string;
  startAssessment?: boolean;
  resetTest?: boolean;
  question?: {
    number: number;
    id: ReferenceKey;
  };
};

export interface IResultOverview {
  xapiKey?: XapiKey;
  isAnswer?: boolean;
  assessmentId?: string;
}

export const TestResult = ({ assessmentId, xapiKey, isAnswer }: IResultOverview) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirm, setConfirm] = useState(false);
  const [chapterOverview, setChapterOverview] = useState(false);
  const routes = useRouteConfiguration();
  const questions = useRecoilValue(testResultState(assessmentId));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleClose = () => {
    setConfirm(true);
  };

  const handleMoreQuestions = async () => {
    const navigateState: NavigateTestState = { redirectTo: location.pathname };
    navigate("/resetTest", { state: navigateState });
  };

  const handleConfirm = async () => {
    if (isAnswer) setChapterOverview(true);
    else navigate(routes.test.path);
  };

  const handleOpenQuestion = (number: number, id: ReferenceKey) => {
    const navigateState: NavigateTestState = { question: { id, number }, hideResult: true };
    navigate(location.pathname, { state: navigateState });
  };

  if (!questions) return null;
  if (chapterOverview) return <Overview xapiKey={xapiKey} isAnswer={isAnswer} />;

  return (
    <>
      <FormattedTypography variant="h1" id={"common.result-overview"} mb={2} />

      <Typography variant="subtitle1" mb={2}>
        {questions.filter((question) => question.status === OverviewQuestionStatus.Correct).length}/{questions.length}
      </Typography>

      <List component={"nav"} sx={{ mx: -2, pb: "3rem" }}>
        {questions?.map((item, index) => {
          let endIcon;
          if (item.status === OverviewQuestionStatus.None)
            endIcon = <Icon name={iconCircleExclamation} color={"disabled"} />;
          if (item.status === OverviewQuestionStatus.Correct)
            endIcon = <Icon name={iconCircleCheck} color={"success"} />;
          if (item.status === OverviewQuestionStatus.Incorrect)
            endIcon = <Icon name={iconCircleXmarkSolid} color={"error"} />;

          return (
            <ListItemButton
              key={item.id.contentId}
              startIcon={<Avatar sx={styleQuestionNumber}>{index + 1}</Avatar>}
              primaryText={item.text}
              onClick={() => handleOpenQuestion(item.number, item.id!)}
              endIcon={endIcon}
              dataTestIdString={`${testId.listItem.question.item}.${index + 1}`}
            />
          );
        })}
      </List>

      <Stack
        spacing={2}
        sx={{ position: isMobile ? "fixed" : "relative", bottom: 0, left: 0, right: 0, backgroundColor: "white", p: 2 }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{ px: 2 }}
          data-testid={`${testId.button.testPrefix}.${testId.common.close}`}
          onClick={handleClose}
        >
          <FormattedMessage id="common.close" />
        </Button>

        {isAnswer && (
          <Button
            variant="outlined"
            fullWidth
            sx={{ px: 2 }}
            data-testid={`${testId.button.testPrefix}.${testId.button.moreQuestions}`}
            onClick={handleMoreQuestions}
          >
            <FormattedMessage id="question.more-questions" />
          </Button>
        )}
      </Stack>

      <ConfirmDialog open={confirm} close={() => setConfirm(false)} confirm={handleConfirm} />
    </>
  );
};

interface IConfirmDialog {
  open: boolean;
  close: () => void;
  confirm: () => void;
}

export const ConfirmDialog = ({ open, close, confirm }: IConfirmDialog) => {
  const handleClose = () => {
    close();
  };

  const handleConfirm = () => {
    close();
    confirm();
  };

  return (
    <DialogMessage
      title="question.close-result"
      open={open}
      close={handleClose}
      primaryButtonText="question.close-result"
      primaryButtonAction={() => handleConfirm()}
      dataTestIdString={testId.common.close}
    >
      <FormattedTypography id="question.close-result.text"></FormattedTypography>
    </DialogMessage>
  );
};

// TEMP component to easily restart a test. TODO a better solution when we get the time.
export const ResetTest = () => {
  const location = useLocation();

  if (!location.state.redirectTo) throw new Error("No redirect url to navigate to");

  const navigateState: NavigateTestState = { startAssessment: true, resetTest: true };
  return <Navigate replace to={location.state.redirectTo} state={navigateState} />;
};

export function generateOverview(overviewQuestions: any) {
  let questions: IOverviewQuestion[] = [];
  let number = 1;

  overviewQuestions.forEach((overviewQuestion: any) => {
    let status: OverviewQuestionStatus = OverviewQuestionStatus.None;
    if (overviewQuestion.result) {
      if (overviewQuestion.result.response) status = OverviewQuestionStatus.Answered;

      if (overviewQuestion.result.completion)
        status = overviewQuestion.result.success ? OverviewQuestionStatus.Correct : OverviewQuestionStatus.Incorrect;
    }

    const question: IOverviewQuestion = {
      id: overviewQuestion.referenceKey,
      text: overviewQuestion.title,
      number: number,
      status: status,
    };
    questions.push(question);
    number++;
  });

  return questions;
}
