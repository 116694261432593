import { useEffect } from "react";
import { Location } from "react-router-dom";

interface IScrollHandler {
  selector: string;
  delay?: number;
  offsetTop?: number;
}

/**
 * Scrolls to an element on the page based on the location hash in the url.
 * Example: navigating to the url http://mysite.com/mypage#elementId will scroll to an element on the page with the id 'elementId'
 * @param location react-router location object
 */
const ScrollHandler = (props: IScrollHandler) => {
  useEffect(() => {
    scrollToElement(props);
  }, [props]);

  return null;
};

/**
 * Scrolls to an element on the page
 * @param selector html element query selector
 * @param delay scroll delay
 * @param offsetTop element scroll top offset
 */
export const scrollToElement = (props: IScrollHandler) => {
  const { selector, delay, offsetTop } = props;

  setTimeout(
    () => {
      const element: HTMLElement | null = selector === "" ? null : document.querySelector(selector);

      window.scrollTo({
        behavior: "smooth",
        top: element ? (offsetTop ? element.offsetTop + offsetTop : element.offsetTop) : 0,
      });
    },
    delay ? delay : 250
  );
};

interface ILocationScrollHandler {
  location: Location;
  delay?: number;
  offsetTop?: number;
}
/**
 * Scrolls to an element on the page based on the location hash in the url. If no hash exist, then will scroll to page top.
 * Example: navigating to the url http://mysite.com/mypage#elementId will scroll to an element on the page with the id 'elementId'
 * @param location react-router location object
 * @param delay scroll delay
 * @param offsetTop element scroll top offset
 */
export const scrollToElementByLocation = (props: ILocationScrollHandler) => {
  const { location, delay, offsetTop } = props;
  const selector = `[id='${location.hash.substring(1)}']`;

  scrollToElement({ selector, delay, offsetTop });
};

export default ScrollHandler;
