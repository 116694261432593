import { FC, useState } from "react";
import { testId } from "tests/testIdStrings";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormInputValueCode } from "layout/Components/Inputs/formInputValueCode";
import { useInfoMessage } from "hooks/useInfoMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Stack } from "@mui/material";
import { post } from "services/httpService";
import { API_URL_BO_VOUCHER } from "utils/constants";

interface IValueCodeDialogProps {
  isOpen: boolean;
  close: () => void;
  onActivated?: () => void;
}

export const ValueCodeDialog: FC<IValueCodeDialogProps> = ({ isOpen, close, onActivated }) => {
  const { infoMessage, setInfoMessageValues } = useInfoMessage();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  async function activate() {
    setLoading(true);

    try {
      const response = await post(`${API_URL_BO_VOUCHER}${code}/activate`, {}, "PATCH");
      if (response.status >= 400) throw new Error(response.errors.message);

      if (onActivated) onActivated();
      close();
    } catch (error) {
      console.error(error);
      setInfoMessageValues({ message: "education.value-code.activate.error", type: "error" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <DialogMessage
      title={"education.value-code.activate.title"}
      open={isOpen}
      close={close}
      primaryButtonText={"education.value-code.activate.button"}
      primaryButtonAction={() => activate()}
      primaryButtonLoader={loading}
      dataTestIdString={`${testId.button.dialogMessage}-promotional`}
    >
      {infoMessage}
      <Stack spacing={4}>
        <FormattedTypography variant="body1" id="education.value-code.activate.body" />
        <FormInputValueCode valueCode={code} onSetCode={(c: string) => setCode(c)} />
      </Stack>
    </DialogMessage>
  );
};
