import { Box } from "@mui/material";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { useAppContext } from "context/AppContext";
import { TextToSpeech } from "@strmediaochitab/optima-component-library";
import { FormattedTypography } from "../FormattedTypography";
import { useAppIntl } from "services/useAppIntl";
const img = process.env.PUBLIC_URL + "/img/illustrations/woman-thinking.svg";

interface IIntroContent {
  numberOfQuestions: number;
  title: string;
}

export const IntroContent = ({ numberOfQuestions, title }: IIntroContent) => {
  const textToSpeech = useTextToSpeech();
  const { soundSupport } = useAppContext();
  const intl = useAppIntl();

  return (
    <>
      <Box textAlign="center" mt={4} mb={5}>
        <img src={img} alt="Woman thinking" />
      </Box>
      <FormattedTypography
        variant="bodyStrong"
        id={"theory.answer.intro.ingress"}
        values={{ numberOfQuestions, title }}
      >
        {soundSupport && (
          <TextToSpeech
            text={intl.formatMessage({ id: "theory.answer.intro.ingress" }, { values: { numberOfQuestions, title } })}
            player={textToSpeech}
          />
        )}
      </FormattedTypography>
      <FormattedTypography variant="body1" id="theory.answer.intro.body">
        {soundSupport && (
          <TextToSpeech text={intl.formatMessage({ id: "theory.answer.intro.body" })} player={textToSpeech} />
        )}
      </FormattedTypography>
    </>
  );
};
