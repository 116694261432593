import React from "react";
import { Box } from "@mui/material";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type Props = {};

export const NotFound: React.FC<Props> = (props) => {
  return (
    <Box display="flex" height="100%">
      <FormattedTypography id="common.page.not-found" />
    </Box>
  );
};
