import { Box, IconButton, SvgIconTypeMap, SxProps, Theme } from "@mui/material";
import { BookmarkDialog } from "layout/more/bookmark/bookmarkDialog";
import React, { useState } from "react";
import { DataTestType } from "tests/test.setup";
import { Icon, iconBookmark, iconBookmarkSolid, iconStar } from "theme/icons";
import { optimaColors } from "theme/optima";
import { bookmarksStateSelector } from "state/bookmarkState";
import { useRecoilValue } from "recoil";
import { Activity } from "types/cds";
import { EditBookmarkDialog } from "layout/more/bookmark/editBookmarkDialog";
import { EducationContentTypes } from "state/learningStructureState";

interface ILearningObjecActions {
  children: React.ReactNode;
  justifyContent?: string;
  sx?: SxProps<Theme> | undefined;
}

export const LearningObjecActions = (props: ILearningObjecActions) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={props.justifyContent ? props.justifyContent : "space-between"}
      sx={props.sx}
    >
      {props.children}
    </Box>
  );
};

interface ILearningObjectListen extends DataTestType {}

export const LearningObjectListen = (props: ILearningObjectListen) => {
  // const handleClick = () => {
  //   console.log("Listen...");
  // };

  return null;

  /* Hide for now
  return (
    <IconButton onClick={handleClick} color="info" data-testid={`${props.dataTestIdString}`}>
      <Icon name={iconVolume} htmlColor={optimaColors.tertiary05}></Icon>
    </IconButton>
  );
  */
};

interface ILearningObjectBookmark extends DataTestType {
  activity: Activity;
  activityType?: EducationContentTypes;
}

export const LearningObjectBookmark = ({ dataTestIdString, activity, activityType }: ILearningObjectBookmark) => {
  const bookMarksList = useRecoilValue(bookmarksStateSelector);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const isBookmarked = bookMarksList?.find(
    (bookmark) => bookmark.key.contentId === activity.key.contentId && bookmark.type === activityType
  );

  const handleClick = () => {
    isBookmarked ? setShowEditDialog(true) : setShowAddDialog(true);
  };

  if (!activityType) return null;

  return (
    <>
      <IconButton onClick={handleClick} data-testid={`${dataTestIdString}`}>
        <Icon
          name={showAddDialog || isBookmarked ? iconBookmarkSolid : iconBookmark}
          color={isBookmarked ? (isBookmarked.color as SvgIconTypeMap["props"]["color"]) : "info"}
          htmlColor={optimaColors.tertiary05}
        ></Icon>
      </IconButton>
      {isBookmarked && (
        <EditBookmarkDialog
          open={showEditDialog}
          close={() => setShowEditDialog(false)}
          selectedBookmark={isBookmarked}
        />
      )}
      {showAddDialog && !isBookmarked && (
        <BookmarkDialog
          open={showAddDialog}
          close={() => setShowAddDialog(false)}
          activity={activity}
          type={activityType}
        />
      )}
    </>
  );
};

interface ILearningObjectStar extends DataTestType {}

export const LearningObjectStar = (props: ILearningObjectStar) => {
  const handleClick = () => {
    console.log("Star...");
  };

  return (
    <IconButton onClick={handleClick} color="info" data-testid={`${props.dataTestIdString}`}>
      <Icon name={iconStar} htmlColor={optimaColors.tertiary05}></Icon>
    </IconButton>
  );
};
