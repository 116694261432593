import { useEffect, useState } from "react";

let timeout: any;

/**
 * Countdown hook
 * @param seconds in seconds (so 5 minutes is 5 * 60 = 300)
 * @returns
 */
const useCountdown = (seconds: number) => {
  const [value, setValue] = useState<number>(seconds);

  const sec = value % 60;
  const min = Math.floor(value / 60);
  const progress = (value / seconds) * 100;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (value > 0) {
      timeout = setTimeout(() => {
        setValue((state) => state - 1);
      }, 1000);
    } else {
      clearTimeout(timeout);
    }
  }, [value]);

  return [min, sec, progress];
};

export default useCountdown;
