import { Box, FormGroup, List, ListItem, ListItemIcon, ListItemText, Radio, Theme, useMediaQuery } from "@mui/material";
import { cardContent, cardContentAlignLeft } from "theme/styles";
import { useAppContext } from "context/AppContext";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { Title } from "utils/helpers/TitleDivider";
import { useAppIntl } from "services/useAppIntl";
import { SupportedLanguages } from "services/localizationService";
import SvgImage, { ellipse4 as svgTop, ellipse3 as svgBottom, svgColor } from "utils/helpers/SvgImage";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { NotFound } from "layout/notFound";

const flagSwedish = process.env.PUBLIC_URL + "/img/languages/swedish.svg";
const flagEnglish = process.env.PUBLIC_URL + "/img/languages/english.svg";

export const Language = () => {
  const { activeLanguage, setActiveLanguage } = useAppContext();
  const intl = useAppIntl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const useLanguage = useFeatureFlag("Language");

  // TODO: Memoize?
  const myLanguages: { id: string; icon: string; language: SupportedLanguages | "system"; label: string }[] = [
    {
      id: "-1",
      icon: flagSwedish, // TODO: Denna ska styras av vald utbildning
      language: "system",
      label: intl.formatMessage({ id: "language.system" }),
    },
    {
      id: "0",
      icon: flagSwedish,
      language: "sv",
      label: intl.formatMessage({ id: "language.sv" }),
    },
    {
      id: "1",
      icon: flagEnglish,
      language: "en",
      label: intl.formatMessage({ id: "language.en" }),
    },
  ];

  if (!useLanguage) return <NotFound />;

  return (
    <>
      <BackgroundShape top={-280} right={-290}>
        <SvgImage
          d={svgTop.d}
          viewBox={svgTop.viewBox}
          width="530"
          height="360"
          color={svgColor.pink}
          rotation={-420}
        />
      </BackgroundShape>

      <Title title={intl.formatMessage({ id: "more.title.language" })}></Title>

      <List sx={isMobile ? { ml: -2, mr: -2 } : null}>
        {myLanguages.map((item) => (
          <ListItem key={item.id} divider sx={{ borderRadius: 0 }}>
            <RadioButtonOption
              value={item.language}
              label={item.label}
              icon={item.icon}
              activeLanguage={activeLanguage}
              onChange={(lang: string) => {
                setActiveLanguage(lang as SupportedLanguages);
              }}
            />
          </ListItem>
        ))}
      </List>

      <BackgroundShape bottom={-260} left={-250}>
        <SvgImage
          d={svgBottom.d}
          viewBox={svgBottom.viewBox}
          width="530"
          height="360"
          color={svgColor.turquoise}
          rotation={-3}
        />
      </BackgroundShape>
    </>
  );
};

type IRadioButtonOptionProps = {
  value: string;
  icon: string;
  label?: string;
  activeLanguage: string;
  onChange: (lang: string) => void;
};
const RadioButtonOption = (props: IRadioButtonOptionProps) => {
  return (
    <FormGroup sx={{ width: "100%" }} onClick={() => props.onChange(props.value)}>
      <Box sx={cardContent}>
        <Box sx={cardContentAlignLeft}>
          <ListItemIcon>
            <img src={props.icon} alt="Illustration background" />
          </ListItemIcon>
          <Box>
            <ListItemText primary={props.label} />
          </Box>
        </Box>
        <Box>
          <Radio
            checked={props.activeLanguage === props.value}
            // onChange={props.onChange}
            value={props.value}
            name="radio-buttons"
          />
        </Box>
      </Box>
    </FormGroup>
  );
};
