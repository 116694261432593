import { Box, ListItemText, List, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, SizeProp, iconArrowLeft, iconTrashcan } from "theme/icons";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";

interface ITopNavigationDetailsViewProps {
  iconSize: SizeProp;
  topTitle: string;
  title: string;
  hasIcons?: boolean;
  to: string;
  onClick?: () => void;
}
export const TopNavigationDetailsView: React.FC<ITopNavigationDetailsViewProps> = (props) => {
  return (
    <Stack>
      <List sx={{ width: "100%" }}>
        <ListItem sx={[styleFlex, styleFlexJustifyContentBetween]}>
          <Box sx={[styleFlex, styleFlexAlignItemsCenter, { height: "2rem" }]}>
            <Link to={props.to}>{<Icon name={iconArrowLeft} size={props.iconSize} />}</Link>
            <Box sx={{ margin: " 0 2rem" }}>
              <ListItemText
                disableTypography
                primary={<Typography variant="caption">{props.topTitle}</Typography>}
                secondary={<Typography variant="h6">{props.title}</Typography>}
              />
            </Box>
          </Box>
          {props.hasIcons && (
            <Link to={props.to} onClick={props.onClick}>
              <Icon name={iconTrashcan} size={props.iconSize} />
            </Link>
          )}
        </ListItem>
      </List>
    </Stack>
  );
};
