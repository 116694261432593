import { useMsal } from "@azure/msal-react";
import { FormControl, FormHelperText } from "@mui/material";
import { b2cPolicies } from "authConfig";
import useLocalStorage from "hooks/useLocalStorage";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { optimaColors } from "theme/optima";
import { FormInput } from "utils/helpers/FormInput";

export const FormInputChangePassword: React.FC = () => {
  const intl = useAppIntl();
  const { instance } = useMsal();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lsChangePassword, setLsChangePassword] = useLocalStorage("profileChangePassword");

  const handleChangePassword = () => {
    setLsChangePassword(true);
    instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
  };

  return (
    <FormControl>
      <FormInput
        disabled
        name="password"
        label={intl.formatMessage({ id: "profile.label.password" })}
        type={"password"}
        defaultValue={"***************"}
        dataTestIdString={`${testId.input.profile}-password`}
      />
      <FormHelperText variant="filled">
        <Link
          style={{ color: optimaColors.primary07, textDecoration: "none" }}
          to={""}
          onClick={handleChangePassword}
          data-testid={testId.link.profile.forgotPassword}
        >
          <FormattedMessage id="link.change.password" />
        </Link>
      </FormHelperText>
    </FormControl>
  );
};
