import { AlertColor, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { appMessageState } from "state/recoilAtoms";
import { SnackbarMessage } from "utils/helpers/SnackbarMessage";

export interface IUseSnackbarMessageResponse {
  snackbarMessage: JSX.Element | undefined;
  setSnackbarValues: (message: Message | undefined) => void;
}

export type Message = { message: string; type: AlertColor; customSx?: SxProps<Theme> };

/**
 * Custom hook for managing snackbar messages.
 * @returns An object containing the current snackbar message and a function to set new snackbar values.
 */
export const useSnackbarMessage = (): IUseSnackbarMessageResponse => {
  const [snackbarMessage, setSnackbarMessage] = useState<JSX.Element | undefined>(undefined);

  const setSnackbarValues = (message: Message | undefined) => {
    if (!message) return setSnackbarMessage(undefined);

    const props = {
      type: message.type,
      message: message.message,
      sx: message.customSx,
      onClose: () => setSnackbarMessage(undefined),
      show: true,
    };
    const element = SnackbarMessage(props);
    setSnackbarMessage(element);
  };

  return { snackbarMessage, setSnackbarValues };
};

/**
 * Custom hook for managing global snackbar messages.
 * @returns An object containing the current snackbar message and a function to set new snackbar values.
 */
export const useGlobalSnackbarMessage = (): IUseSnackbarMessageResponse => {
  const [snackbarMessage, setSnackbarMessage] = useRecoilState(appMessageState);

  const setSnackbarValues = (message: Message | undefined) => {
    if (!message) return setSnackbarMessage(undefined);

    const props = {
      type: message.type,
      message: message.message,
      sx: message.customSx,
      onClose: () => setSnackbarMessage(undefined),
      show: true,
    };
    const element = SnackbarMessage(props);
    setSnackbarMessage(element);
  };

  return { snackbarMessage, setSnackbarValues };
};
