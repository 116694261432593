import { Control } from "react-hook-form";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { FormInput } from "utils/helpers/FormInput";

interface IFormInputFirstNameProps {
  control: Control<any, any> | undefined;
}

export const FormInputFirstName = ({ control }: IFormInputFirstNameProps) => {
  const intl = useAppIntl();

  return (
    <FormInput
      name="firstName"
      label={intl.formatMessage({ id: "profile.label.first-name" })}
      dataTestIdString={`${testId.input.profile}-firstname`}
      control={control}
    />
  );
};
