import React, { useCallback } from "react";
import { Stack } from "@mui/system";
import { testId } from "tests/testIdStrings";
import { useAppIntl } from "services/useAppIntl";
import { iconArrowCircleRight, iconSettings } from "theme/icons";
import { getEducationIcon } from "utils/helpers/GetEducationIcon";
import { UserEducation, EducationStateEnum } from "types/education";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useNavigate } from "react-router-dom";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { IntlMessageKeys } from "services/useAppIntl";
import { Typography, Box, useTheme } from "@mui/material";
import { useDateService } from "hooks/useDateService";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEducation } from "hooks/useEducation";

export const EducationHandler: React.FC<{ educations: UserEducation[] }> = ({ educations }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery("(max-width:375px)");
  const intl = useAppIntl();
  const { getDateAsLocalDateString: getDateAsString } = useDateService();
  const navigate = useNavigate();
  const routes = useRouteConfiguration();
  const { setEducation } = useEducation();

  const handleSelectedEducationItem = (id: string) => {
    navigate(`${routes.education.path}/${id}`);
  };

  const setCurrentActiveEducation = (education: UserEducation) => {
    setEducation(education);
    navigate(`${routes.home.path}`);
  };

  const getListItem = useCallback((education: UserEducation) => {
    const isActive = education.educationState === EducationStateEnum.Active;
    const validUntil = getDateAsString(education.validUntil);
    const handleOnClick = () => {
      return isActive ? setCurrentActiveEducation(education) : handleSelectedEducationItem(education.identifier);
    };

    const getTitleText = () => {
      return isSmallDevice && education.name.length > 9 ? education.name.substring(0, 10) + "..." : education.name;
    };

    const getNavigationText = () => {
      const textId: IntlMessageKeys = isActive ? "education.general.go.to" : "education.general.manage";
      const truncate = isSmallDevice && !isActive ? "education.general.manage.short" : textId;
      return getText(intl.formatMessage({ id: truncate }));
    };

    const getSubText = () => {
      const textId: IntlMessageKeys = isActive
        ? "education.general.card-info.active.to.subtitle"
        : "education.active.since";
      const validDate = isSmallDevice ? validUntil.substring(0, validUntil.length - 4) + "..." : validUntil;
      return getText(`${intl.formatMessage({ id: textId })} ${validDate}`);
    };

    const getText = (text: string) => <Typography variant="breadcrumbs">{text}</Typography>;

    return (
      <Box display={"flex"} flexDirection={"column"} key={education.identifier}>
        <NavigationItem
          key={education.identifier}
          size="large"
          text={getTitleText()}
          subText={isActive ? getSubText() : ""}
          icon={getEducationIcon(education.name)}
          navigationText={getNavigationText()}
          navigationIcon={isActive ? iconArrowCircleRight : iconSettings}
          onClick={handleOnClick}
          iconSize="medium"
          color={theme.palette.primary.main}
          dataTestIdString={`${testId.listItem.education.educationNavigationItem}-${education.identifier
            .match(/([^-]+$)/g)
            ?.toString()}`}
        />
        {isActive && (
          <Box alignSelf={"flex-end"} marginRight={1} color={theme.palette.primary.dark07} sx={{ cursor: "pointer" }}>
            <Typography
              variant={"captionImage"}
              onClick={() => handleSelectedEducationItem(education.identifier)}
              data-testid={`${testId.listItem.education.educationNavigationHandle}-${education.identifier
                .match(/([^-]+$)/g)
                ?.toString()}}`}
            >
              {getText(intl.formatMessage({ id: "education.general.manage" }))}
            </Typography>
          </Box>
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterEducations = useCallback(
    (educationState: EducationStateEnum) => {
      const filtered = educations.filter((x) => x.educationState.toLowerCase() === educationState.toLowerCase());
      if (filtered.length < 1) return null;
      return (
        <>
          <FormattedTypography
            variant="subtitle2"
            id={`education.${educationState.toLowerCase()}` as IntlMessageKeys}
          />
          {filtered.map((x) => getListItem(x))}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [educations]
  );

  return (
    <Stack spacing={3}>
      <>
        {filterEducations(EducationStateEnum.Active)}
        {filterEducations(EducationStateEnum.Paused)}
        {filterEducations(EducationStateEnum.Completed)}
      </>
    </Stack>
  );
};
