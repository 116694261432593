import { Stack, Typography, Box, useTheme } from "@mui/material";
import { testId } from "tests/testIdStrings";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppContext } from "context/AppContext";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { useAppIntl } from "services/useAppIntl";
import { TextToSpeech } from "@strmediaochitab/optima-component-library";
import FormattedMessage from "../FormattedMessage";
const img = process.env.PUBLIC_URL + "/img/illustrations/person-roller-blue.svg";

interface INoContent {
  heading?: string;
}

export const NoContent = ({ heading }: INoContent) => {
  const textToSpeech = useTextToSpeech();
  const { soundSupport } = useAppContext();
  const intl = useAppIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={!isMobile ? { paddingTop: 4, paddingLeft: 2, paddingBottom: 6, boxShadow: theme.shadows[2] } : null}>
      {heading && (
        <Typography color={theme.palette.text.primary} variant="h1">
          {heading}
          {soundSupport && <TextToSpeech text={heading} player={textToSpeech} />}
        </Typography>
      )}
      <Stack alignItems="center" mt={4} spacing={5} data-testid={testId.common.noContent}>
        <img src={img} alt="Woman thinking" />

        <Typography color={theme.palette.text.primary} variant="body1">
          <FormattedMessage id="theory.material.activity.no-content" />
          {soundSupport && (
            <TextToSpeech
              text={intl.formatMessage({ id: "theory.material.activity.no-content" })}
              player={textToSpeech}
            />
          )}
        </Typography>
      </Stack>
    </Box>
  );
};
