import React, { useEffect } from "react";
import { Theme, useMediaQuery, Stack } from "@mui/material";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { iconArrowCircleRight } from "theme/icons";
import { TreeNode } from "types/cds";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  theoryState,
  theoryAreaState,
  theoryChapterState,
  theoryStateSelected,
  theoryStateAreas,
} from "state/theoryState";
import { useNavigate } from "react-router-dom";
import { learningStructureState } from "state/learningStructureState";
import { testId } from "tests/testIdStrings";
import useChapterActivities from "hooks/useChapterActivities";

interface TheoryAreaProps {}

export const TheoryAreas: React.FC<TheoryAreaProps> = (props) => {
  const navigate = useNavigate();
  // Hämtar elevens utbildningsstruktur från CDS
  const structure = useRecoilValue(learningStructureState);
  const { setChapterActivities } = useChapterActivities();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // Setter för att spara ner theoryState
  const setTheoryState = useSetRecoilState(theoryState);
  const setChapterState = useSetRecoilState(theoryChapterState);
  const setAreaState = useSetRecoilState(theoryAreaState);

  // Hämtar ut alla noder på första nivån från theoryState
  const areas = useRecoilValue(theoryStateAreas);

  // Läser/Sätter vald nod på theoryStateSelected
  const setTheorySelected = useSetRecoilState(theoryStateSelected);

  // Sätter hela strukturen i theoryState
  useEffect(() => {
    if (structure) setTheoryState(structure);
  }, [structure, setTheoryState, setChapterState]);

  // Sätter vald item i state
  const handleSelectedItem = (item: TreeNode) => {
    let path = item.id.toString();
    setTheorySelected(item);
    setAreaState(item);

    // Navigate to read directly
    if (!isMobile) {
      const chapters = structure!.filter((activity) => activity.parentId === item.id);
      const firstChapter = chapters[0];
      setChapterState(firstChapter);
      setChapterActivities(firstChapter);

      path = item.id + "/" + firstChapter.id + "/read";
    }

    navigate(path);
  };

  if (!structure) return null;
  if (!structure.length) throw new Error("The education has no content");

  return (
    <Stack spacing={3}>
      {areas &&
        areas.map((item: TreeNode, index: number) => {
          return (
            <NavigationItem
              size="large"
              key={item.id}
              text={item.title}
              icon={item.icon}
              navigationIcon={iconArrowCircleRight}
              onClick={() => handleSelectedItem(item)}
              // progressValue={50}
              dataTestIdString={`${testId.listItem.theory.areas}-${index + 1}`}
            ></NavigationItem>
          );
        })}
    </Stack>
  );
};
