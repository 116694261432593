import { Snackbar, Alert, Button, SxProps, Theme, AlertColor } from "@mui/material";
import { ReactElement } from "react";
import FormattedMessage from "./FormattedMessage";
import { getMessageIcon } from "./InfoMessage";

type SnackbarMessageProps = {
  type: AlertColor;
  message: string | ReactElement;
  show: boolean;
  onClose: () => void;
  sx?: SxProps<Theme>;
};

export const SnackbarMessage = ({ type, show, message, onClose, sx }: SnackbarMessageProps) => {
  const icon = getMessageIcon(type);
  const autoHide = type === "success" ? 5000 : null;
  const action =
    type === "error" ? (
      <Button color="inherit" size="small" sx={{ p: "0 0 0 1rem" }} onClick={onClose}>
        <FormattedMessage id="common.close" />
      </Button>
    ) : null;

  const customSx = sx ?? { right: "16px", left: "16px", bottom: "16px" };
  return (
    <Snackbar open={show} onClose={onClose} autoHideDuration={autoHide} sx={{ ...customSx }}>
      <Alert
        severity={type}
        sx={{
          width: "100%",
          ".MuiAlert-message": { display: "flex", alignItems: "center", justifyContent: "space-between" },
        }}
        icon={icon}
      >
        {message}
        {action}
      </Alert>
    </Snackbar>
  );
};
