import React from "react";
import { Button } from "@mui/material";
import { Icon, iconArrowLeft, iconArrowRight, iconTheory, iconPuzzle } from "theme/icons";
import { useNavigate, useParams } from "react-router-dom";
import { TheoryTab } from "./TopNavigationTabs";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { DataTestType } from "tests/test.setup";

interface ILearningObjectNavigation extends DataTestType {
  navigateTo: TheoryTab;
  contentId: string;
  disabled?: boolean;
}

export const LearningObjectNavigation: React.FC<ILearningObjectNavigation> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const routes = useRouteConfiguration();

  const handleClick = () => {
    navigate(`${routes.theory.path}/${params.areaId}/${params.chapterId}/${props.navigateTo}#${props.contentId}`);
  };

  return (
    <Button
      variant="outlined"
      sx={{ px: 2 }}
      onClick={handleClick}
      data-testid={props.dataTestIdString}
      disabled={props.disabled}
      color="info"
    >
      {props.navigateTo === TheoryTab.practice ? (
        <>
          <Icon name={iconPuzzle} sx={{ mr: 1 }}></Icon>
          <Icon name={iconArrowRight}></Icon>
        </>
      ) : (
        <>
          <Icon name={iconArrowLeft} sx={{ mr: 1 }}></Icon>
          <Icon name={iconTheory}></Icon>
        </>
      )}
    </Button>
  );
};
