declare let window: any;

export const appConfig = {
  appId: window?.env?.APP_ID,
  googleMapsApiKey: window?.env?.GOOGLE_MAPS_API_KEY,

  authClientId: window?.env?.AUTH_CLIENT_ID,
  authTenant: window?.env?.AUTH_TENANT,
  authTenantName: window?.env?.AUTH_TENANT_NAME,

  authUserFlowSignIn: window?.env?.AUTH_USER_FLOW_SIGN_IN,
  authUserFlowPasswordReset: window?.env?.AUTH_USER_FLOW_PASSWORD_RESET,
  authUseVerboseLogging: window?.env?.AUTH_USE_VERBOSE_LOGGING,

  // # Api scopes
  authScopesBoRead: window?.env?.AUTH_SCOPES_BO_READ,
  authScopesBoWrite: window?.env?.AUTH_SCOPES_BO_WRITE,
  authScopesLrsRead: window?.env?.AUTH_SCOPES_LRS_READ,
  authScopesLrsWrite: window?.env?.AUTH_SCOPES_LRS_WRITE,
  authScopesLrsOLdRead: window?.env?.AUTH_SCOPES_LRS_OLD_READ,
  authScopesLrsOldWrite: window?.env?.AUTH_SCOPES_LRS_OLD_WRITE,
  authScopesCcsRead: window?.env?.AUTH_SCOPES_CCS_READ,
  authScopesRpsRead: window?.env?.AUTH_SCOPES_RPS_READ,
  authScopesAssRead: window?.env?.AUTH_SCOPES_ASS_READ,
  authScopesAssWrite: window?.env?.AUTH_SCOPES_ASS_WRITE,
  authScopesFfsRead: window?.env?.AUTH_SCOPES_FFS_READ,
  authScopesSagRead: window?.env?.AUTH_SCOPES_SAG_READ,

  // # Api settings
  ccsApiUrl: window?.env?.CCS_API_URL,
  responsePatternApiUrl: window?.env?.RESPONSE_PATTERN_API_URL,
  boApiUrl: window?.env?.BO_API_URL,
  lrsApiUrl: window?.env?.LRS_API_URL,
  lrsOldApiUrl: window?.env?.LRS_OLD_API_URL,
  assApiUrl: window?.env?.ASS_API_URL,
  ffsApiUrl: window?.env?.FFS_API_URL,
  sagApiUrl: window?.env?.SAG_API_URL,

  salesChannelId: window?.env?.SALES_CHANNEL_ID,
  debug_recoil: window?.env?.DEBUG_RECOIL ?? "false",
  app_insights_connection_string: window?.env?.APP_INSIGHTS_CONNECTION_STRING ?? "",
  filterEducators: window?.env?.FILTER_EDUCATORS ?? "",
};

/**
 * Indicates whether there are any errors in env-config.
 */
let hasErrors = false;
for (const [key, value] of Object.entries(appConfig)) {
  if (value === null || value === "" || value === undefined) {
    hasErrors = true;
    console.error(`Missing value for env variable ${key} in env-config`);
  }
}
if (hasErrors) throw new Error("env-config has missing entries");
