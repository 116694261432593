import React, { ReactElement } from "react";
import { Backdrop, Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import { loadingState, loadingStateText } from "state/recoilAtoms";
import { useRecoilValue } from "recoil";
import FormattedMessage from "./FormattedMessage";

export const LoadingIndicator: React.FC<{
  size?: number;
  color?: CircularProgressProps["color"];
  children?: ReactElement;
}> = ({ children, size = 80, color = "primary" }) => {
  return (
    <Box textAlign="center">
      <CircularProgress size={size} color="primary" />
      {children && (
        <Typography marginTop={2} component="div" variant="subtitle1" color="primary.light04">
          {children}
        </Typography>
      )}
    </Box>
  );
};

export const PageLoader: React.FC = () => {
  let isLoading = useRecoilValue(loadingState);
  let loadingText = useRecoilValue(loadingStateText);

  if (isLoading)
    return (
      <Backdrop sx={{ backgroundColor: "rgba(255,255,255,0.35)", zIndex: 2 }} open>
        <LoadingIndicator>
          {loadingText ? <Typography>{loadingText}</Typography> : <FormattedMessage id="common.title.loading" />}
        </LoadingIndicator>
      </Backdrop>
    );

  return null;
};

export const SuspenseLoader: React.FC = () => {
  return (
    <Backdrop sx={{ backgroundColor: "rgba(255,255,255,0.35)", zIndex: 2 }} open>
      <LoadingIndicator>
        <FormattedMessage id="common.title.loading" />
      </LoadingIndicator>
    </Backdrop>
  );
};
