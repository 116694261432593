import React, { ReactNode } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import { testId } from "tests/testIdStrings";

export type CardSize = 0 | 1 | 2;

interface ICardLinkProps {
  to: string;
  children?: ReactNode;
  highlightColor?: string;
  size?: CardSize;
  dataTestIdString?: string;
}

export const CardLink: React.FC<ICardLinkProps> = (props) => {
  const getCardHeight = () => {
    switch (props.size) {
      case 1:
        return "5rem";

      default:
        return "auto";
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        height: getCardHeight(),
      }}
    >
      <CardActionArea component={Link} to={props.to} data-testid={`${testId.link.cardLink}-${props.dataTestIdString}`}>
        <CardContent>{props.children}</CardContent>
      </CardActionArea>
    </Card>
  );
};
