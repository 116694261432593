import { useDateService } from "hooks/useDateService";
import { Message, useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { get } from "services/httpService";
import { useAppIntl } from "services/useAppIntl";
import { educationStateEducationAdded, userEducationsState } from "state/educationState";
import { UserEducation } from "types/education";
import { API_URL_BO_EDUCATION, SUPPORT_LINK } from "utils/constants";

/**
 * Custom hook for "activating" an education.
 * This hook fetches the users educations every 10 seconds until an activated education is found or a maximum number of retries is reached.
 * If an activated education is found, it displays a success message with the education details.
 * If no activated education is found after the maximum number of retries, it displays an error message.
 *
 * @returns An object containing the `handleActivateEducation` function.
 */
export const useActivateEducation = () => {
  const { setSnackbarValues } = useGlobalSnackbarMessage();
  const educationsState = useRecoilValue(userEducationsState);
  const { getDateAsLocalDateString: getDateAsString } = useDateService();
  const intl = useAppIntl();
  // Used to trigger a re-fetch of educations to update recoil state
  const educationAdded = useSetRecoilState(educationStateEducationAdded);
  const refreshEducations = () => educationAdded((n: number) => n + 1);
  const [done, setDone] = useState(false);

  let retries = 1;

  const handleActivateEducation = async () => {
    // Re-fetch educations every 10 secs until we get information on the activated one
    const intervalId = setInterval(async () => {
      const response = await get(`${API_URL_BO_EDUCATION}educations`);
      const educations: UserEducation[] = response.educations;

      // Check if we have a new or changed education
      const activatedEducations = educations.filter((education) => {
        console.log("ecucation string", JSON.stringify(education));

        return !educationsState!.find((state) => {
          // Remove icon from state to do correct object compare
          const educationState = { ...state, icon: undefined };
          return JSON.stringify(education) === JSON.stringify(educationState);
        });
      });

      // try x number of times or until we have an activated education
      if (retries >= 20 || activatedEducations.length > 0) {
        let message: Message | undefined = undefined;

        if (activatedEducations.length > 0) {
          refreshEducations();
          const activatedEducation: UserEducation = activatedEducations[0];
          const validUntilString = activatedEducation.validUntil ? getDateAsString(activatedEducation.validUntil) : "";
          message = {
            message: intl.formatMessage(
              { id: "education.activate.confirm.messages" },
              { values: { date: validUntilString, educationName: activatedEducation.name } }
            ),
            type: "success",
          };
        } else
          message = {
            message: intl.formatMessage(
              { id: "education.value-code.activate.fail" },
              { values: { link: SUPPORT_LINK } }
            ),
            type: "error",
          };

        setDone(true);
        setSnackbarValues(message);
        clearInterval(intervalId);
      }

      retries++;
    }, 10000);
  };

  return { done, handleActivateEducation };
};
