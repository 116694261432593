import React from "react";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { testId } from "tests/testIdStrings";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Stack } from "@mui/material";

type Props = {
  open: boolean;
  cancel: () => void;
  leave: () => void;
};

export const LeaveTestDialog: React.FC<Props> = ({ open, leave, cancel }) => {
  const handleLeave = () => {
    leave();
  };

  return (
    <DialogMessage
      title="test.dialog.leave-test.title"
      open={open}
      close={cancel}
      primaryButtonText="test.dialog.leave-test.button"
      primaryButtonAction={() => handleLeave()}
      dataTestIdString={testId.listItem.test.common.leave}
    >
      <Stack spacing={2}>
        <FormattedTypography
          id={"test.dialog.leave-test.body"}
          values={{
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
      </Stack>
    </DialogMessage>
  );
};
