import { Box, Avatar, Button, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userStateAvatarById } from "state/userState";
import { Icon, iconPen } from "theme/icons";
import { styleFlex, styleFlexJustifyContentCenter } from "theme/styles";
import { testId } from "tests/testIdStrings";
import useToggle from "hooks/useToggle";
import { ProfileImageDialog } from "./profileImageDialog";

type ProfileImageProps = {
  avatarId: string;
  onSetAvatarId: (avatarId: string) => void;
};

const ProfileImage = ({ avatarId, onSetAvatarId }: ProfileImageProps) => {
  const theme = useTheme();
  const userAvatar = useRecoilValue(userStateAvatarById(avatarId));
  const [openProfileImageDialog, setOpenProfileImageDialog] = useToggle(false);

  return (
    <>
      <Box mb={8} sx={[styleFlex, styleFlexJustifyContentCenter]}>
        <Box sx={{ position: "relative" }}>
          <Avatar
            data-testid={`${
              avatarId
                ? testId.listItem.profile.profilePicture + "-" + avatarId
                : testId.listItem.profile.profilePicture
            }`}
            src={userAvatar && userAvatar.src}
            sx={{
              width: 172,
              height: 172,
              border: `1px solid ${theme.palette.primary.light03}`,
              backgroundColor: "white",
              color: theme.palette.primary.light04,
            }}
          ></Avatar>

          <Button
            variant="contained"
            color="primary"
            sx={{
              position: "absolute",
              bottom: "0",
              right: "1rem",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              minWidth: "auto",
            }}
            data-testid={testId.listItem.profile.avatarChooser}
            onClick={() => setOpenProfileImageDialog(true)}
          >
            <Icon name={iconPen} />
          </Button>
        </Box>
      </Box>

      <ProfileImageDialog
        avatarId={avatarId}
        open={openProfileImageDialog}
        close={() => setOpenProfileImageDialog()}
        setAvatarId={onSetAvatarId}
      />
    </>
  );
};

export default ProfileImage;
