import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import { API_URL_BO_AGREEMENT } from "utils/constants";
import { AgreementTypes, AgreementResponse } from "types/agreements";
import { UserSiteTypes } from "types/userSites";

export const AgreementViewer = () => {
  const [agreement, setAgreement] = useState<string | null>(null);
  const [agreementType, setAgreementType] = useState<AgreementTypes | null>(null);
  const location = useLocation();
  const { json, get } = useFetch<AgreementResponse>();

  useEffect(() => {
    const agmntType = location.state?.agreementType;
    if (agmntType) {
      setAgreementType(agmntType);
      const url = `${API_URL_BO_AGREEMENT}${UserSiteTypes.Korkortsboken}/${agmntType}`;
      // fetch json result
      get(url);
    }
    // do not add "get", will call api infinte times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.agreementType]);

  useEffect(() => {
    // when get is finished we get json result, (could use async / await)
    if (json) {
      setAgreement(json.content);
    }
  }, [json]);

  if (!agreement || !agreementType) {
    return null;
  }

  return <Typography dangerouslySetInnerHTML={{ __html: agreement }} />;
};
