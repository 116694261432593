import { selectorFamily } from "recoil";
import { getTestResultOverview } from "services/lrsService";
import { generateOverview } from "layout/test/testResult";
import { IOverviewQuestion } from "utils/helpers/theory/QuestionOverview";

// TODO: Kanske ta bort? Väljer i nuläget att skippa recoil för denna state.

// export const progressState = atom<null | any>({
//   key: "progresData",
//   default: {},
//   effects: [localStorageEffect("progressData")],
// });

// export const testResults = selector<[TestResultObject] | []>({
//   key: "progressRefined",
//   get: ({ get }) => {
//     const progressData = get(progressState);
//     if (!progressData) return undefined;
//     const testResult = progressData?.items[2].items;
//     return testResult;
//   },
// });

// export const progressLevels = selector<[ProgressLevelObject] | undefined>({
//   key: "progressLevels",
//   get: ({ get }) => {
//     const progressData = get(progressState);
//     if (!progressData) return undefined;
//     const progressLevels = progressData?.items[1].items;
//     return progressLevels;
//   },
// });

export const testResultState = selectorFamily<IOverviewQuestion[] | undefined, string | undefined>({
  key: "testResults",
  get:
    (assessmentId) =>
    async ({ get }) => {
      if (!assessmentId) return undefined;

      const reportOverview = await getTestResultOverview(assessmentId);
      const questions = generateOverview(reportOverview);

      return questions;
    },
});
