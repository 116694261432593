import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { educationState } from "state/educationState";
import { theoryChapterState } from "state/theoryState";
import { TreeNode } from "types/cds";
import { UserEducation } from "types/education";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";
import * as appStateService from "services/appStateService";
import { useLocation, Location } from "react-router";

/**
 * Custom hook that saves the state of the theory chapter.
 * It listens for changes in the chapter, education, and location,
 * and triggers the saving process when necessary.
 */
export const useSaveTheoryState = () => {
  const chapter = useRecoilValue(theoryChapterState);
  const education = useRecoilValue(educationState);
  const location = useLocation();

  useEffect(() => {
    if (!chapter || !chapter.activityKey || !chapter.activityKey.versionId) return;
    if (!education) return;

    console.log("thory layout saving chapter state");
    saveTheoryState(chapter, education, location);
  }, [chapter, education, location]);
};

function saveTheoryState(chapter: TreeNode, education: UserEducation, location: Location) {
  const stateKey: appStateService.StateKey = {
    typeId: appStateService.theoryStateId,
    auxId1: education.contentId,
    auxId2: education.versionId,
  };

  let page: TheoryTab;
  switch (true) {
    case location.pathname.includes(TheoryTab.practice):
      page = TheoryTab.practice;
      break;
    case location.pathname.includes(TheoryTab.answer):
      page = TheoryTab.answer;
      break;

    default:
      page = TheoryTab.read;
      break;
  }

  const state: appStateService.TheoryState = { key: chapter.activityKey as any, page };
  console.debug("Remember chapterId:", chapter, "and state is", state);

  appStateService.saveState(stateKey, state);
}
