import { Icon, iconCircleXmark } from "theme/icons";
import { alpha, createTheme, Direction } from "@mui/material";
import { common } from "@mui/material/colors";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import shadows from "@mui/material/styles/shadows";
import { optimaColors, optimaFontSize } from "./optima";

export const desktopWidth420 = "420px";
export const desktopWidth640 = "640px";

export const theme = (direction: Direction) =>
  createTheme({
    direction: direction,
    typography: {
      fontFamily: "proxima-nova, sans-serif",
      // fontSize: (14 / 16) * 18, // Defaults to 18px
      h1: {
        fontSize: optimaFontSize.h1,
        lineHeight: optimaFontSize.h1,
        fontWeight: 600,
      },
      h2: {
        fontSize: optimaFontSize.h2,
        lineHeight: optimaFontSize.h2,
        fontWeight: 600,
      },
      h3: {
        fontSize: optimaFontSize.h3,
        fontWeight: 500,
      },
      h4: {
        fontSize: optimaFontSize.h4,
        fontWeight: 700,
        letterSpacing: "0.1px",
      },
      body1: {
        fontSize: optimaFontSize.body1,
      },
      body2: {
        fontSize: optimaFontSize.body2,
        lineHeight: 1.225,
      },
      bodyStrong: {
        fontSize: optimaFontSize.bodyStrong,
        fontWeight: 600,
        lineHeight: 1.4,
      },
      subtitle1: {
        fontSize: optimaFontSize.subtitle1,
        fontWeight: 600,
        lineHeight: "19px",
        letterSpacing: "0.5px",
      },
      subtitle2: {
        fontSize: optimaFontSize.subtitle2,
        fontWeight: 500,
        lineHeight: "17px",
        letterSpacing: "0.5px",
      },
      caption: {
        fontSize: optimaFontSize.caption,
        fontWeight: 400,
        lineHeight: 1.225,
        letterSpacing: "0.004em",
        display: "inline-flex",
      },
      captionImage: {
        fontSize: optimaFontSize.imageText,
        lineHeight: 1.0625,
        letterSpacing: "0.4px",
      },
      breadcrumbs: {
        fontSize: optimaFontSize.breadcrumb,
        fontWeight: 500,
        display: "inline-flex",
      },
      note: {
        fontSize: optimaFontSize.note,
        fontStyle: "italic",
        lineHeight: 1.0625,
        letterSpacing: "0.25px",
      },

      button: {
        fontSize: optimaFontSize.button,
        fontWeight: 600,
        lineHeight: "19px",
        letterSpacing: 1.5,
        textTransform: "uppercase",
        height: "3rem",
      },
    },
    header: {
      height: 12,
    },
    shape: {
      borderRadius: 5,
    },
    palette: {
      mode: "light",
      background: {
        desktop: "#FDFDFD",
      },
      primary: {
        main: optimaColors.primary,
        light: optimaColors.primaryLight,
        light01: optimaColors.primary01,
        light02: optimaColors.primary02,
        light03: optimaColors.primary03,
        light04: optimaColors.primary04,
        dark: optimaColors.primaryDark,
        dark06: optimaColors.primary06,
        dark07: optimaColors.primary07,
        dark08: optimaColors.primary08,
        dark09: optimaColors.primary09,
        contrastText: common.white,
      },
      secondary: {
        main: optimaColors.secondary,
        light: optimaColors.secondaryLight,
        dark: optimaColors.secondaryDark,
        contrastText: common.white,
      },
      error: {
        main: optimaColors.error,
        contrastText: common.white,
      },
      success: {
        main: optimaColors.success,
        contrastText: common.white,
      },
      info: {
        main: optimaColors.tertiary05,
        light: optimaColors.tertiary04,
        dark: optimaColors.tertiary06,
        contrastText: common.black,
      },
      warning: {
        main: optimaColors.neutralWarning,
      },
      text: {
        secondary: "rgba(0,0,0,0.6)",
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "4.5rem",
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
          fontSize: optimaFontSize.body2,
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // Denna funkar konstigt nog inte under typography/button så måste ligga här
            borderRadius: "25px",
          },
          contained: {
            boxShadow: "none",
            ":hover, :active": {
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              color: "#FFF",
              backgroundColor: optimaColors.primary01,
            },
          },
          outlined: {
            backgroundColor: common.white,
            borderColor: alpha(common.black, 0.12),
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <Icon name={iconCircleXmark} />,
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderColor: alpha(common.black, 0.12),
            fontSize: "12px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            ".MuiChip-label": {
              padding: 0,
            },
            ".MuiSvgIcon-root": {
              fontSize: "20px",
              color: alpha(common.black, 0.6),
              ":first-child": {
                marginRight: "0.5rem",
                marginLeft: "-0.2rem",
              },
              ":last-child": {
                marginLeft: "0.5rem",
                marginRight: "-0.2rem",
              },
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            ".MuiSwitch-switchBase.Mui-checked": {
              color: optimaColors.primary06,
              "+.MuiSwitch-track": {
                backgroundColor: optimaColors.primary02,
              },
            },
            ".MuiSwitch-track": {
              opacity: 0.14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: optimaFontSize.input,
            "&:not(.MuiInputBase-multiline)": {
              height: "3rem",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: optimaFontSize.input,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: alpha(common.black, 0.6),
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: optimaFontSize.input,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              boxShadow: shadows[4],
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: optimaFontSize.subtitle2,
            paddingTop: 0,
            paddingBottom: 0,
            color: common.white,
            lineHeight: 1.5,
            ".MuiAlert-icon": { color: common.white, opacity: 1, alignSelf: "center" },
            "&.MuiAlert-standardSuccess": { backgroundColor: optimaColors.success },
            "&.MuiAlert-standardError": { backgroundColor: optimaColors.error },
            "&.MuiAlert-standardWarning": {
              backgroundColor: optimaColors.warning,
              color: common.black,
              border: `1px solid ${optimaColors.neutralWarning}`,
              ".MuiSvgIcon-root": { color: optimaColors.neutralWarning },
            },
            "&.MuiAlert-standardInfo": { backgroundColor: optimaColors.info },
            ".MuiAlert-message": { width: "100%" },
          },
        },
      },
      MuiBottomNavigation: {
        defaultProps: {
          sx: {
            height: "70px",
            backgroundColor: optimaColors.primary,
          },
        },
      },
      MuiBottomNavigationAction: {
        defaultProps: {
          sx: {
            color: alpha("#FFFFFF", 0.74),
            minWidth: "auto",
            padding: "0 5px",
            ".MuiBottomNavigationAction-label": {
              fontSize: "12px",
              "&.Mui-selected": {
                fontSize: "12px",
              },
            },
            "&.Mui-selected": {
              color: common.white,
              fontWeight: 600,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "30px",
            backgroundColor: common.white,
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);",
            ".MuiCardActionArea-root:hover": {
              backgroundColor: optimaColors.primary01,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            ".MuiMenu-paper": {
              boxShadow: shadows[4],
              borderRadius: 15,
              ".MuiMenu-list": {
                padding: 0,
                ".MuiMenuItem-root:hover": {
                  backgroundColor: alpha(optimaColors.primary, 0.12),
                },
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: optimaFontSize.body2,
            padding: "1rem",
            borderBottom: `1px solid ${alpha(common.black, 0.12)}`,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            overflow: "hidden",
            borderRadius: "25px",
            ".MuiListItemButton-root:hover": {
              backgroundColor: optimaColors.primary01,
            },
            h6: {
              lineHeight: "1.25",
            },
            ".MuiListItemSecondaryAction-disableGutters button": {
              marginRight: "10px",
            },
            ".MuiLinearProgress-root": {
              marginBottom: "-0.5rem",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          // Alert message in dialog
          root: {
            ".MuiDialog-container .MuiAlert-root.MuiPaper-root": {
              borderRadius: "4px",
              position: "relative",
              boxShadow: "none",
              marginBottom: "1.5rem",
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            ".MuiListItemButton-root:hover": {
              backgroundColor: optimaColors.primary01,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            // Topnav tabs
            ".MuiTabs-root": {
              ".MuiTab-root": {
                letterSpacing: "0.045em",
                fontWeight: "400",
                padding: "12px 24px",
                textAlign: "center",

                "&.Mui-selected": {
                  fontWeight: 600,
                },
                label: {
                  paddingLeft: "2px",
                  cursor: "pointer",
                },
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            // ".MuiTabs-indicator": { height: "1px" },
            ".MuiTab-root": {
              color: optimaColors.primary03,
              minHeight: "auto",
              padding: "6px 24px",
              ".MuiSvgIcon-root": { marginRight: "0.5rem" },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: "1.5rem 0 2rem 0",
          },
        },
      },
    },
  });

declare module "@mui/material/styles" {
  interface Theme {
    header: {
      height: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    header?: {
      height?: number;
    };
  }

  interface TypographyVariants {
    bodyStrong: TypographyStyleOptions;
    breadcrumbs: TypographyStyleOptions;
    note: TypographyStyleOptions;
    captionImage: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyStrong?: TypographyStyleOptions;
    breadcrumbs?: TypographyStyleOptions;
    note?: TypographyStyleOptions;
    captionImage?: TypographyStyleOptions;
  }

  interface PaletteColor {
    light01?: string;
    light02?: string;
    light03?: string;
    light04?: string;
    dark06?: string;
    dark07?: string;
    dark08?: string;
    dark09?: string;
  }

  interface SimplePaletteColorOptions {
    light01?: string;
    light02?: string;
    light03?: string;
    light04?: string;
    dark06?: string;
    dark07?: string;
    dark08?: string;
    dark09?: string;
  }

  interface TypeBackground {
    desktop?: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyStrong: true;
    breadcrumbs: true;
    note: true;
    captionImage: true;
  }
}
