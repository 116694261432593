import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Icon, iconCheckbox, iconCheckboxEmpty } from "theme/icons";
import { Control, useController, Validate } from "react-hook-form";

interface IFormCheckboxProps {
  label: string;
  name: string;
  labelPlacement: "top" | "bottom" | "start" | "end";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  dataTestIdString?: string;
  control?: Control<any, any>;
  validate?: Validate<any, any> | Record<string, Validate<any, any>>;
  required?: boolean;
}

/**
 * Custom form checkbox.
 * @param label
 * @param name
 * @param labelPlacement "top" | "bottom" | "start" | "end";
 * @param onChange [optional?] onChange event
 * @param checked [optional?] you must use this prop if you're NOT using the control prop
 * @param dataTestIdString [optional?] unique data-id for test 
 * @param control [optional?] use this if you want the checkbox to be controlled by the useForm hook
 * @param validate [optional?] validation function for useForm hook

* @returns a controlled checkbox
 */
export const FormCheckbox: React.FC<IFormCheckboxProps> = (props) => {
  if (props.control) return <FormCheckboxControlled {...props} />;

  if (props.checked === undefined) throw new Error("checked property is missing on " + props.name);

  return (
    <FormControlLabel
      control={
        <Checkbox
          required={props.required}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          checkedIcon={<Icon name={iconCheckbox} fontSize={"24px"} />}
          icon={<Icon name={iconCheckboxEmpty} fontSize={"24px"} />}
          color={"default"}
          data-testid={props.dataTestIdString}
        />
      }
      label={<Typography variant="subtitle2">{props.label}</Typography>}
      labelPlacement={props.labelPlacement}
    />
  );
};

const FormCheckboxControlled: React.FC<IFormCheckboxProps> = (props) => {
  const { name, control } = props;
  const { field } = useController({ control, name });

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={field.name}
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
          checkedIcon={<Icon name={iconCheckbox} fontSize={"24px"} />}
          icon={<Icon name={iconCheckboxEmpty} fontSize={"24px"} />}
          color={"default"}
          data-testid={props.dataTestIdString}
        />
      }
      label={<Typography variant="subtitle2">{props.label}</Typography>}
      labelPlacement={props.labelPlacement}
    />
  );
};
