import React from "react";
import { Box } from "@mui/material";
import { Title } from "utils/helpers/TitleDivider";
import { useAppIntl } from "services/useAppIntl";
import roadsigns from "static/roadsigns.png";
import { iconExternalLink, iconTriangle } from "theme/icons";
import { styleFlex, styleFlexJustifyContentCenter } from "theme/styles";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import ButtonBack from "utils/helpers/ButtonBack";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import SvgImage, { svgColor, ellipse4 } from "utils/helpers/SvgImage";

interface IRoadSignsProps {}
export const RoadSigns: React.FC<IRoadSignsProps> = (props) => {
  const intl = useAppIntl();

  return (
    <>
      <BackgroundShape top={-470} center>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={180}
          width="1230"
          height="860"
          color={svgColor.pink}
        />
      </BackgroundShape>

      <Title title={intl.formatMessage({ id: "common.roadsigns" })} />

      <Box mt={18} mb={9} sx={[styleFlex, styleFlexJustifyContentCenter]}>
        <img src={roadsigns} alt="Roadsigns" />
      </Box>
      <FormattedTypography id="roadsigns.text.information" />
      <Box mt={8} mb={8}>
        <NavigationItem
          size="large"
          text={intl.formatMessage({ id: "roadsigns.link" })}
          icon={iconTriangle}
          navigationIcon={iconExternalLink}
          href="https://www.vagmarken.nu/"
          hrefTarget="_blank"
          dataTestIdString={testId.listItem.roadSigns}
        />
      </Box>
      <ButtonBack dataTestIdString={testId.common.text.roadSigns} />

      <BackgroundShape bottom={-1000} center stickToBottom>
        <SvgImage
          d={ellipse4.d}
          viewBox={ellipse4.viewBox}
          rotation={340}
          width="900"
          height="600"
          color={svgColor.turquoise}
        />
      </BackgroundShape>
    </>
  );
};
