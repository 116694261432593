import { FeatureFlagResponse } from "@strmediaochitab/optima-react-components";
import { get } from "services/httpService";
import { API_URL_FFS } from "utils/constants";
import { msalInstance } from "utils/helpers/msal/AppMsalProvider";

let retryInitialFetch = 5;
export const fetchFeatureFlags = async (): Promise<FeatureFlagResponse[]> => {
  const account = msalInstance.getActiveAccount();

  // Check to see if we have an account (eg. user is logged in), if not, retry
  // This check due to the app structure and we might be in the middle of logging in
  if (!account) {
    if (retryInitialFetch > 0) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          retryInitialFetch--;
          resolve(fetchFeatureFlags());
        }, 750);
      });
    } else {
      return [];
    }
  }

  return await get(API_URL_FFS);
};

export const fetchFeatureFlag = async (flag: string): Promise<FeatureFlagResponse> => {
  return await get(API_URL_FFS + "/" + flag);
};
