import { Box } from "@mui/material";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  currentIndex: number;
}

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, currentIndex, index, ...other } = props;

  // Don't render content if current index matches
  if (currentIndex !== index) return null;

  return (
    <Box role="tabpanel" id={`study-test-tabpanel-${index}`} aria-labelledby={`study-test-${index}`} {...other}>
      {children}
    </Box>
  );
};
