import React, { useState } from "react";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { TopNavigationDetailsView } from "utils/helpers/TopNavigationDetailsView";
import { Icon, iconCircle, iconCircleCheck, iconChevronUp, iconChevronDown, iconTest } from "theme/icons";
import { FormToggle } from "utils/helpers/FormToggle";

// TODO: Används ännu inte. Tänkt att användas för att visa en checklista för körkortet.

type Props = {};
const myChecklist = [
  {
    id: "0",
    title: "Körkortstillstånd",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "1",
    title: "Gör en synprövning",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "2",
    title: "Inskrivning hos trafikskola",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "3",
    title: "Introduktionsutbildning",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "4",
    title: "Riskutbildning 1",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "5",
    title: "Riskutbildning 2",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "6",
    title: "Kunskapsprov",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
  {
    id: "7",
    title: "Körprov",
    description:
      "Till ansökan om körkortstillstånd ska du även fylla i en hälsodeklaration och bifoga synintyg. Din STR-anslutna utbildare hjälper dig med detta.",
    checked: false,
    showDescription: false,
  },
];
const sampleText = {
  bodyText1:
    "På den här sidan får du hjälp att hålla reda på vad för olika delar du behöver göra under din resa till körkortet",
  bodyText2: "Checka av i checkboxarna för att hålla reda på vad du gjort.",
};
const progressWrapper = {
  width: "100%",
  height: "fit-content",
  padding: "1rem",
  position: "relative",
};
const contentContainer = {
  width: "100%",
  minHeight: "5rem",
  height: "fit-content",
  display: "flex",
  justifyContent: "flex-start",
};
const iconWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justiyContent: "center",
};
const iconStyle = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const titleWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  margin: "0 2rem 0 2rem",
  height: "fit-content",
  position: "relative",
};
const chapterStyle = {
  margin: "-2rem 0 1rem -2rem",
  position: "absolute",
};
const descriptionWrapper = {
  boxShadow: "none",
  margin: "1rem 0 2rem 0",
  padding: "1rem",
};
const arrowTextContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const arrowButtonStyle = {
  display: "flex",
  alignItems: "flex-start",
  margin: "0 0 0 2rem",
};
const medalStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "0 2rem 0 0",
};
export const Checklist: React.FC<Props> = (props) => {
  const [defaultValues, setDefaultValues] = useState(myChecklist);

  const allChecked = defaultValues.filter((item) => item.checked === false).length <= 0;
  return (
    <Stack spacing={4}>
      <TopNavigationDetailsView iconSize={"large"} topTitle={"Mer"} title={"Checklista"} to={`/more/`} />
      <Box>
        <Card sx={{ boxShadow: "none", padding: "1rem" }}>{sampleText.bodyText1}</Card>
        <Card sx={{ boxShadow: "none", padding: "1rem" }}>{sampleText.bodyText2}</Card>
      </Box>
      <Divider />
      <Box sx={progressWrapper}>
        {defaultValues.map((item) => (
          <Box key={item.id} sx={contentContainer}>
            <Box sx={iconWrapper}>
              <FormToggle
                style={iconStyle}
                onChange={(status: boolean) => {
                  const newState = defaultValues.map((obj) => {
                    if (obj.id === item.id) {
                      return { ...obj, checked: status };
                    }
                    return obj;
                  });
                  setDefaultValues(newState);
                }}
              >
                <Icon
                  name={item.checked ? iconCircleCheck : iconCircle}
                  size={"large"}
                  htmlColor={item.checked ? "green" : "lightGray"}
                />
                <Box sx={{ border: "dotted 1px black", width: "1px", height: "100%" }}></Box>
              </FormToggle>
            </Box>
            <Box sx={titleWrapper}>
              {item.title.includes("Riskutbildning 1") && (
                <Box sx={chapterStyle}>
                  <Typography component="div" variant="body2">
                    Riskutbildning
                  </Typography>
                </Box>
              )}
              {item.title.includes("Kunskap") && (
                <Box sx={chapterStyle}>
                  <Typography component="div" variant="body2">
                    {item.title}
                  </Typography>
                </Box>
              )}
              <Box sx={arrowTextContainer}>
                <Typography component="div" variant="h5" color={item.checked ? "green" : ""}>
                  {item.title}
                </Typography>
                <FormToggle
                  style={arrowButtonStyle}
                  onChange={(status: boolean) => {
                    const newState = defaultValues.map((obj) => {
                      if (obj.id === item.id) {
                        return { ...obj, showDescription: status };
                      }
                      return obj;
                    });
                    setDefaultValues(newState);
                  }}
                >
                  <Icon
                    name={item.showDescription ? iconChevronDown : iconChevronUp}
                    size={"large"}
                    htmlColor={"ligthgrey"}
                  />
                </FormToggle>
              </Box>
              <Card sx={descriptionWrapper} style={{ display: item.showDescription ? "" : "none" }}>
                <Typography component="div" variant="body2" style={{ marginBottom: "1rem" }}>
                  {item.description}
                </Typography>
                <Divider />
              </Card>
            </Box>
          </Box>
        ))}
        <Box sx={contentContainer}>
          <Box>
            <Box sx={medalStyle}>
              <Icon name={iconTest} size={"large"} htmlColor={allChecked ? "green" : "lightGrey"} />
            </Box>
          </Box>
          <Box>
            Checklista
            <Typography component="div" variant="h5" color={allChecked ? "green" : ""}>
              Grattis till körkortet!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
