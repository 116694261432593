import React from "react";
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";

interface ILinearProgressIndicatorProps {
  value: number;
  color?: LinearProgressProps["color"];
  height?: string;
  progressText?: boolean;
}

export const LinearProgressIndicator: React.FC<ILinearProgressIndicatorProps> = ({
  value,
  color,
  height,
  progressText,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {progressText && (
        <Typography variant="subtitle1" mb={0.5}>
          {`${value}%`}
        </Typography>
      )}
      <LinearProgress
        variant="determinate"
        value={value}
        color={color}
        sx={{ height: height ? height : undefined, borderRadius: "8px" }}
      />
    </Box>
  );
};
