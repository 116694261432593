import React, { FC, useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { optimaColors } from "theme/optima";
import { EducatorDetails } from "types/educator";
import { iconLocation, Icon } from "theme/icons";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { appConfig } from "appConfig";
import pinEducatorStr from "static/pinEducatorStr.png";
import pinEducator from "static/pinEducator.png";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { testId } from "tests/testIdStrings";

const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "15px",
};

const centerOfSweden = {
  lat: 61.987556758736574,
  lng: 15.299610840325164,
};

const FindUs: FC<{ educator: EducatorDetails }> = ({ educator }) => {
  const mapRef = useRef<GoogleMap>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appConfig.googleMapsApiKey,
    language: "sv-SE",
    region: "sv-SE",
  });
  const hasCoordinates = useMemo(() => {
    const location = educator.address?.location;
    return !!location?.latitude && !!location?.longitude;
  }, [educator.address?.location]);

  const icon = useMemo(() => (educator.isSTRMember ? pinEducatorStr : pinEducator), [educator.isSTRMember]);
  const position = useMemo(() => {
    const location = educator.address?.location;
    return {
      lat: location?.latitude ?? centerOfSweden.lat,
      lng: location?.longitude ?? centerOfSweden.lng,
    };
  }, [educator.address?.location]);

  return isLoaded ? (
    <Box data-testid={testId.listItem.educator.educatorAdressBox}>
      <FormattedTypography id="more.educator.find.us" variant="h2" marginBottom={2} marginLeft={1} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        border="1px solid rgba(0, 0, 0, 0.12)"
        borderRadius={4}
        padding={2}
      >
        <Box display={"flex"} marginBottom={2}>
          <Icon name={iconLocation} htmlColor={optimaColors.primary06} />
          <Box marginLeft={2}>
            <FormattedTypography id="profile.label.address-label" variant="h3" />

            <Typography marginTop={1}>{educator.address.street1}</Typography>
            <Typography>
              {educator.address.postalCode} {educator.address.city}
            </Typography>
          </Box>
        </Box>
        <GoogleMap
          ref={mapRef}
          clickableIcons={false}
          mapContainerStyle={containerStyle}
          center={position}
          zoom={hasCoordinates ? 12 : 4}
        >
          {hasCoordinates && (
            <MarkerF icon={icon} key={educator?.identifier} position={position} title={educator?.name} />
          )}
        </GoogleMap>
      </Box>
    </Box>
  ) : null;
};

export default React.memo(FindUs);
