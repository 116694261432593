import { Alert, Typography, Box, Button } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IntlMessageKeys } from "services/useAppIntl";
import { Icon, iconCircleCheck, iconCircleExclamation, iconCircleInfoSolid, iconXmark } from "theme/icons";
import shadows from "@mui/material/styles/shadows";

export type MessageType = "success" | "info" | "warning" | "error";
export type InfoMessageProps = {
  type: MessageType;
  message: IntlMessageKeys;
  messageValue?: Record<string, React.ReactNode>;
  closeButton?: () => void;
  showCloseButton?: boolean;
  closeButtonColor?: string;
};

export const InfoMessage: FC<InfoMessageProps> = ({
  type,
  message,
  messageValue,
  closeButton,
  showCloseButton,
  closeButtonColor,
}) => {
  const icon = getMessageIcon(type);

  return (
    <Alert severity={type} icon={icon} sx={{ width: "100%", px: 1, boxShadow: shadows[4] }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="subtitle2" letterSpacing="0.07px">
          <FormattedMessage id={message} values={messageValue} />
        </Typography>
        {showCloseButton && (
          <Button onClick={closeButton} sx={{ minWidth: "40px", marginLeft: "auto" }}>
            <Icon
              name={iconXmark}
              sx={{ color: closeButtonColor ? closeButtonColor : "rgba(0, 0, 0, 0.87)!important" }}
            ></Icon>
          </Button>
        )}
      </Box>
    </Alert>
  );
};

export const getMessageIcon = (type: MessageType) => {
  let icon;
  switch (type) {
    case "success":
      icon = iconCircleCheck;
      break;
    case "info":
      icon = iconCircleInfoSolid;
      break;
    case "warning":
      icon = iconCircleExclamation;
      break;
    default:
      icon = iconCircleExclamation;
      break;
  }

  return <Icon name={icon} />;
};
