import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryChapterState } from "state/theoryState";
import { LRSActionType, LRSProvider, LRSProviderState, LRSRecord } from "@strmediaochitab/optima-component-library";
import {
  activitiesStateSelector,
  educationContentTypeState,
  selectedActivitesKeyState,
} from "state/learningStructureState";
import { scrollToElementByLocation } from "utils/helpers/ScrollHandler";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { testId } from "tests/testIdStrings";
import { RenderActivities } from "utils/helpers/theory/RenderActivities";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";
import { getLrsState, handleLrsQuestion, XapiKey } from "services/lrsService";
import { useAccount } from "@azure/msal-react";
import { NoContent } from "utils/helpers/theory/NoContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { useAppContext } from "context/AppContext";

interface TheoryPracticeProps {}
export const TheoryPractice: React.FC<TheoryPracticeProps> = (props) => {
  const theme = useTheme();
  const { soundSupport, activeLanguage } = useAppContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const account = useAccount();
  const chapter = useRecoilValue(theoryChapterState);
  const activities = useRecoilValue(
    activitiesStateSelector({
      keys: useRecoilValue(selectedActivitesKeyState),
      classificationId: useRecoilValue(educationContentTypeState).get("practice")!,
    })
  );

  const [lrsState, setLrsState] = useState<LRSRecord>();
  const { start, stop, speechInProgress } = useTextToSpeech();

  const xapiKey: XapiKey = {
    userId: account!.localAccountId,
    contentId: chapter!.activityKey!.contentId,
    versionId: chapter!.activityKey!.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get("practice")!,
  };

  const hasMaterial = useMemo(() => {
    return activities?.some((activity) => activity.materialReferences?.length !== 0);
  }, [activities]);

  useEffect(() => {
    const getState = async () => {
      // TODO: Vi bör separera assessment även här. Men just nu baseras det på att vi först hämtat ut vår assessment :(
      const lrsState = await getLrsState(xapiKey);

      setLrsState(lrsState);
    };

    getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter!.id]);

  useEffect(() => {
    if (!activities?.length) return;
    if (!lrsState) return;

    scrollToElementByLocation({ location, offsetTop: -100 });
  }, [location, activities, lrsState]);

  useEffect(() => {
    ReactGA.event({
      category: "theory",
      action: "practice",
    });
  }, []);

  const handleCallbackSetQuestion = async (
    state: LRSProviderState,
    setState?: (state: LRSProviderState) => void,
    action?: LRSActionType
  ) => {
    const key = xapiKey;

    handleLrsQuestion({ key, state, setState, action });
  };

  if (!account) {
    console.log("No active account");
    return null;
  }

  // Make sure we wait for LRS before initializing the LRSProvider
  if (!lrsState) return null;

  if (hasMaterial === false)
    return (
      <>
        <Typography variant="h1" data-testid={testId.listItem.theory.articleTitle}>
          {chapter?.title}
        </Typography>
        <NoContent />
      </>
    );

  return (
    <Box mx={theme.spacing(-2)}>
      <LRSProvider
        user={account.localAccountId}
        language={activeLanguage}
        textToSpeech={
          soundSupport
            ? {
                start: start,
                stop: stop,
                speechInProgress: speechInProgress,
              }
            : undefined
        }
        callbackSetQuestion={handleCallbackSetQuestion}
        state={{ questions: lrsState }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {isMobile && (
            <Typography
              marginTop="56px"
              marginLeft={2}
              variant="h1"
              mb={0}
              data-testid={testId.listItem.theory.articleTitle}
            >
              {chapter?.title}
            </Typography>
          )}
          <RenderActivities
            activities={activities}
            navigateTo={TheoryTab.read}
            bgColor={theme.palette.primary.light01}
            type="practice"
          />
        </Box>
      </LRSProvider>
    </Box>
  );
};
