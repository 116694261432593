import { useAppContext } from "context/AppContext";
import { useState } from "react";
import { textToSpeech } from "services/microsoftCognitiveServicesSpeech";

export const useTextToSpeech = () => {
  const { activeLanguage } = useAppContext();
  const [speechInProgress, setSpeechInProgress] = useState(false);
  const { start, stop, resume } = textToSpeech(setSpeechInProgress, activeLanguage);

  return { start, stop, resume, speechInProgress, setSpeechInProgress };
};
